import PropTypes from "prop-types"
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { classNames } from "../../../utils/ClassNames"
export const Checklist = ({ isError, text }) => {
  return (
    <>
      {isError ? (
        <div className="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <CheckCircleIcon className="h-4 w-4 text-gray-900" />
          <span className="ml-2 text-sm font-semibold">{text}</span>
        </div>
      ) : (
        <div className="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon className="h-4 w-4 text-red-400" />
          <span className="ml-2  text-sm font-semibold">{text}</span>
        </div>
      )}
    </>
  )
}

Checklist.propTypes = {
  isError: PropTypes.bool,
  text: PropTypes.string,
}

Checklist.defaultProps = {
  isError: true,
  text: null,
}
