import { useState, useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
// import { PlusCircleIcon } from "@heroicons/react/outline"
import userIcon from "../../../../assets/user.png"

import { RoundedButton } from "../../../../components/buttons"
import SpinLoader from "../../../../components/SpinLoader"
import { Toggle, SelectMenu } from "../../../../components/inputs"
import BusinessHourCard from "./BusinessHourCard"
import Table from "./Table"
import AddressPopup from "./AddressPopup"

import {
  billingRatesTableColumns,
  initialBusinessState,
  serviceAreaOptions,
} from "../../../../constants/constants"
import Static from "../../../../static.json"
import {
  getProviderProfile,
  updateProviderProfileInfo,
} from "../../../../actions/profile"
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/helper"

import { useRouteMatch, Redirect } from "react-router-dom"
import { checkMediaScreen } from "../../../../utils/helper"
import TimeErrorModal from "./TimeErrorModal"

const ProviderProfile = ({
  profile,
  getProviderProfile,
  updateProviderProfileInfo,
}) => {
  const {
    dashboard: {
      profile: {
        pagetitle,
        title,
        subtitle1,
        subtitle2,
        subtitle3,
        listdesc1,
        listdesc2,
        listdesc3,
        listdesc4,
        businessnamelabel,
        businessaddresslabel,
        servicearealabel,
        servicecalllabel,
        actionbuttonlabel,
        editbuttonlabel,
        changebuttonlabel,
        uploadphotolabel,
        successnotification,
        editProfileLabel,
        saveProfileLabel,
      },
    },
  } = Static
  const [serviceCall, setServiceCall] = useState(false)
  const [businessHours, setBusinessHours] = useState(initialBusinessState)
  const [editableBusiness, setEditableBusiness] = useState("")
  const [editBusinessAddress, setEditBusinessAddress] = useState(false)
  const [editServiceArea, setEditServiceArea] = useState(false)
  const [serviceRadius, setServiceRadius] = useState()
  const [billingItems, setBillingItems] = useState([])
  const [ratesColumn, setRatesColumn] = useState(billingRatesTableColumns)
  const [companyInfo, setCompanyInfo] = useState()
  const [categories, setCategories] = useState([])
  const [editableCategoryId, setEditableCategoryId] = useState()
  const [isMobile, setIsMobile] = useState(null)
  const [notifySetTime, setNotifySetTime] = useState(false)
  const [notifySetRate, setNotifySetRate] = useState(false)
  const [timeOverlap, setTimeOverlap] = useState(false)
  const [timeError, setTimeError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const checkMediaScreen = () =>
    window.screen.width < 768 ? setIsMobile(true) : setIsMobile(false)

  useEffect(() => {
    document.title = pagetitle

    getProviderProfile()
    checkMediaScreen()
  }, [])

  useEffect(() => {
    // console.log("PROFILE: ", profile)
    if (profile) {
      const { company, categories, rates } = profile
      const { acceptTeleservice = false, serviceRadius } = company
      setCompanyInfo(company)
      setServiceCall(acceptTeleservice)
      setServiceRadius(
        serviceAreaOptions.find(({ value }) => value === serviceRadius)
      )
      setCategories(categories)
      setBillingItems(rates)

      setBusinessHours(() => {
        if (businessHours && rates.length > 0) {
          const getDays = Object.keys(businessHours)
          getDays.map((findDay) => {
            const findDayObject = businessHours[findDay]
            const getCategoryHours = rates.find(
              (value) => value.typeId === findDayObject.typeId
            )
            let selectedDay = { ...businessHours[findDay] }
            const newSelectedDay = {
              ...selectedDay,
              slots: {
                from: parseDaytime(getCategoryHours?.startTime),
                to: parseDaytime(getCategoryHours?.endTime),
              },
            }
            // console.log("New Selected Day: ", newSelectedDay)
            businessHours[findDay] = newSelectedDay
          })

          rates?.map(({ typeId, isActive }) => {
            Object.keys(businessHours).forEach((key) => {
              if (businessHours[key].typeId === typeId) {
                businessHours[key].checked = isActive
              }
              if (businessHours[key].typeId === 1) {
                businessHours[key].checked = isActive
              }
            })
          })
          return businessHours
        } else return initialBusinessState
      })

      // Set the rates column to only the business hours that are checked
      // setRatesColumn(() => {
      //   let ratesColumnCopy = [...ratesColumn]
      //   Object.keys(businessHours).forEach((key) => {
      //     for (let i = 0; i < ratesColumn.length; i++) {
      //       if (ratesColumn[i].type === businessHours[key].typeId) {
      //         const isChecked = businessHours[key].checked
      //         ratesColumn[i].available = isChecked
      //       }
      //     }
      //   })
      //   return ratesColumnCopy
      // })
    }
  }, [profile])

  const parseDaytime = (time) => {
    if (time) {
      let hours = time
      hours = hours.substr(0, time.length - 2)
      const mins = hours.split(":")[1].trim()
      hours = hours.split(":")[0]
      if (time.includes("PM") && parseInt(hours) !== 12) {
        hours = parseInt(hours) + 12
      }
      return new Date("2000", "01", "01", hours.toString(), mins)
    }
  }

  const handleBillingRateChange = (categoryId, type, newValue) => {
    const findOne = billingItems.find(
      ({ providerCategoryId, typeId }) =>
        providerCategoryId === categoryId && typeId === type
    )
    if (findOne) {
      setBillingItems(
        billingItems.map(({ providerCategoryId, typeId, value }) => {
          if (providerCategoryId === categoryId && typeId === type) {
            return {
              providerCategoryId,
              typeId,
              value: newValue,
            }
          }
          return {
            providerCategoryId,
            typeId,
            value,
          }
        })
      )
    } else {
      billingItems.push({
        providerCategoryId: categoryId,
        typeId: type,
        value: newValue,
      })
      setBillingItems([...billingItems])
    }
  }

  const handleBusinessHourStatus = (property) => {
    let selectedDay = { ...businessHours[property] }
    selectedDay = {
      ...selectedDay,
      checked: !selectedDay.checked,
    }
    const checkStatus = selectedDay.checked

    // Switch case to determine which hours have been selected to notify user to select rates
    switch (property) {
      case "overtime_weekdays":
        setNotifySetTime([property, checkStatus, selectedDay.slots.from])
        setNotifySetRate("overtime_weekdays")
        break
      case "saturday":
        setNotifySetTime([property, checkStatus, selectedDay.slots.from])
        setNotifySetRate("saturday")
        break
      case "sunday":
        setNotifySetTime([property, checkStatus, selectedDay.slots.from])
        setNotifySetRate("sunday")
        break
      default:
        return
    }

    setBusinessHours({ ...businessHours, [property]: selectedDay })
    setRatesColumn(
      ratesColumn.map(({ dataKey, ...rest }) => {
        if (dataKey === property) {
          return {
            ...rest,
            dataKey,
            available: selectedDay.checked,
          }
        }
        return {
          ...rest,
          dataKey,
        }
      })
    )
  }

  // const handleAddSlot = (property) => {
  //   let selectedDay = { ...businessHours[property] }
  //   selectedDay = {
  //     ...selectedDay,
  //     slots: [
  //       ...selectedDay.slots,
  //       {
  //         from: new Date("2000/01/01 8:30:00Z"),
  //         to: new Date("2000/01/01 17:30:00Z"),
  //       },
  //     ],
  //   }
  //   setBusinessHours({ ...businessHours, [property]: selectedDay })
  // }

  // const handleRemoveSlot = (property, index) => {
  //   let selectedDay = { ...businessHours[property] }
  //   const slots = selectedDay.slots
  //   slots.splice(index, 1)
  //   selectedDay = {
  //     ...selectedDay,
  //     slots,
  //   }
  //   setBusinessHours({ ...businessHours, [property]: selectedDay })
  // }

  //* Debounce function to soften button submission
  const debounce_leading = (func, timeout = 2000) => {
    let timer
    return (...args) => {
      if (!timer) {
        func.apply(this, args)
      }
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = undefined
      }, timeout)
    }
  }

  const handleUpdateProfile = () => {
    const { address } = companyInfo

    const dataForUpdate = {
      company: {
        address,
        serviceRadius: serviceRadius.value,
        acceptTeleservice: serviceCall,
      },
      rates: billingItems?.map(({ providerCategoryId, typeId, value, isActive }) => {
        let startTime
        let endTime
        Object.keys(businessHours).forEach((key) => {
          if (businessHours[key].typeId === typeId) {
            const slots = businessHours[key].slots
            const { from, to } = slots
            const checked = businessHours[key].checked
            startTime = moment(from).format("hh:mm A")
            endTime = moment(to).format("hh:mm A")
            isActive = typeId === 1 ? true : checked
          }
        })
        return {
          providerCategoryId,
          typeId,
          value,
          startTime,
          endTime,
          isActive,
        }
      }),
    }
    console.log("DATA SENDING TO API: ", dataForUpdate)

    // Arr of errors
    const validateHoursAndRates = checkTimeAndRates(dataForUpdate.rates)
    const validateHoursWithRates = validateTimes(dataForUpdate.rates)

    if (validateHoursWithRates.length >= 1) {
      setTimeOverlap(true)
      setTimeError({
        status: true,
        message: validateHoursWithRates,
      })
    } else if (validateHoursAndRates.length >= 1) {
      setTimeError({
        status: true,
        message: validateHoursAndRates,
      })
      setTimeOverlap(true)
    } else {
      setLoading(true)
      updateProviderProfileInfo(dataForUpdate)
        .then(() => {
          showSuccessNotification(successnotification)
          getProviderProfile()
          setTimeout(() => {
            setLoading(false)
            setIsEdit(false)
          }, 1000)
        })
        .catch((err) => {
          showErrorNotification(err)
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
    }
  }

  //* Function to handle the debouncing
  const processSaveProfile = debounce_leading(() => handleUpdateProfile())

  // Check for errors with hours and rates
  const validateTimes = (rates) => {
    let isReturn
    let retArr = [
      {
        status: "error",
        error: "Please set rates higher than 0 on checked business hours.",
      },
    ]
    // If rates are empty, return error
    if (rates.length === 0) {
      isReturn = true
    }
    // Find checked days
    let checkedDaysArr = []
    for (let i = 0; i < categories.length; i++) {
      Object.keys(businessHours).forEach((key) => {
        if (businessHours[key].checked === true) {
          checkedDaysArr.push(businessHours[key].typeId)
        }
      })
    }
    // Count the total categories for each business hour
    let objCount = {}
    for (let i = 0; i < checkedDaysArr.length; i++) {
      objCount[checkedDaysArr[i]] = objCount[checkedDaysArr[i]] + 1 || 1
    }
    let objRatesCount = {}
    for (let i = 0; i < rates.length; i++) {
      objRatesCount[rates[i].typeId] = objRatesCount[rates[i].typeId] + 1 || 1
    }

    Object.keys(objCount).forEach((key) => {
      if (objCount[key] !== objRatesCount[key]) {
        isReturn = true
      }
    })
    if (Object.keys(objRatesCount).length === 0) {
      isReturn = true
    }
    return isReturn ? retArr : []
  }
  // Check the billing rate values for zero
  const checkBillingRates = () => {
    let res = []
    // Find checked days
    let checkedDaysArr = []
    for (let i = 0; i < categories.length; i++) {
      Object.keys(businessHours).forEach((key) => {
        if (businessHours[key].checked === true) {
          checkedDaysArr.push(businessHours[key].typeId)
        }
      })
    }
    // Count the total categories for each business hour
    let objCount = {}
    for (let i = 0; i < checkedDaysArr.length; i++) {
      objCount[checkedDaysArr[i]] = objCount[checkedDaysArr[i]] + 1 || 1
    }
    // Validate the rates for checked hours for 0
    billingItems?.map((item) => {
      Object.keys(objCount).forEach((key) => {
        if (parseInt(key) === item.typeId) {
          const valueInt = parseInt(item.value)
          if (valueInt === 0) {
            res.push(valueInt)
          }
        }
      })
    })
    if (res.length >= 1) {
      return true
    } else {
      return false
    }
  }

  const checkEndHours = (day, startTime, endTime, arr) => {
    const checkStart = startTime?.slice(6, 8) === "AM" ? 1 : -1
    const checkEnd = endTime?.slice(6, 8) === "AM" ? 1 : -1

    day.start = Number(startTime?.slice(0, 5).replace(":", "."))
    day.end = Number(endTime?.slice(0, 5).replace(":", "."))

    if (day.end * checkEnd >= 12) {
      arr.push({ status: "day", error: "Cannot work past midnight" })
    }
    // if checkstart and checkend is BOTH positive, check if checkEnd is Smaller
    if (checkStart === 1 && checkEnd === 1) {
      if (checkStart * day.start > checkEnd * day.end) {
        arr.push({
          status: "day",
          error: "End hours cannot be before start hours",
        })
      }
    } else if (checkStart === -1 && checkEnd === -1) {
      if (checkStart * day.start <= -12) {
        if (checkStart * day.start > day.end * checkEnd) {
          arr.push({
            status: "day",
            error: "End hours cannot be before start hours",
          })
        }
      } else if (checkStart * day.start < checkEnd * day.end) {
        arr.push({
          status: "day",
          error: "End hours cannot be before start hours",
        })
      }
    } else if (checkStart === -1 && checkEnd === 1) {
      arr.push({
        status: "day",
        error: "End hours cannot be before start hours",
      })
    }
    return arr
  }

  // ! Create a function that checks if the end time is less than the start time
  const checkTimeAndRates = (times) => {
    // console.log("HOURS + RATES: ", times)
    let res = []
    // * Validate these times
    let isWeekday = false
    let isAfterhour = false
    let isSaturday = false
    let isSunday = false

    let weekday = {}
    let afterhour = {}
    let saturday = {}
    let sunday = {}

    const weekdayFilter = times?.filter((item) => item.typeId === 1)
    const afterhourFilter = times?.filter((item) => item.typeId === 2)
    const saturdayFilter = times?.filter((item) => item.typeId === 3)
    const sundayFilter = times?.filter((item) => item.typeId === 4)

    if (weekdayFilter.length !== 0) isWeekday = true
    if (afterhourFilter.length !== 0) isAfterhour = true
    if (saturdayFilter.length !== 0) isSaturday = true
    if (sundayFilter.length !== 0) isSunday = true

    // If weekday and afterhour both true, check if times overlap
    if (isWeekday && isAfterhour) {
      weekday.start = Number(
        weekdayFilter[0].startTime?.slice(0, 5).replace(":", ".")
      )
      weekday.end = Number(weekdayFilter[0].endTime?.slice(0, 5).replace(":", "."))
      afterhour.start = Number(
        afterhourFilter[0].startTime?.slice(0, 5).replace(":", ".")
      )
      afterhour.end = Number(
        afterhourFilter[0].endTime?.slice(0, 5).replace(":", ".")
      )
      // Condition to check whether if afterhour start time overlaps with weekday end time
      if (weekday.end > afterhour.start && weekday.end <= afterhour.end) {
        res.push({
          status: "Workday and Afterhour",
          error: "Hours Must Not Overlap",
        })
      }
    }

    // Check if weekday is past midnight and if end time is before start time
    if (isWeekday) {
      checkEndHours(
        weekday,
        weekdayFilter[0].startTime,
        weekdayFilter[0].endTime,
        res
      )
    }

    // Check if after hour is past midnight and if end time is before start time
    if (isAfterhour) {
      checkEndHours(
        afterhour,
        afterhourFilter[0].startTime,
        afterhourFilter[0].endTime,
        res
      )
    }

    // Check if saturday is past midnight and if time is before start time
    if (isSaturday) {
      checkEndHours(
        saturday,
        saturdayFilter[0].startTime,
        saturdayFilter[0].endTime,
        res
      )
    }

    // Check if sunday is past midnight and if time is before start time
    if (isSunday) {
      checkEndHours(sunday, sundayFilter[0].startTime, sundayFilter[0].endTime, res)
    }

    const isValuesZero = checkBillingRates()
    if (isValuesZero === true) {
      res.push({
        status: "day",
        error: "Please set rates higher than 0 on checked business hours.",
      })
    }

    return res
  }

  const handleFromTime = (value, property) => {
    const selectedBusinessHour = businessHours[property]
    setBusinessHours({
      ...businessHours,
      [property]: {
        ...selectedBusinessHour,
        slots: {
          from: value,
          to: selectedBusinessHour.slots.to,
        },
      },
    })
  }

  const handleToTime = (value, property) => {
    const selectedBusinessHour = businessHours[property]
    setBusinessHours({
      ...businessHours,
      [property]: {
        ...selectedBusinessHour,
        slots: {
          to: value,
          from: selectedBusinessHour.slots.from,
        },
      },
    })
  }

  if (!companyInfo || Object.keys(companyInfo).length === 0) {
    return <SpinLoader />
  }

  const { businessName, address } = companyInfo
  const { line1, line2 = "", city, state, zip } = address

  if (isMobile) return <Redirect to={`/provider/dashboard/home`} />

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      {editBusinessAddress && (
        <AddressPopup
          onSubmit={(values) => {
            setCompanyInfo({
              ...companyInfo,
              address: values,
            })
          }}
          address={address}
          open={editBusinessAddress}
          setOpen={setEditBusinessAddress}
        />
      )}
      <h3 className="text-gray-600 text-xl">{title}</h3>
      <div className="my-3 flex items-center mb-6">
        <div className="flex flex-col justify-center items-center mr-3">
          <img
            // className="h-16 w-16 rounded-full mr-2 border-2 border-primary"
            className="h-16 w-16 rounded-full mr-2 border-primary"
            src={userIcon}
            alt="icon"
          />
          {/* <button className="text-sm text-red-500">{uploadphotolabel}</button> */}
        </div>
        <div className="flex-grow grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="flex border border-gray-400 rounded-md bg-white flex-none py-2 px-3 justify-between h-20">
            <div>
              <p className="text-red-500 text-xs">{businessnamelabel}</p>
              <p className="text-gray-700 text-sm font-bold">{businessName}</p>
            </div>
          </div>
          <div className="flex border border-gray-400 rounded-md bg-white flex-none py-2 px-3 justify-between h-20 overflow-hidden">
            <div>
              <p className="text-red-500 text-xs">{businessaddresslabel}</p>
              <p className="text-gray-700 text-sm font-bold">
                {`${line1} ${line2}, ${city}, ${state} ${zip}`}
              </p>
            </div>
            <div>
              <button
                onClick={() => {
                  setEditableBusiness("")
                  setEditBusinessAddress(true)
                  setEditServiceArea(false)
                }}
                className="text-sm text-red-500"
              >
                {editbuttonlabel}
              </button>
            </div>
          </div>
          <div className="flex border border-gray-400 rounded-md bg-white flex-none py-2 px-3 justify-between h-20 overflow-hidden">
            <div className="flex-grow">
              <p className="text-red-500 text-xs">{servicearealabel}</p>
              {editServiceArea ? (
                <SelectMenu
                  type="select"
                  name="serviceArea"
                  options={serviceAreaOptions}
                  value={serviceRadius}
                  onChange={setServiceRadius}
                  autoFocus={true}
                  onBlur={() => setEditServiceArea(false)}
                />
              ) : (
                <p className="text-gray-700 text-sm font-bold">
                  {serviceRadius && serviceRadius.label}
                </p>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  setEditableBusiness("")
                  setEditBusinessAddress(false)
                  setEditServiceArea(true)
                }}
                className="text-sm text-red-500"
              >
                {changebuttonlabel}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium mb-2">{subtitle1}</h2>
        {Object.keys(businessHours).map((key) => (
          <BusinessHourCard
            key={key}
            property={key}
            {...businessHours[key]}
            onChange={() => handleBusinessHourStatus(key)}
            // addSlot={handleAddSlot}
            // removeSlot={handleRemoveSlot}
            handleFromTime={(value) => handleFromTime(value, key)}
            handleToTime={(value) => handleToTime(value, key)}
            editable={editableBusiness}
            setEditable={(property) => {
              setEditableBusiness(property)
              setEditBusinessAddress(false)
              setEditServiceArea(false)
            }}
            isNotified={
              notifySetTime[0] === key &&
              notifySetTime[1] === true &&
              notifySetTime[2] === undefined
            }
            setNotifySetTime={setNotifySetTime}
            notifySetTime={notifySetTime}
            isEdit={isEdit}
          />
        ))}
      </div>
      <div className="my-3">
        <div className="flex justify-between">
          <h2 className="text-gray-700 font-medium">{subtitle2}</h2>
        </div>
        <div className="mt-2 shadow overflow-auto border-b border-gray-200 sm:rounded-lg p-0 bg-white">
          <Table
            columns={ratesColumn.filter(({ available }) => available)}
            data={billingItems}
            categories={categories}
            onChange={handleBillingRateChange}
            editableCategoryId={editableCategoryId}
            setEditableCategoryId={setEditableCategoryId}
            notifySetRate={notifySetRate}
            setNotifySetRate={setNotifySetRate}
            isEdit={isEdit}
          />
        </div>
      </div>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium mb-2">{subtitle3}</h2>
        <Toggle
          title={servicecalllabel}
          value={serviceCall}
          onChange={() => setServiceCall(!serviceCall)}
        />
        <ul className="list-disc mt-2 ml-6 text-gray-600">
          <li>{listdesc1}</li>
          <li>{listdesc2}</li>
          <li>{listdesc3}</li>
          <li>{listdesc4}</li>
        </ul>
      </div>
      {isEdit ? (
        <button
          onClick={() => {
            if (loading) return
            processSaveProfile()
          }}
          className="flex justify-center items-center rounded-full shadow-md px-2 py-1 text-s w-80 focus:outline-none bg-red-250 text-white cursor-pointer"
        >
          {loading && <SpinLoader />}
          {saveProfileLabel}
        </button>
      ) : (
        <button
          onClick={() => setIsEdit(true)}
          className="rounded-full shadow-md px-2 py-1 text-s w-80 focus:outline-none bg-primary text-white cursor-pointer"
        >
          {editProfileLabel}
        </button>
      )}

      <TimeErrorModal
        message={timeError?.message}
        open={timeOverlap}
        setOpen={setTimeOverlap}
      />
    </div>
  )
}

ProviderProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  getProviderProfile: PropTypes.func.isRequired,
  updateProviderProfileInfo: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  }
}

const mapDispatchToProps = {
  getProviderProfile,
  updateProviderProfileInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderProfile)
