import { useState, useEffect } from "react"
import { Redirect, Link, useRouteMatch, Switch, Route } from "react-router-dom"
import {
  ArrowSmRightIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  BadgeCheckIcon,
} from "@heroicons/react/outline"
import AddBankAccount from "./AddBankAccount"
import ViewInvoice from "./Invoice/ViewInvoice"
import PaymentsGroupImage from "../../../../assets/payments_group.png"
import Static from "../../../../static.json"
import { getBankDetails } from "../../../../actions/payment"
import SpinLoader from "../../../../components/SpinLoader"

const ProviderPayments = () => {
  const {
    dashboard: {
      payments: { pagetitle, title, description, addactionlabel, viewactionlabel },
    },
  } = Static

  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [bankInfo, setBankInfo] = useState(null)
  const [isMobile, setIsMobile] = useState(null)
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)

  const { path, url } = useRouteMatch()

  const checkMediaScreen = () =>
    window.screen.width < 768 ? setIsMobile(true) : setIsMobile(false)

  useEffect(() => {
    document.title = pagetitle
    checkMediaScreen()
  }, [])

  useEffect(() => {
    getBankDetails()
      .then((res) => {
        if (res.data.length >= 1) {
          setBankInfo(res.data[0])
          setIsLoading(false)
        }
      })
      .catch((err) => {
        // console.log("Error:", err.response)
        if (err) {
          setIsLoading(false)
          setBankInfo(null)
        }
      })
  }, [])

  // if (modalOpen) {
  //   return <AddBankAccount setModalOpen={setModalOpen} />
  // }

  // if (isInvoiceOpen) {
  //   console.log(" open up modal ")
  //   return <ViewInvoice setIsInvoiceOpen={setIsInvoiceOpen} />
  // }

  if (isMobile) return <Redirect to={`/provider/dashboard/home`} />

  return (
    <>
      <Switch>
        <Route exact path={`${url}`}>
          <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
            <div className="flex relative h-56 z-0">
              <img
                className="hidden sm:block absolute right-0  w-96 h-96 p-6 z-0"
                src={PaymentsGroupImage}
                alt="img"
              />
              <div className="flex flex-col p-6 justify-center z-10 max-w-md">
                <h3 className="text-gray-600 text-2xl">{title}</h3>
              </div>
            </div>
            <div className="my-3 z-10 flex">
              {isLoading ? (
                <button className="m-8 flex flex-row w-full items-center sm:w-1/2 h-24 bg-gradient-to-r from-blue-350 to-blue-450 rounded-xl">
                  <CreditCardIcon className="p-4 h-24 w-24 bg-white bg-opacity-20 text-white" />
                  <div className="flex justify-between p-6 w-full text-white uppercase font-bold">
                    {isLoading && <SpinLoader />}
                    Loading..
                    <ArrowSmRightIcon className="h-6 w-6" />
                  </div>
                </button>
              ) : (
                <>
                  {bankInfo !== null && (
                    <button className="m-8 flex flex-row w-full items-center sm:w-1/2 h-24 bg-gradient-to-r from-blue-350 to-blue-450 rounded-xl">
                      <CreditCardIcon className="p-4 h-24 w-24 bg-white bg-opacity-20 text-white" />
                      <div className="flex justify-between p-6 w-full text-white uppercase font-bold">
                        <div>
                          <p> {bankInfo[0].description} </p>
                          <p>Bank Account number ending in {bankInfo[0].number}</p>
                        </div>
                      </div>
                    </button>
                  )}
                  {bankInfo === null && (
                    <Link
                      to={`${url}/add-bank-account`}
                      className="m-8 flex flex-row w-full items-center sm:w-1/2 h-24 bg-gradient-to-r from-blue-350 to-blue-450 rounded-xl"
                      // onClick={() => setModalOpen(true)}
                    >
                      <CreditCardIcon className="p-4 h-24 w-24 bg-white bg-opacity-20 text-white" />
                      <div className="flex justify-between p-6 w-full text-white uppercase font-bold">
                        {bankInfo === null && addactionlabel}
                        <ArrowSmRightIcon className="h-6 w-6" />
                      </div>
                    </Link>
                  )}
                </>
              )}
              <Link
                to="/provider/dashboard/payments/invoices"
                className="m-8 flex flex-row w-full items-center sm:w-1/2 h-24 bg-gradient-to-r from-green-350 to-green-450 rounded-xl"
              >
                {/* <button
                // className="m-8 flex flex-row w-full items-center sm:w-1/2 h-24 bg-gradient-to-r from-green-350 to-green-450 rounded-xl"
                // onClick={() => setIsInvoiceOpen(true)}
                > */}
                <CurrencyDollarIcon className="p-4 h-24 w-24 bg-white bg-opacity-20 text-white" />
                <div className="flex justify-between p-6 w-full text-white uppercase font-bold">
                  {viewactionlabel}
                  <ArrowSmRightIcon className="h-6 w-6" />
                </div>
                {/* </button> */}
              </Link>
            </div>
          </div>
        </Route>
        <Route path={`${url}/invoices`}>
          <ViewInvoice />
        </Route>
        <Route path={`${url}/add-bank-account`}>
          <AddBankAccount />
        </Route>
      </Switch>
    </>
  )
}

export default ProviderPayments
