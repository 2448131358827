import { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Static from "../../../../static.json"
import { settingsLink } from "../../../../constants/constants"
import { Redirect } from "react-router-dom"

const ProviderSettings = () => {
  const {
    dashboard: {
      settings: { pagetitle, title },
    },
  } = Static

  const [isMobile, setIsMobile] = useState(null)

  const checkMediaScreen = () =>
    window.screen.width < 768 ? setIsMobile(true) : setIsMobile(false)

  useEffect(() => {
    document.title = pagetitle
    checkMediaScreen()
  }, [])

  if (isMobile) return <Redirect to={`/provider/dashboard/home`} />

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <h3 className="text-gray-600 text-xl mb-4">{title}</h3>
      {settingsLink.map((setting, key) => {
        return (
          <Fragment key={key}>
            <div className="flex m-4">
              <img src={setting.Icon} alt={setting.name} className="w-8 h-auto" />
              {!setting.route ? (
                <p
                  className="flex items-center ml-6 underline font-normal"
                  style={{ cursor: "not-allowed" }}
                >
                  {setting.name}
                </p>
              ) : (
                <Link
                  className="flex items-center ml-6 underline font-normal"
                  to={setting.route}
                >
                  {setting.name}
                </Link>
              )}
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

ProviderSettings.propTypes = {}

export default ProviderSettings
