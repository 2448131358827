import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import ProtectedRoute from "./ProtectedRoute"
import ProviderSignUp from "./containers/Provider/SignUp"
import ProviderSignIn from "./containers/Provider/SignIn"
import ProviderDashboard from "./containers/Provider/Dashboard"
import TermsOfService from "./containers/Provider/TermsOfService"
import PrivacyPolicy from "./containers/Provider/PrivacyPolicy"
import ForgotPasswordPage from "./containers/Provider/ForgotPassword"
import SetPassword from "./containers/Provider/SetPassword"

function App() {
  return (
    <>
      <ToastContainer />
      <Switch>
        <ProtectedRoute path="/provider/dashboard">
          <ProviderDashboard />
        </ProtectedRoute>
        <Route path="/signup">
          <ProviderSignUp />
        </Route>
        <Route path="/signin">
          <ProviderSignIn />
        </Route>
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/setPassword/">
          <SetPassword />
        </Route>
        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <ProviderSignIn />
        </Route>
      </Switch>
    </>
  )
}

export default App
