import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Field, Form } from "react-final-form"
import { useHistory, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import { setPassword } from "../../../actions/user"
import LogoImage from "../../../assets/logo.svg"
import BetterImage from "../../../assets/Better.png"
import SignUpMainImage from "../../../assets/SignUp.png"
import { Button } from "../../../components/buttons"
import { TextInputField } from "../../../components/inputs"
import { Checklist } from "./Checklist"

import {
  passwordValidationPattern,
  numberContains,
  alphabetLower,
  alphabetUpper,
  specialCharacter,
} from "../../../constants/constants"
import Static from "../../../static.json"
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/helper"
import SplashPage from "../SignIn/SplashPage"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const SetPassword = ({ setPassword }) => {
  let query = useQuery()
  const {
    signup: {
      index: {
        resetpagetitle,
        subtitle2,
        subtitle3,
        subtitle4,
        successmessageforgotpassword,
        description1,
        errormessageforgotpassword,
        description2,
        description3,
        input: { resetpasswordplaceholder, resetconfirmpasswordplaceholder },
        validation: { passwordvalidation, passwordconfirmationvalidation },
        passwordLength,
        aSpecialCharacterLabel,
        atLeastNumberLabel,
        atLeastUpperCaseLabel,
        atLeastLowerCaseLabel,
        resetPasswordInstruction,
      },
    },
  } = Static
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = resetpagetitle
  }, [])

  const FormValidation = (values) => {
    const { password, passwordConfirmation } = values
    const errors = {}
    if (!password || !passwordValidationPattern.test(password)) {
      errors.password = " "
      errors.specialCharacter = specialCharacter.test(password)
      errors.alphabetUpper = alphabetUpper.test(password)
      errors.alphabetLower =
        password === undefined ? false : alphabetLower.test(password)
      errors.number = numberContains.test(password)
      errors.passwordLength = password && password.length > 7 ? true : false
    }

    if (password != passwordConfirmation) {
      errors.passwordConfirmation = passwordconfirmationvalidation
    }
    console.log(errors)
    return errors
  }

  const onSubmit = (values) => {
    let { password } = values
    let data = { username: query.get("username"), password: password }
    setPassword(data)
      .then(() => {
        setLoading(false)
        showSuccessNotification(successmessageforgotpassword)
        history.push("/set-password?success=true")
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error, errormessageforgotpassword)
        console.log(error)
      })
  }

  return (
    <div className="flex min-h-screen mx-auto">
      <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full" style={{ maxWidth: 370 }}>
          <div>
            <img className="h-12 w-auto m-auto" src={LogoImage} alt="Workflow" />
            <h4 className="mt-6 text-2xl font-regular text-gray-900 text-center">
              Invites-Only Network for
            </h4>
            <h4 className="text-2xl font-regular text-gray-900 text-center">
              Service Providers
            </h4>
          </div>

          <div className="mt-8">
            {query.get("success") && (
              <section aria-label="forgot-password-success">
                <p className="mb-2">Password reset done</p>

                <div className="mt-5 text-center">
                  {/* <Link
                    to="/signin"
                    className="inline-flex justify-center py-3 px-4 text-lg w-60 rounded-md shadow-md font-medium text-white bg-primary hover:opacity-70"
                  >
                    Login
                  </Link> */}
                </div>
              </section>
            )}

            {!query.get("success") && (
              <div className="mt-6">
                <div className="my-8">
                  <h2 className="text-xl font-bold text-gray-700">
                    Create Your Secure Password
                  </h2>
                </div>
                <Form
                  onSubmit={onSubmit}
                  validate={FormValidation}
                  render={({ handleSubmit, errors }) => (
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <Field
                        type="password"
                        name="password"
                        placeholder={resetpasswordplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      <Field
                        type="password"
                        name="passwordConfirmation"
                        placeholder={resetconfirmpasswordplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      <p className="font-bold">{resetPasswordInstruction}</p>
                      <div className="px-2 py-1">
                        <Checklist
                          isError={errors.passwordLength}
                          text={passwordLength}
                        />
                        <Checklist
                          isError={errors.specialCharacter}
                          text={aSpecialCharacterLabel}
                        />
                        <Checklist
                          isError={errors.alphabetUpper}
                          text={atLeastUpperCaseLabel}
                        />
                        <Checklist
                          isError={errors.alphabetLower}
                          text={atLeastLowerCaseLabel}
                        />
                        <Checklist
                          isError={errors.number}
                          text={atLeastNumberLabel}
                        />
                      </div>
                      <Button loading={loading} type="submit" title="Continue" />
                    </form>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SplashPage />
      {/* <div
        className="hidden lg:flex flex-col relative w-0 w-1/2 flex-1 justify-center"
        style={{
          background: "linear-gradient(109.83deg, #F1BF00 4.84%, #FFE924 98.74%)",
        }}
      >
        <div className="flex justify-center mt-8">
          <h2 className="text-5xl text-center mb-4 relative">
            {subtitle2} <b>{subtitle3}</b> {subtitle4}
            <img
              className="w-auto max-w-full absolute -bottom-8 left-24"
              src={BetterImage}
              alt="Workflow"
            />
          </h2>
        </div>
        <div className="flex justify-center mt-12">
          <img className="w-auto max-w-full" src={SignUpMainImage} alt="Workflow" />
        </div>
        <div className="flex flex-col justify-center p-12 w-full mx-auto">
          <p className="text-xl font-regular whitespace-pre-wrap text-center">
            {description1}
          </p>
          <p className="text-xl font-regular whitespace-pre-wrap text-center">
            {description2}
          </p>
          <p className="text-xl font-semibold whitespace-pre-wrap text-center mt-8">
            {description3}
          </p>
        </div>
      </div> */}
    </div>
  )
}

SetPassword.propTypes = {
  setPassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  setPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword)
