import PropTypes from "prop-types"
import { Field, Form } from "react-final-form"

import { RoundedButton } from "../../../components/buttons"
import { ToggleField } from "../../../components/inputs"
import Toolbar from "../../../components/Toolbar"

import Static from "../../../static.json"

const ConditionsComponent = ({ onSubmit, setSteps, loading }) => {
  const {
    signup: {
      condition: {
        input: {
          backgroundtitle,
          backgroundlabel,
          opstitle,
          opslabel,
          termtitle,
          termlabel,
          buttontitle,
        },
        validation: { background, ops, term },
      },
    },
  } = Static

  const submitHandler = (values) => {
    onSubmit(values, "policy")
  }

  const FormValidation = (values) => {
    const { backgroundCheck, smsEmailOptIn, terms } = values
    const formError = {}

    if (!backgroundCheck) {
      formError.backgroundCheck = background
    }
    if (!smsEmailOptIn) {
      formError.smsEmailOptIn = ops
    }
    if (!terms) {
      formError.terms = term
    }
    return formError
  }

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none flex flex-col justify-between">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <Form
            validate={FormValidation}
            onSubmit={submitHandler}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="space-y-4">
                <Field
                  type="check"
                  title={backgroundtitle}
                  label={backgroundlabel}
                  name="backgroundCheck"
                  component={ToggleField}
                  widthFull
                />
                <Field
                  type="check"
                  title={opstitle}
                  label={opslabel}
                  name="smsEmailOptIn"
                  component={ToggleField}
                  widthFull
                />
                <div className="relative">
                  <Field
                    type="check"
                    title={termtitle}
                    label={termlabel}
                    name="terms"
                    component={ToggleField}
                    widthFull
                  />
                  <div className="flex-grow flex flex-col mr-8 absolute top-0 border border-black opacity-0">
                    <p className="text-md font-medium text-text-primary">
                      Terms of Use and Privacy Notice
                    </p>
                    <p className="text-sm text-text-primary">
                      By proceeding, I agree to Tirios{"'"}s{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://serviceprovider.tirios.ai/terms-of-service",
                            "_blank"
                          )
                        }
                      >
                        Terms of Use
                      </span>{" "}
                      and acknowledge that I have read the{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://serviceprovider.tirios.ai/privacy-policy",
                            "_blank"
                          )
                        }
                      >
                        Privacy Notice.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex pt-12">
                  <RoundedButton
                    title="Back"
                    variant="secondary"
                    onClick={() => setSteps(1)}
                  />
                  <RoundedButton
                    variant="primary"
                    className="ml-3"
                    type="submit"
                    title={buttontitle}
                    loading={loading}
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
      <div className="my-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <Toolbar />
        </div>
      </div>
    </main>
  )
}

ConditionsComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

ConditionsComponent.defaultProps = {
  loading: false,
}

export default ConditionsComponent
