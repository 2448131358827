import React, { useState, useEffect, useContext } from "react"
import { useHistory, useParams, Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import { ChevronLeftIcon, TrashIcon } from "@heroicons/react/outline"
import moment from "moment"
import CurrencyInput from "react-currency-input-field"

import InvoiceModal from "./InvoiceModal"
import { InvoiceContext } from "./InvoiceContext"
import {
  getInvoiceById,
  updateInvoiceById,
  submitInvoice,
} from "../../../../../services/api"
import BellIcon from "../../../../../assets/bell-icon.png"
import { getProviderId } from "../../../../../utils/auth"
import { invoicesData, invoiceColumns } from "../../../../../constants/constants"

const ReviewInvoice = ({ date }) => {
  const history = useHistory()
  const { invoiceId } = useParams()
  const {
    handleAdd,
    isEdit,
    handleEdit,
    handleSave,
    isModalOpen,
    setIsModalOpen,
    handleSubmit,
    isSubmit,
    invoiceDetails,
    setInvoiceDetails,
    invoiceAddress,
    setInvoiceAddress,
    invoiceParts,
    setInvoiceParts,
    isDeleteModal,
    setIsDeleteModal,
    confirmDelete,
    totalInvoiceLabor,
    setTotalInvoiceLabor,
    taxRate,
    setTaxRate,
    incentive,
    setIncentive,
    incentiveLabel,
    setIncentiveLabel,
    fee,
    setFee,
    feeLabel,
    setFeeLabel,
  } = useContext(InvoiceContext)
  const [invoiceStatus, setInvoiceStatus] = useState()

  const { description, quantity, pricePerUnit, total } = invoiceColumns

  if (!date) {
    return <Redirect to={"/provider/dashboard/payments/invoices/"} />
  }
  useEffect(() => {
    const getInvoiceDetailsById = async () => {
      try {
        const res = await getInvoiceById(invoiceId)
        const data = res.data.data
        console.log("DATA", data)
        const { invoice, job, invoiceLines } = data
        setInvoiceDetails(invoice)
        setInvoiceAddress(job)
        setInvoiceParts(invoiceLines)
        setTotalInvoiceLabor(invoice?.totalLabor)
        setInvoiceStatus(() => {
          return invoicesData.invoiceStatuses[invoice?.invoiceStatus].value
        })
        setTaxRate(invoice?.taxRate)

        setIncentive(
          invoice?.tiriosIncentiveAmount ? invoice?.tiriosIncentiveAmount : 0
        )
        setFee(invoice?.tiriosFeeAmount ? invoice?.tiriosFeeAmount : 0)
        setIncentiveLabel(job?.tiriosIncentive)
        setFeeLabel(job?.tiriosFee)
      } catch (err) {
        console.log(err)
      }
    }
    getInvoiceDetailsById()
  }, [])

  const sendInvoiceForUpdate = () => {
    handleSubmit()
  }

  const submitUpdate = async () => {
    try {
      const calculateTotalPartsArr = []
      const invoiceLinesToSend = invoiceParts.map(
        ({ itemQty, itemPrice, partName }, index) => {
          const total = itemQty * itemPrice
          calculateTotalPartsArr.push(total)
          return {
            lineNumber: index + 1,
            itemQty: Number(itemQty),
            itemPrice: Number(itemPrice),
            partName,
          }
        }
      )
      const totalPartsToSend = calculateTotalPartsArr.reduce(
        (prev, curr) => prev + curr,
        0
      )
      const data = {
        totalParts: totalPartsToSend,
        totalLabor: Number(totalInvoiceLabor),
        totalTax: Number(invoiceDetails?.totalTax),
        total: Math.round(invoiceDetails?.total * 100) / 100,
        invoiceLines: invoiceLinesToSend,
      }
      console.log("DATA TO  SEND: ", data)
      const res = await updateInvoiceById(invoiceId, data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const submitInvoiceForReview = async () => {
    try {
      await submitInvoice(invoiceId)
      setTimeout(() => {
        history.push("/provider/dashboard/payments/invoices")
        window.location.reload()
      }, 200)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="py-4 pl-3 lg:pr-12 pr-3 ">
        <button
          onClick={() => {
            history.push("/provider/dashboard/payments/invoices")
            setTimeout(() => {
              window.location.reload()
            }, 0)
          }}
          className="flex mb-6 mt-3 text-gray-500"
        >
          <ChevronLeftIcon className="text-gray-500 h-6 w-8" />
          Back to Invoices
        </button>
        <h1 className="text-4xl mt-5 mb-5 tracking-wide text-gray-primary">
          Invoices
        </h1>
        {/* Invoice Details Container */}
        <p className="text-red-350 font-bold"> {invoiceStatus} </p>
        {/* Invoice Address */}
        <div className="absolute right-14 top-20 text-right font-bold">
          <p>{moment(`${date}z`).format("MMM DD, YYYY")}</p>
          <p> {invoiceAddress?.property.addressLine1} </p>
          <p>
            {invoiceAddress?.property.city}, {invoiceAddress?.property.state}{" "}
            {invoiceAddress?.property.zipCode}
          </p>
          <p> Invoice # {invoiceDetails?.invoiceId}</p>
        </div>
        <div className="table-fixed w-full bg-white mt-4 shadow p-4 rounded-md flex border  min-w-fit">
          <div className="w-2/3  max-w-5xl">
            <table className="table-fixed bg-white w-full ">
              <thead className="border-b-2 border-gray-light">
                <tr>
                  <th className="text-left w-1/4 text-gray-primary font-normal pb-2">
                    {description}
                  </th>
                  <th className="text-left w-1/4 text-gray-primary font-normal pb-2 ">
                    {quantity}
                  </th>
                  <th className="text-left w-1/4 text-gray-primary font-normal pb-2 ">
                    {pricePerUnit}
                  </th>
                  <th className="text-right w-1/4 pr-12 text-gray-primary font-normal pb-2 ">
                    {total}
                  </th>
                </tr>
              </thead>
            </table>
            <div className="w-full">
              <InvoiceRow
                description="Labor"
                quantity="--"
                pricePerUnit=" --"
                total={totalInvoiceLabor}
              />
              {invoiceParts?.map(({ partName, itemPrice, itemQty }, index) => {
                if (isEdit) {
                  return (
                    <InvoiceRowEdit
                      description={`${partName}`}
                      quantity={itemQty}
                      pricePerUnit={parseFloat(itemPrice).toFixed(2)}
                      total={itemQty * itemPrice}
                      index={index}
                      key={partName + index}
                    />
                  )
                } else {
                  return (
                    <InvoiceRow
                      description={`${partName}`}
                      quantity={itemQty}
                      pricePerUnit={parseFloat(itemPrice).toFixed(2)}
                      total={itemQty * itemPrice}
                      key={index}
                    />
                  )
                }
              })}
            </div>

            <div className="rounded-md mt-12 pl-4 pr-12 pt-4 pb-6 bg-gray-bg">
              <div className="flex justify-between pb-6">
                <p>Subtotal</p>
                <p> ${invoiceDetails?.totalLabor.toFixed(2)} </p>
              </div>
              <div className="flex justify-between pb-6">
                <p>Taxes, 8.25%</p>
                <p> +${invoiceDetails?.totalTax.toFixed(2)} </p>
              </div>
              {incentive > 0 && (
                <div className="flex justify-between pb-6">
                  <p>Bonus Incentive, {incentiveLabel}</p>
                  <p> +${incentive?.toFixed(2)} </p>
                </div>
              )}
              <div className="flex justify-between pb-6">
                <p>Tirios Service Fee, {feeLabel ? feeLabel : "0%"}</p>
                <p> -${fee?.toFixed(2)} </p>
              </div>
              <div className="flex justify-between font-bold">
                <p>Total Payment</p>
                <p> ${invoiceDetails?.total.toFixed(2)} </p>
              </div>
            </div>
          </div>
          <div className="w-1/3 max-w-lg flex flex-col-reverse relative">
            {isEdit && (
              <div className="absolute top-20 left-2">
                Labor cannot be edited. Please contact members@tirios.ai
              </div>
            )}
            <div className="flex flex-col ml-5">
              {isEdit ? (
                <div className="relative flex flex-col items-center ">
                  <button
                    className="bg-yellow-bg w-48 h-10 rounded-full mb-4"
                    onClick={handleAdd}
                  >
                    Add Item
                  </button>
                  <button
                    className="bg-green-primary w-48 h-10 rounded-full mb-4 text-white"
                    onClick={() => {
                      handleSave()
                      submitUpdate()
                    }}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className="relative flex flex-col items-center ">
                  <button
                    className="bg-yellow-bg w-48 h-10 rounded-full mb-4"
                    onClick={handleEdit}
                  >
                    Edit Invoice
                  </button>
                  <div className="relative">
                    <button
                      className="bg-green-primary w-48 h-10 rounded-full mb-4 text-white"
                      onClick={sendInvoiceForUpdate}
                    >
                      Submit for Payment
                    </button>
                    <img
                      src={BellIcon}
                      alt="bell"
                      className="absolute bottom-6 -left-6 h-4"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && isSubmit && (
        <InvoiceModal
          message={
            "Are you sure you want to submit for payment?  This action cannot be undone."
          }
          open={isModalOpen}
          setOpen={setIsModalOpen}
          actionLabel={"Submit for Payment"}
          type={"submit"}
          actionFunction={submitInvoiceForReview}
        />
      )}
      {isDeleteModal && (
        <InvoiceModal
          message={
            "Are you sure you want to delete this item?  This action cannot be undone."
          }
          open={isDeleteModal}
          setOpen={setIsDeleteModal}
          actionLabel={"Delete Item"}
          actionFunction={confirmDelete}
        />
      )}
    </>
  )
}
const InvoiceRow = ({ description, quantity, pricePerUnit, total }) => {
  return (
    <div className=" mt-2 mb-2 rounded-md flex justify-between bg-gray-bg ">
      <td className="p-4 h-16  w-1/4">{description}</td>
      <td className="p-4 h-16  w-1/4">{quantity}</td>
      <td className="p-4 h-16  w-1/4">${pricePerUnit}</td>
      <td className="p-4 h-16  w-1/4 text-right mr-8">
        ${total && total.toFixed(2)}
      </td>
    </div>
  )
}

InvoiceRow.propTypes = {
  description: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  pricePerUnit: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
}

const InvoiceRowEdit = ({ description, quantity, pricePerUnit, total, index }) => {
  const { handleDelete, handleInvoiceEdit } = useContext(InvoiceContext)
  const [newDescription, setNewDescription] = useState(description)
  const [newQuantity, setNewQuantity] = useState(quantity)
  const [newUnitPrice, setNewUnitPrice] = useState(pricePerUnit)
  const [newTotal, setNewTotal] = useState(total)

  useEffect(() => {
    const calculateNewTotal = () => {
      const updatedTotal = Number(newQuantity) * Number(newUnitPrice)
      setNewTotal(updatedTotal)
    }
    calculateNewTotal()
  }, [newQuantity, newUnitPrice, newQuantity, newUnitPrice])

  const isNumberKey = (obj, decimals) => {
    if (obj.value % Math.round(obj.value)) {
      const divisor = Math.pow(10, decimals)
      obj.value = Math.floor(obj.value * divisor) / divisor
    }
  }

  return (
    <div className=" mt-2 mb-2 rounded-md flex justify-between items-center bg-gray-bg">
      <td className="p-4 h-16  w-1/4 flex items-center">
        <input
          type="text"
          value={newDescription}
          onChange={(e) =>
            handleInvoiceEdit(e, setNewDescription, "partName", index)
          }
          className="rounded-md w-4/5"
          placeholder="Part Description"
          autoFocus
        />
      </td>
      <td className="p-4 h-16  w-1/4 flex items-center">
        <input
          type="number"
          value={newQuantity}
          onChange={(e) => handleInvoiceEdit(e, setNewQuantity, "itemQty", index)}
          className="rounded-md w-20"
          onKeyDown={(evt) => evt.key === "." && evt.preventDefault()}
        />
      </td>
      <td className="p-4 h-16  w-1/4 flex items-center">
        <CurrencyInput
          id="input-example"
          name="input-name"
          placeholder="0.00"
          value={newUnitPrice}
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={(value) =>
            handleInvoiceEdit(value, setNewUnitPrice, "itemPrice", index, true)
          }
          className="rounded-md w-32"
        />
      </td>
      <td className="p-4 h-16  w-1/4 flex items-center">
        <div className="flex items-center flex-row-reverse w-full relative ">
          <p className="text-right   mr-16">${newTotal.toFixed(2)}</p>
          <TrashIcon
            onClick={() => handleDelete(index)}
            className="p-1  h-7 w-7 rounded-full bg-red-700 text-white shadow-md cursor-pointer absolute right-0"
          />
        </div>
      </td>
    </div>
  )
}

InvoiceRowEdit.propTypes = {
  description: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  pricePerUnit: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  index: PropTypes.number,
}

ReviewInvoice.propTypes = {
  date: PropTypes.string,
}

export default ReviewInvoice
