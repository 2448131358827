import {
  SIGN_IN,
  SIGN_UP,
  LOG_OUT,
  FORGOT_PASSWORD,
  SEND_VERIFICATION_NOTIFICATION,
  SET_PASSWORD,
  CHANGE_PASSWORD,
  // VERIFY_EMAIL,
} from "./types"
import {
  Signin,
  Signup,
  ForgotPassword,
  SetPassword,
  VerifyEmailAndPhonenumber,
  ChangePassword,
} from "../services/user"
import { sendRequestLink } from "../services/api"
import { getProviderId, getMemberId, getUserName } from "../utils/auth"

export const signIn = (email, password) => async (dispatch) => {
  try {
    const res = await Signin({ email, password })

    dispatch({
      type: SIGN_IN,
      payload: res.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const forgotPassword = (data) => async (dispatch) => {
  try {
    const res = await ForgotPassword(data)
    dispatch({
      type: FORGOT_PASSWORD,
      payload: res.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const changePassword =
  (idToken, accessToken, oldPassword, newPassword) => async (dispatch) => {
    try {
      const res = await ChangePassword(idToken, getUserName(), {
        accessToken,
        oldPassword,
        newPassword,
      })
      dispatch({
        type: CHANGE_PASSWORD,
        payload: res.data,
      })
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

export const setPassword = (data) => async (dispatch) => {
  try {
    const res = await SetPassword(data)

    dispatch({
      type: SET_PASSWORD,
      payload: res.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const signUp = (data) => async (dispatch) => {
  try {
    const res = await Signup(data)

    dispatch({
      type: SIGN_UP,
      payload: res.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const logOut = () => (dispatch) => {
  try {
    dispatch({
      type: LOG_OUT,
    })

    return Promise.resolve()
  } catch (err) {
    return Promise.reject(err)
  }
}

export const sendVerification = () => async (dispatch) => {
  try {
    await sendRequestLink(getProviderId(), getMemberId())

    dispatch({
      type: SEND_VERIFICATION_NOTIFICATION,
    })

    return Promise.resolve()
  } catch (err) {
    return Promise.reject(err)
  }
}

export const verifyEmailAndPhone =
  (email, phoneNumber, firstName, lastName) => async () => {
    try {
      const res = await VerifyEmailAndPhonenumber({
        phoneNumber,
        email,
        firstName,
        lastName,
      })

      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
