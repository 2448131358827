import { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { Field, Form } from "react-final-form"
import { toast } from "react-toastify"
import PhoneInput from "react-phone-input-2"

import { Button } from "../../../../components/buttons"
import {
  CheckboxField,
  TextInputField,
  SelectInputField,
  CheckBoxFieldMember,
} from "../../../../components/inputs"
import {
  emailValidationPattern,
  toastSuccessOptions,
  addMemberRoles,
  tradeCategories,
} from "../../../../constants/constants"
import { showErrorNotification } from "../../../../utils/helper"

import Static from "../../../../static.json"

let rolesArr = [
  { name: addMemberRoles.owner, isChecked: false },
  { name: addMemberRoles.worker, isChecked: false },
  { name: addMemberRoles.admin, isChecked: false },
]

const NewMemberModal = ({ onSubmit, open, setOpen, roles, categories }) => {
  const {
    dashboard: {
      members: {
        modal: {
          title,
          subtitle,
          firstnameplaceholder,
          firstnamevalidation,
          lastnameplaceholder,
          lastnamevalidation,
          phonenumberplaceholder,
          phonenumbervalidation,
          emailplaceholder,
          emailvalidation,
          tradeslabel,
          tradesplaceholder,
          tradesvalidation,
          actionbuttontitle,
          trickcontent,
        },
      },
    },
  } = Static
  const [phone, setPhone] = useState()
  const [phoneTouch, setPhoneTouch] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [tradeError, setTradeError] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [checkedRole, setCheckedRole] = useState("")
  const [roleState, setRoleState] = useState(rolesArr)

  const clearState = () => {
    const currentRoleState = [...roleState]
    for (let i = 0; i < currentRoleState.length; i++) {
      if (currentRoleState[i].isChecked) {
        currentRoleState[i].isChecked = false
      }
    }
    setRoleState(currentRoleState)
  }

  useEffect(() => {
    const currentRoleState = [...roleState]
    for (let i = 0; i < currentRoleState.length; i++) {
      if (currentRoleState[i].name === checkedRole) {
        console.log(currentRoleState[i].name)
        if (currentRoleState[i].isChecked) {
          currentRoleState[i].isChecked = false
        } else {
          currentRoleState[i].isChecked = true
        }
      }
    }
    setRoleState(currentRoleState)
  }, [isClicked])

  useEffect(() => {
    clearState()
    return () => {
      clearState()
    }
  }, [open])

  const renderSuccessScreen = (firstName) => {
    return (
      <div
        onClick={() => location.reload()}
        className="p-4 bg-white flex flex-col justify-center items-center"
      >
        <CheckCircleIcon className="h-12 w-12 text-primary" aria-hidden="true" />
        <h2 className="text-black font-bold mb-1">Congrats!</h2>
        <p className="text-black text-center mb-2">
          {`You've successfully invited ${firstName} to your organization`}
        </p>
        <Button title={"OK"} />
      </div>
    )
  }

  const handleSelectedRole = (roleName) => {
    setError(false)
    setIsClicked(!isClicked)
    setCheckedRole(roleName)
  }

  const submitHandler = (values) => {
    const { firstName, lastName, email, categories } = values

    if (!phone || phone.length !== 11) {
      setPhoneTouch(true)
      return
    }

    const currentRoleStatus = [...roleState]
    const selectedRoles = []
    for (let i = 0; i < currentRoleStatus.length; i++) {
      if (currentRoleStatus[i].isChecked === true) {
        if (currentRoleStatus[i].name === addMemberRoles.worker) {
          selectedRoles.push(addMemberRoles.pendingWorker)
        } else {
          selectedRoles.push(currentRoleStatus[i].name)
        }
      }
    }

    // if (selectedRoles.length === 0) {
    //   setError(true)
    //   return
    // } else {
    //   setError(false)
    // }
    const updatedCategories =
      roleState[1].isChecked === false ? [] : categories.map(({ value }) => value)
    console.log("categories", updatedCategories)

    // if (roleState[1].isChecked === true && updatedCategories.length === 0) {
    //   setTradeError(true)
    //   return
    // } else {
    //   setTradeError(false)
    // }
    setLoading(true)
    onSubmit({
      firstname: firstName,
      lastname: lastName,
      email,
      phoneNumber: `+${phone}`,
      roles: ["PENDING_WORKER"],
      categories: categories.map(({ value }) => value),
      // categories: updatedCategories,
    })
      .then(() => {
        setLoading(false)
        setOpen(false)
        toast(renderSuccessScreen(firstName), {
          ...toastSuccessOptions,
          style: { backgroundColor: "#ffffff" },
        })
      })
      .catch((error) => {
        showErrorNotification(error)
        setLoading(false)
      })
  }
  const tradingCategoriesOptions = []
  categories.forEach((category) => {
    tradingCategoriesOptions.push({
      value: category.name,
      label: tradeCategories[category.name],
    })
  })
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-2 pt-3 pb-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{subtitle}</p>
                  </div>
                </div>
              </div>
              <div className="my-2">
                <Form
                  onSubmit={submitHandler}
                  render={({ handleSubmit, values, valid }) => (
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <Field
                          validate={(value) => !value && firstnamevalidation}
                          required
                          type="text"
                          name="firstName"
                          placeholder={firstnameplaceholder}
                          component={TextInputField}
                          widthFull
                        />
                        <Field
                          validate={(value) => !value && lastnamevalidation}
                          required
                          type="text"
                          name="lastName"
                          placeholder={lastnameplaceholder}
                          component={TextInputField}
                          widthFull
                        />
                        <div>
                          <PhoneInput
                            specialLabel=""
                            country={"us"}
                            value={phone}
                            placeholder={phonenumberplaceholder}
                            onChange={(value) => {
                              setPhone(value)
                              setPhoneTouch(true)
                            }}
                            onlyCountries={["us"]}
                            inputClass="appearance-none block p-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:border-green-primary sm:text-sm w-full"
                          />
                          {phoneTouch && (!phone || phone.length !== 11) && (
                            <p
                              className="mt-2 text-sm text-red-400"
                              id="captcha-error"
                            >
                              {phonenumbervalidation}
                            </p>
                          )}
                        </div>
                        <Field
                          validateOnBlur
                          validate={(value) =>
                            !emailValidationPattern.test(value) && emailvalidation
                          }
                          required
                          type="email"
                          name="email"
                          placeholder={emailplaceholder}
                          component={TextInputField}
                          widthFull
                        />
                        <div>
                          {roles.map(({ roleName }, i) => {
                            if (
                              roleName === addMemberRoles.operations ||
                              roleName === addMemberRoles.admin ||
                              roleName === addMemberRoles.owner
                            ) {
                              return
                            } else {
                              return (
                                <div
                                  className="w-4 h-4  flex items-center mb-3 "
                                  key={i}
                                >
                                  <Field
                                    type="input"
                                    name={roleName}
                                    component={() => (
                                      <CheckBoxFieldMember
                                        onChange={() => handleSelectedRole(roleName)}
                                        name={roleName}
                                        // checked={roleState[i].isChecked}
                                        checked={true}
                                        label={
                                          roleName === addMemberRoles.worker
                                            ? addMemberRoles.technician
                                                .charAt(0)
                                                .toUpperCase() +
                                              addMemberRoles.technician
                                                .slice(1)
                                                .toLowerCase()
                                            : roleName.charAt(0).toUpperCase() +
                                              roleName.slice(1).toLowerCase()
                                        }
                                        value={roleName}
                                      />
                                    )}
                                  />
                                </div>
                              )
                            }
                          })}
                        </div>
                        <Field
                          type="select"
                          name="categories"
                          label={tradeslabel}
                          options={tradingCategoriesOptions}
                          component={SelectInputField}
                          placeholder={tradesplaceholder}
                          widthFull
                          isMulti
                          validate={(value) => !value && tradesvalidation}
                          required
                        />
                        {/* {roleState.length >= 1 &&
                        roleState[1].isChecked === false ? (
                          <div className="relative"></div>
                        ) : (
                          <Field
                            type="select"
                            name="categories"
                            label={tradeslabel}
                            options={tradingCategoriesOptions}
                            component={SelectInputField}
                            placeholder={tradesplaceholder}
                            widthFull
                            isMulti
                            validate={(value) => !value && tradesvalidation}
                            required
                          />
                        )} */}
                      </div>
                      {/* {error && <p className="text-red-600"> Please select role </p>}
                      {roleState[1].isChecked && tradeError && (
                        <p className="text-red-600  text-right">
                          Please select trades from categories
                        </p>
                      )} */}
                      <Button
                        type="submit"
                        title={actionbuttontitle}
                        loading={loading}
                      />
                    </form>
                  )}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

NewMemberModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
}

export default NewMemberModal
