import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { getUser } from "./utils/auth"

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        return getUser() ? children : <Redirect to="/signin" />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateRoute
