import React, { createContext, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

const InvoiceContext = createContext()

const InvoiceContextProvider = ({ children }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [invoiceDetails, setInvoiceDetails] = useState()
  const [invoiceAddress, setInvoiceAddress] = useState()
  const [invoiceParts, setInvoiceParts] = useState([])
  const [partToDelete, setPartToDelete] = useState()
  const [totalInvoiceLabor, setTotalInvoiceLabor] = useState()
  const [taxRate, setTaxRate] = useState()
  const [incentive, setIncentive] = useState()
  const [incentiveLabel, setIncentiveLabel] = useState()
  const [fee, setFee] = useState()
  const [feeLabel, setFeeLabel] = useState()

  const handleAdd = () => {
    const invoiceItemObj = {
      lineNumber: 1,
      itemQty: 0,
      itemPrice: 0.0,
      partName: `Part Name`,
    }
    setInvoiceParts((prev) => [...prev, invoiceItemObj])
  }

  const handleDelete = (index) => {
    setIsDeleteModal(true)
    setPartToDelete(index)
  }

  const confirmDelete = () => {
    const destructuredInvoicePartsArr = [...invoiceParts]
    destructuredInvoicePartsArr.splice(partToDelete, 1)
    setInvoiceParts(destructuredInvoicePartsArr)
  }

  const handleInvoiceEdit = useCallback(
    (e, setNewValue, type, index, isPricePerUnit) => {
      const destructuredInvoicePartsArr = [...invoiceParts]
      if (isPricePerUnit) {
        setNewValue(e)
        console.log("VALUE:", e)
        if (e === undefined) {
          setNewValue(0)
          destructuredInvoicePartsArr[index][type] = 0
          return
        }
        destructuredInvoicePartsArr[index][type] = Number(e)
        setInvoiceParts(destructuredInvoicePartsArr)
      } else {
        setNewValue(e.target.value)
        if (e.target.value === "") return
        destructuredInvoicePartsArr[index][type] = e.target.value
        setInvoiceParts(destructuredInvoicePartsArr)
      }
    },
    [invoiceParts]
  )

  // Every time invoice parts change, calculate the total for parts
  useEffect(() => {
    const updateTotal = () => {
      if (!invoiceParts) return
      if (!taxRate) return
      const sumArr = []
      const invoicePartsArr = [...invoiceParts]
      invoicePartsArr.forEach((part) => {
        const total = part.itemQty * part.itemPrice
        sumArr.push(total)
      })
      setInvoiceDetails((prev) => {
        const subTotal =
          sumArr.reduce((prev, curr) => prev + curr, 0) + totalInvoiceLabor
        const totalTax = subTotal * (taxRate / 100)
        const total = subTotal + totalTax + incentive - fee
        return {
          ...prev,
          totalLabor: subTotal,
          totalTax: Math.round(totalTax * 100) / 100,
          total: total,
        }
      })
    }
    updateTotal()
  }, [invoiceParts, taxRate, totalInvoiceLabor, fee, incentive])

  const handleEdit = () => {
    setIsEdit(!isEdit)
  }
  const handleSave = () => {
    setIsEdit(!isEdit)
  }
  const handleSubmit = () => {
    setIsSubmit(true)
    setIsModalOpen(true)
  }

  return (
    <InvoiceContext.Provider
      value={{
        handleAdd,
        isEdit,
        handleEdit,
        handleSave,
        isModalOpen,
        setIsModalOpen,
        handleSubmit,
        isSubmit,
        invoiceDetails,
        setInvoiceDetails,
        invoiceAddress,
        setInvoiceAddress,
        invoiceParts,
        setInvoiceParts,
        handleInvoiceEdit,
        handleDelete,
        isDeleteModal,
        setIsDeleteModal,
        confirmDelete,
        totalInvoiceLabor,
        setTotalInvoiceLabor,
        taxRate,
        setTaxRate,
        incentive,
        setIncentive,
        incentiveLabel,
        setIncentiveLabel,
        fee,
        setFee,
        feeLabel,
        setFeeLabel,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
}

InvoiceContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export { InvoiceContextProvider, InvoiceContext }
