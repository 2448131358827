import { FBUrl, TwitterUrl, LinkedInUrl } from "../constants/constants"
import FB from "../assets/FB.png"
import Twitter from "../assets/Twitter.png"
import LinkedIn from "../assets/LinkedIn.png"

import Static from "../static.json"

const Toolbar = () => {
  const {
    toolbar: { title },
  } = Static
  return (
    <div className="h-16 bg-white flex justify-between px-4">
      <p className="text-text-primary self-center">{title}</p>
      <div className="flex items-center">
        <a target="_blank" href={FBUrl} rel="noreferrer">
          <img className="w-5 h-5 mr-2 object-contain" src={FB} alt="FB" />
        </a>
        <a target="_blank" href={TwitterUrl} rel="noreferrer">
          <img className="w-5 h-5 mr-2 object-contain" src={Twitter} alt="Twitter" />
        </a>
        <a target="_blank" href={LinkedInUrl} rel="noreferrer">
          <img className="w-5 h-5 object-contain" src={LinkedIn} alt="LinkedIn" />
        </a>
      </div>
    </div>
  )
}

export default Toolbar
