import PropTypes from "prop-types"
import { classNames } from "../../utils/ClassNames"
import SpinLoader from "../SpinLoader"

const Button = ({ title, type, loading }) => (
  <div>
    <button
      type={type}
      disabled={loading ? true : false}
      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-primary hover:outline-none focus:outline-none focus:outline-none focus:outline-none focus:outline-none"
    >
      {loading && <SpinLoader />}
      {title}
    </button>
  </div>
)

Button.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  type: "button",
  loading: false,
}

const RoundedButton = ({
  title,
  type,
  variant,
  loading,
  icon,
  className,
  ...props
}) => {
  return (
    <div>
      <button
        type={type}
        className={classNames(
          "flex justify-center py-2 px-4 border border-transparent rounded-3xl shadow-md text-sm font-medium hover:outline-none focus:outline-none focus:outline-none focus:outline-none focus:outline-none",
          variant === "primary"
            ? "text-white bg-primary"
            : variant === "secondary"
            ? "text-black bg-white"
            : variant === "continue"
            ? "text-white bg-gray-900 px-12 py-3.5"
            : "",
          className
        )}
        disabled={loading}
        {...props}
      >
        {loading && <SpinLoader />}
        {icon}
        {title}
      </button>
    </div>
  )
}

RoundedButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

RoundedButton.defaultProps = {
  type: "button",
  variant: "",
  loading: false,
  className: "",
  disabled: false,
}

const MiniRoundedButton = ({ title, type, loading, ...props }) => {
  return (
    <div>
      <button
        type={type}
        className={
          "inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
        }
        {...props}
      >
        {loading && <SpinLoader />}
        {title}
      </button>
    </div>
  )
}

MiniRoundedButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

MiniRoundedButton.defaultProps = {
  type: "button",
  disabled: false,
  variant: "",
  loading: false,
}

export { Button, RoundedButton, MiniRoundedButton }
