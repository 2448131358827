import { useCallback, useEffect } from "react"
import { usePlaidLink } from "react-plaid-link"

const OpenPlaidLink = ({
  token,
  showPlaid,
  onContinue,
  setPlaidSuccess,
  setPlaid,
}) => {
  const onSuccess = useCallback((public_token, metadata) => {
    const createAccount = {
      primary: "1",
      platform: "PLAID",
      currency: "USD",
      publicToken: public_token,
      accountToken: metadata.account_id,
    }
    setPlaidSuccess(false)
    onContinue(createAccount)
    console.log(public_token)
    // send public_token to server
  }, [])

  const config = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    clientName: "Tirios",
    env: process.env.REACT_APP_ENV === "production" ? "production" : "sandbox",
    product: ["auth", "transactions"],
    token,
    onSuccess,
    // receivedRedirectUri: document.location.href, // required for OAuth
    // onExit
    // onEvent
  }

  const { open, ready } = usePlaidLink(config)

  // this opens link as soon as it's ready
  useEffect(() => {
    console.log("plaidLinkCall")
    if (!ready) {
      return
    }
    if (showPlaid) open()
    // If user accidently exits plaid, return function allows plaid link to be clicked again
    return () => setPlaid(false)
  }, [ready, open, showPlaid])

  // don't render anything, just open Link
  return null
}

export default OpenPlaidLink
