import moment from "moment"
import PropTypes from "prop-types"

import { CalculateDifferenceBetweenTwoDates } from "../../utils/helper"
// import JobCardImage from "../../assets/jobCard.png"
import GearIcon from "../../assets/gear-icon.png"

import Static from "../../static.json"
import { tradeCategories, nextJobCardConstants } from "../../constants/constants"

export const OnboardingCard = ({ onClick, disabled, description, label }) => {
  return (
    <div className="bg-white shadow sm:rounded-lg max-w-md">
      <div className="px-8 py-8">
        <div className="mt-2 max-w-xl text-base text-gray-500">
          <p>{description}</p>
        </div>
        {disabled ? (
          <div className="mt-3 text-sm">
            <button
              type="button"
              onClick={onClick}
              disabled={disabled}
              className="w-full text-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md  bg-gray-200 text-gray-700 cursor-not-allowed  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
            >
              {label}
            </button>
          </div>
        ) : (
          <div className="mt-3 text-sm">
            <button
              type="button"
              onClick={onClick}
              disabled={disabled}
              className="w-full text-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
            >
              {label}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

OnboardingCard.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  label: PropTypes.string,
}

const {
  dashboard: {
    onboarding: { carddescription, cardactionlabel },
  },
} = Static

OnboardingCard.defaultProps = {
  onClick: () => {},
  disabled: false,
  description: carddescription,
  label: cardactionlabel,
}

export const JobCard = ({
  onAccept,
  onDecline,
  job: jobObject,
  acceptTitle,
  declineTitle,
  isNext,
}) => {
  const { title, bonusIncentive, tiriosFeeConstant, descriptionTitle } =
    nextJobCardConstants

  if (isNext) {
    // console.log("JOB OBJECT FOR NEXT JOB:", jobObject)

    const { property, serviceCategory, tiriosFee, tiriosIncentive } = jobObject
    let { scheduledTime } = jobObject
    scheduledTime = `${scheduledTime}z`
    const { address_line1, city, state, zipCode } = property

    return (
      <div className="text-gray-dark bg-gray-bg-light rounded-lg border border-gray-dark-2 text-center p-8 w-full max-w-sm md:w-80">
        <p className="font-medium text-lg border-b border-gray-dark"> {title} </p>
        <p className="font-medium m-3">
          {tradeCategories[serviceCategory.description]}
        </p>
        <div className="text-gray-dark bg-white rounded-lg border border-gray-dark-2 text-center p-2 mb-3">
          <p className="font-medium">
            {moment(scheduledTime).format("MMM DD, YYYY")}
          </p>
          <p className="font-medium">{moment(scheduledTime).format("h:mm A")}</p>
        </div>
        <div className="mb-3">
          <p className="font-medium">{`${address_line1},`}</p>
          <p className="font-medium">{`${city}, ${state} ${zipCode}`}</p>
        </div>
        {tiriosIncentive && (
          <p className="font-medium text-red-400">
            {bonusIncentive} {tiriosIncentive}
          </p>
        )}
        {tiriosFee ? (
          <p className="font-medium">
            {tiriosFeeConstant} {tiriosFee}
          </p>
        ) : (
          <p className="font-medium">{tiriosFeeConstant} $0</p>
        )}
      </div>
    )
  }

  const { id, job } = jobObject

  // console.log("INCOMING JOB", job)

  let { endTime } = jobObject
  endTime = `${endTime}z`
  let scheduledTime = jobObject?.job?.scheduledTime
  scheduledTime = `${scheduledTime}z`
  const { property, serviceCategory, description, tiriosFee, tiriosIncentive } = job
  const { address_line1, city, state, zipCode } = property

  const reaminingTime = CalculateDifferenceBetweenTwoDates(
    moment(new Date()),
    moment(endTime)
  )
  console.log("Remaining Time: ", reaminingTime)

  const {
    dashboard: {
      jobcard: { endsinlabel },
    },
  } = Static

  return (
    <>
      {reaminingTime !== "0 minutes" && (
        <div className="bg-red-250 border shadow rounded-lg  p-8 flex flex-col items-center sm:w-full max-w-sm  md:w-80 text-white">
          <div className="bg-white w-full text-center rounded-lg p-3 text-red-350 font-medium">{`${endsinlabel}${reaminingTime}`}</div>
          <img src={GearIcon} alt="#" />
          <p className="font-medium">
            {tradeCategories[serviceCategory.description]}
          </p>
          {tiriosIncentive && (
            <p className="text-yellow-light font-medium">
              {bonusIncentive} {tiriosIncentive}
            </p>
          )}
          {tiriosFee ? (
            <p className="font-medium">
              {" "}
              {tiriosFeeConstant} {tiriosFee}
            </p>
          ) : (
            <p className="font-medium"> {tiriosFeeConstant} $0 </p>
          )}
          <div className="text-gray-dark bg-white rounded-lg border border-gray-dark-2 text-center p-2 mb-3 mt-3 w-full">
            <p className="font-medium">
              {moment(scheduledTime).format("MMM DD, YYYY")}
            </p>
            <p className="font-medium">{moment(scheduledTime).format("h:mm A")}</p>
          </div>
          <div className="mb-3">
            <p className="font-medium m-0">{`${address_line1},`}</p>
            <p className="font-medium m-0">{`${city}, ${state} ${zipCode}`}</p>
          </div>
          <div className="flex justify-between items-center mb-3">
            <button
              type="button"
              onClick={() => onAccept(id)}
              className="w-24 text-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-full text-black bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 sm:text-sm"
            >
              {acceptTitle}
            </button>
            <div className="w-2"></div>
            <button
              type="button"
              onClick={() => onDecline(id)}
              className="w-24 text-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-full text-black bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 sm:text-sm"
            >
              {declineTitle}
            </button>
          </div>
          <div>
            <p className="font-medium border-b mb-3 text-center">
              {descriptionTitle}
            </p>
            <p> {description} </p>
          </div>
        </div>
      )}
    </>
  )
}

JobCard.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  job: PropTypes.object.isRequired,
  acceptTitle: PropTypes.string,
  declineTitle: PropTypes.string,
  isNext: PropTypes.bool,
}

const {
  dashboard: {
    jobcard: { acceptbuttontitle, declinebuttontitle },
  },
} = Static

JobCard.defaultProps = {
  acceptTitle: acceptbuttontitle,
  declineTitle: declinebuttontitle,
  expiration: null,
  date: null,
  isNext: false,
  onAccept: () => {},
  onDecline: () => {},
}
