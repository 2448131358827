import React, { useState, useEffect } from "react"
import { Field, Form } from "react-final-form"
import {
  TextInputField,
  SelectInputField,
  SelectMenu,
  TextInput,
} from "../../../../components/inputs"
import { RoundedButton } from "../../../../components/buttons"
import PropTypes from "prop-types"
import Static from "../../../../static.json"
import { accountTypeOptions } from "../../../../constants/constants"
import successImage from "../../../../assets/Allura City Life.png"

const ManualBankAccount = ({ onSubmit, paymentStatus, showForm }) => {
  const {
    dashboard: {
      payments: {
        actionbuttonlabel,
        manual: { success, unSuccessful1, unSuccessful2, unSuccessful3 },
        input: {
          accountHolderLabel,
          accountHolerPlaceholder,
          bankRoutingNumberLabel,
          bankRoutingNumberPlaceholder,
          bankAccountNumberLabel,
          bankAccountNumberPlaceholder,
          confirmBankAccountNumberLabel,
          confirBankAccountNumberPlaceholder,
          accountTypeLabel,
          accountTypePlaceholder,
          accountNickname,
        },
        validation: {
          accountHolderValidation,
          bankRoutingValidation,
          bankAccountValidation,
          confirmBankAccountValidation,
          accountTypeValidation,
          nicknameValidation,
        },
      },
    },
  } = Static

  const [paymentInfo, setPaymentInfo] = useState({})
  const [error, setError] = useState({})
  const [loader, setLoader] = useState(false)
  // const [showForm, setShowForm] = useState(true)
  const [isSuccess, setIsSuccess] = useState(null)

  useEffect(() => {
    if (paymentStatus) {
      setLoader(false)
    }
  }, [loader])

  const addPaymentType = (values) => {
    const paymentErrors = {}
    const { primary, routing, accountNickname, method } = values
    if (!paymentInfo.number || paymentInfo.number !== paymentInfo.confirmedNumber) {
      paymentErrors.confirmedNumber = confirmBankAccountValidation
    }
    if (Object.keys(paymentErrors).length === 0) {
      let paymentInfoToSend = {
        name: primary,
        description: accountNickname,
        primary: "1",
        currency: "USD",
        account: {
          method: method.value,
          number: paymentInfo.number,
          routing: routing,
        },
      }
      // console.log(paymentInfoToSend)
      onSubmit(paymentInfoToSend)
      setLoader(true)
    } else {
      setError(paymentErrors)
    }
  }

  if (showForm) {
    return (
      <div className="md py-6 px-8 bg-white">
        <Form
          onSubmit={addPaymentType}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-6 smd:w-full md:w-1/2">
                <Field
                  type="text"
                  label={accountHolderLabel}
                  name="primary"
                  placeholder={accountHolerPlaceholder}
                  component={TextInputField}
                  widthFull
                  required
                  validate={(value) => {
                    const LETTERS = /^[A-Za-z ]+$/
                    if (value === undefined) return accountHolderValidation
                    return value && !value.match(LETTERS)
                      ? accountHolderValidation
                      : undefined
                  }}
                />
                <Field
                  type="number"
                  name="routing"
                  placeholder={bankRoutingNumberPlaceholder}
                  label={bankRoutingNumberLabel}
                  component={TextInputField}
                  className="w-full"
                  widthFull
                  validate={(value) =>
                    value && value.length == 9 ? undefined : bankRoutingValidation
                  }
                  required
                />
                <TextInput
                  type="number"
                  label={bankAccountNumberLabel}
                  name="number"
                  placeholder={bankAccountNumberPlaceholder}
                  component={TextInputField}
                  error={error.number}
                  value={paymentInfo.number}
                  onChange={(e) => {
                    setPaymentInfo({
                      ...paymentInfo,
                      number: e.target.value,
                    })
                    setError({
                      ...error,
                      number: "",
                    })
                  }}
                  widthFull
                  required
                />
                <TextInput
                  type="number"
                  label={confirmBankAccountNumberLabel}
                  name="confirmedNumber"
                  placeholder={confirBankAccountNumberPlaceholder}
                  component={TextInputField}
                  error={error.confirmedNumber}
                  value={paymentInfo.confirmedNumber}
                  onChange={(e) => {
                    setPaymentInfo({
                      ...paymentInfo,
                      confirmedNumber: e.target.value,
                    })
                    setError({
                      ...error,
                      confirmedNumber: "",
                    })
                  }}
                  widthFull
                  required
                />
                <Field
                  type="select"
                  name="method"
                  label={accountTypeLabel}
                  options={accountTypeOptions}
                  component={SelectInputField}
                  placeholder={accountTypePlaceholder}
                  widthFull
                  required
                />
                <Field
                  type="text"
                  label={accountNickname}
                  name="accountNickname"
                  placeholder={accountNickname}
                  component={TextInputField}
                  widthFull
                  required
                  validate={(value) =>
                    value && value.length > 1 ? undefined : nicknameValidation
                  }
                  // validate={(value) => {
                  //   const LETTERS = /^[A-Za-z ]+$/
                  //   if (value === undefined) return nicknameValidation
                  //   return value && !value.match(LETTERS)
                  //     ? nicknameValidation
                  //     : undefined
                  // }}
                />
              </div>
              <div className="my-4 h-2 bg-gray-200 rounded-lg mt-12">
                <div className="my-4 h-2 w-4/5 bg-primary rounded-lg" />
              </div>
              <RoundedButton
                variant="primary"
                type="submit"
                title={actionbuttonlabel}
                loading={loader}
              />
            </form>
          )}
        />
      </div>
    )
  } else
    return (
      <>
        {paymentStatus ? (
          <div className="bg-white">
            <div className="flex flex-col justify-center items-center">
              <h1
                className="pt-10 mb-10 text-center"
                style={{ color: "#949494", fontSize: "20px" }}
              >
                {success}
              </h1>
              <img
                className=""
                style={{ height: "350px", width: "350px" }}
                src={successImage}
                alt="img"
              />
              <div className="my-10" onClick={() => window.location.reload()}>
                <RoundedButton variant="primary" title="Go to Payments" />
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white">
            <div className="flex flex-col justify-center items-center">
              <h1
                className="text-center pt-10"
                style={{ color: "#949494", fontSize: "20px" }}
              >
                {unSuccessful1}
              </h1>
              <h1
                className="text-center mt-10"
                style={{ color: "#949494", fontSize: "20px" }}
              >
                {unSuccessful2}
              </h1>
              <h1
                className="text-center mt-10"
                style={{ color: "#949494", fontSize: "20px" }}
              >
                {unSuccessful3}
              </h1>
              <div className="my-10" onClick={() => window.location.reload()}>
                <RoundedButton variant="primary" title="Go to Payments" />
              </div>
            </div>
          </div>
        )}
      </>
    )
}

ManualBankAccount.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  paymentStatus: PropTypes.bool.isRequired,
  showForm: PropTypes.bool.isRequired,
}

export default ManualBankAccount
