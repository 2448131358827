import { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

import { RoundedButton } from "../buttons"

import Static from "../../static.json"

const MemberStatusPopupModal = ({ onSubmit, open, setOpen, active, loading }) => {
  const {
    dashboard: {
      members: {
        statusmodal: {
          title,
          description,
          actionlabel1,
          actionlabel2,
          activelabel,
          inactivelabel,
        },
      },
    },
  } = Static

  const submitHandler = () => {
    onSubmit()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pb-2 p-4 text-left overflow-hidden shadow-xl transform transition-all sm:m-0 sm:align-middle sm:max-w-xs sm:w-full sm:p-4">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-full p-1 text-gray-400 bg-gray-200 hover:text-gray-500 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="text-left pb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {`${title} ${active ? inactivelabel : activelabel}`}
                  </Dialog.Title>
                  <p className="text-gray-600 text-xs mt-4">
                    {description}{" "}
                    <span className="capitalize">
                      {active ? inactivelabel : activelabel}
                    </span>
                  </p>
                </div>
              </div>
              <div className="my-2">
                <div></div>
              </div>
              <div className="justify-between flex my-2">
                <RoundedButton
                  className="w-36"
                  variant="secondary"
                  title={actionlabel1}
                  onClick={() => setOpen(false)}
                />
                <RoundedButton
                  loading={loading}
                  className="w-36 bg-red-500 text-white"
                  title={`${actionlabel2} ${active ? inactivelabel : activelabel}`}
                  onClick={submitHandler}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

MemberStatusPopupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  active: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
}

export default MemberStatusPopupModal
