import PropTypes from "prop-types"
import Select from "react-select"
import { classNames } from "../../utils/ClassNames"

export const SelectInputField = ({
  meta,
  label,
  autoComplete,
  input,
  placeholder,
  isMulti,
  widthFull,
  options,
  subLabel,
}) => {
  const { submitError, touched, valid } = meta
  const errorStatus = submitError && touched ? submitError : undefined
  const validStatus = touched && valid
  const { value } = input

  return (
    <SelectMenu
      autoComplete={autoComplete}
      label={label}
      {...input}
      placeholder={placeholder}
      value={value}
      error={errorStatus}
      validate={validStatus}
      isMulti={isMulti}
      widthFull={widthFull}
      options={options}
      subLabel={subLabel}
    />
  )
}

SelectInputField.propTypes = {
  widthFull: PropTypes.bool,
  meta: PropTypes.object,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  subLabel: PropTypes.string,
  isMulti: PropTypes.bool,
}

SelectInputField.defaultProps = {
  widthFull: false,
  meta: {},
  autoComplete: null,
  label: null,
  placeholder: null,
  subLabel: null,
  isMulti: false,
}

const colourStyles = {
  control: (styles) => {
    return { ...styles, borderRadius: 8 }
  },
  input: (styles) => {
    return { ...styles, paddingTop: 6, paddingBottom: 6 }
  },
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

export const SelectMenu = ({
  label,
  value,
  options,
  subLabel,
  onChange,
  widthFull,
  isMulti,
  error,
  placeholder,
  onBlur,
  autoFocus,
}) => {
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-text-primary"
      >
        {label}
        {subLabel && <span className="text-sm font-regular"> {subLabel}</span>}
      </label>
      <Select
        value={value}
        isMulti={isMulti}
        name="colors"
        options={options}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        className={classNames(
          "mt-1 block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
          widthFull ? "w-full" : ""
        )}
        classNamePrefix="select"
        onChange={onChange}
        styles={colourStyles}
        onBlur={onBlur}
        autoFocus={autoFocus}
      />
      {error && (
        <label htmlFor="location" className="block text-sm text-red-400">
          {error}
        </label>
      )}
    </div>
  )
}

SelectMenu.propTypes = {
  widthFull: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  subLabel: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
}

SelectMenu.defaultProps = {
  widthFull: false,
  isMulti: false,
  label: "",
  value: null,
  options: [],
  subLabel: null,
  error: null,
  placeholder: "",
  onBlur: () => {},
  autoFocus: false,
}

export default SelectMenu
