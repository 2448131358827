import { GET_ROLES } from "../actions/types"

const initialState = []

function roleReducer(roles = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_ROLES:
      return payload

    default:
      return roles
  }
}

export default roleReducer
