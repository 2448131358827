import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/outline"
import { updatePaymentInfo } from "../../../../actions/payment"
import moment from "moment"

import AboutBusiness from "./AboutBusiness"
import AboutOwner from "./AboutOwner"
import PlaidLink from "./Plaid"
import ManualBankAccount from "./ManualBankAccount"
import {
  addBankSteps,
  newOwnerCreation,
  tiriosURL,
} from "../../../../constants/constants"
import Static from "../../../../static.json"

const initialState = {
  aboutBusiness: {},
  aboutOwner: {},
  accounts: [],
}
const AddBankAccount = ({ setModalOpen, updatePaymentInfo }) => {
  const [steps, setSteps] = useState(0)
  const [entity, setEntity] = useState(initialState)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [showForm, setShowForm] = useState(true)
  const {
    dashboard: {
      payments: { backbuttonlabel },
    },
  } = Static
  const history = useHistory()

  useEffect(() => {
    if (
      entity["aboutOwner"]["members"] &&
      entity["aboutOwner"]["members"].length > 0 &&
      entity["accounts"] &&
      entity["accounts"].length > 0
    ) {
      const type = entity["aboutBusiness"]["categories"]["value"]
      const businessState = entity["aboutBusiness"]["state"]["value"]
        ? entity["aboutBusiness"]["state"]["value"]
        : entity["aboutBusiness"]["state"]
      entity["aboutBusiness"]["ein"] =
        entity["aboutBusiness"]["tax"]["mid"] &&
        entity["aboutBusiness"]["tax"]["suf"]
          ? `${entity["aboutBusiness"]["tax"]["mid"]}${entity["aboutBusiness"]["tax"]["suf"]}`
          : ""
      const createMembers = []
      entity["aboutOwner"]["members"].forEach((item) => {
        item[
          "dob"
        ] = `${item["dob"]["year"]}${item["dob"]["month"]}${item["dob"]["day"]}`
        // item["dob"] = "19790101"
        item[
          "ssn"
        ] = `${item["ssn"]["pre"]}${item["ssn"]["mid"]}${item["ssn"]["suf"]}`
        const primary = item["primary"] === true ? "1" : "0"
        const ownerState = item["state"]["value"]
        const modifyOwners = {
          title: item["businesstitle"],
          first: item["first"],
          last: item["last"],
          ssn: item["ssn"],
          dob: item["dob"],
          ownership: item["ownership"],
          email: item["email"],
          phone: item["phone"],
          primary: primary,
          address1: item["address1"],
          address2: item["address2"] ? item["address2"] : null,
          city: item["city"],
          state: ownerState,
          zip: item["zip"],
          country: "USA",
          significantResponsibility: 1,
          politicallyExposed: 0,
        }
        createMembers.push(modifyOwners)
      })
      const reqData = {
        providerId: "",
        entity: {
          name: entity["aboutBusiness"]["name"],
          email: entity["aboutBusiness"]["email"],
          ein: entity["aboutBusiness"]["ein"]
            ? entity["aboutBusiness"]["ein"].toString()
            : "",
          type: type,
          address1: entity["aboutBusiness"]["address1"],
          address2: entity["aboutBusiness"]["address2"]
            ? entity["aboutBusiness"]["address2"]
            : null,
          city: entity["aboutBusiness"]["city"],
          state: businessState,
          zip: entity["aboutBusiness"]["zip"],
          country: "USA",
          phone: entity["aboutBusiness"]["phone"],
          currency: "USD",
          tcAcceptDate:
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[0]
              .replaceAll("-", "") +
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .split("T")[1]
              .replaceAll(":", "")
              .slice(0, 4),
          website: entity["aboutBusiness"]["website"]
            ? entity["aboutBusiness"]["website"]
            : tiriosURL,
          merchant: {
            dba: "",
            new: 1,
            mcc: "1799",
            status: 1,
            members: [...createMembers],
          },
          accounts: entity["accounts"],
          public: 0,
        },
      }
      console.log("ReqData To Send: ", reqData)
      updatePaymentInfo(reqData)
        .then((res) => {
          console.log("Successfully Hit EndPoint", res)
          setPaymentStatus(true)
          setShowForm(false)
        })
        .catch((err) => {
          console.error("Unsuccessful with error: ", err)
          setPaymentStatus(false)
          setShowForm(false)
        })
    }
  }, [entity])

  const handleUpdatePayment = (values) => {
    console.log("[---submit values--]", values)
    const innerKey =
      steps === 0
        ? "aboutBusiness"
        : steps === 1
        ? "aboutOwner"
        : steps === 2
        ? "accounts"
        : ""
    if (innerKey) {
      setEntity({
        ...entity,
        [innerKey]:
          (steps === 0) | (steps === 1)
            ? { ...values }
            : steps === 2
            ? [{ ...values }]
            : {},
      })
    }
    setSteps(steps + 1)
  }

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <button
        // onClick={() => setModalOpen(false)}
        onClick={() => history.goBack()}
        className="flex mb-6 mt-3 text-gray-500"
      >
        <ChevronLeftIcon className="text-gray-500 h-6 w-8" />
        {backbuttonlabel}
      </button>
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8 bg-gray-600 rounded-t-lg">
          {addBankSteps.map(({ id, name }, index) => (
            <li key={name} className="md:flex-1">
              <a
                className={`justify-center group py-2 px-2 flex flex-col md:py-4 md:px-4 ${
                  index <= steps ? "text-white" : "text-gray-400"
                }`}
              >
                <span className="text-base text-center flex items-center justify-around">
                  {id} : {name}
                  {index !== addBankSteps.length - 1 && (
                    <ChevronRightIcon
                      className={`${
                        index <= steps ? "text-white" : "text-gray-400"
                      } h-6 w-6`}
                    />
                  )}
                </span>
              </a>
            </li>
          ))}
        </ol>
        {steps === 0 ? (
          <AboutBusiness onSubmit={handleUpdatePayment} />
        ) : steps === 1 ? (
          <AboutOwner
            onSubmit={handleUpdatePayment}
            entity={{
              members: [newOwnerCreation],
            }}
          />
        ) : (
          // <PlaidLink onContinue={handleUpdatePayment} />
          <ManualBankAccount
            onSubmit={handleUpdatePayment}
            paymentStatus={paymentStatus}
            showForm={showForm}
          />
        )}
      </nav>
    </div>
  )
}

AddBankAccount.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  updatePaymentInfo: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    payment: state.entity,
  }
}

const mapDispatchToProps = {
  updatePaymentInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount)
