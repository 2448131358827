const TermsOfService = () => {
  return (
    <div className="max-w-screen-lg m-auto my-24 text-gray-500 text-xl">
      <h1 className="text-center text-2xl font-bold uppercase">Terms of Service</h1>
      <h2 className="mt-4 font-bold">
        1. <span className="underline">Introduction</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4">
          Welcome to <strong>Tirios!</strong> As you have just clicked to our Terms
          of Service, please take a pause, grab a cup of coffee and carefully read
          the following pages. It will take you approximately 20minutes.
        </p>
        <p className="mt-4">
          These Terms of Service (&quot;Terms&quot;, &quot;Terms of Service&quot;)
          govern your use of our web pages located at &nbsp;
          <a
            className="underline"
            target="_blank"
            href="https://www.tirios.ai"
            rel="noreferrer"
          >
            https://www.tirios.ai
          </a>
          , our mobile application Tiriosand our mobile application My Tirios
          (together or individually &quot;Service&quot; or &quot;Platform&quot;)
          operated by Tirios Corporation(&quot;Tirios&quot;, &quot;we&quot;,
          &quot;us&quot;, or &quot;our&quot;).
        </p>
        <p className="mt-4">
          Our Privacy Policy also governs your use of our Service and explains how we
          collect, safeguard and disclose information that results from your use of
          our web pages. Please read it here{" "}
          <a className="underline" href="http://www.tirios.ai/privacy">
            www.tirios.ai/privacy
          </a>
        </p>
        <p className="mt-4">
          Your agreement with us includes these Terms and our Privacy Policy (&quot;
          <strong>Agreements</strong>&quot;). You acknowledge that you have read and
          understood Agreements, and agree to be bound of the
        </p>
        <p className="mt-4">
          If you do not agree with (or cannot comply with) Agreements, then you may
          not use the Service, but please let us know by emailing at
          members@tirios.ai so we can try to find a solution. These Terms apply to
          all visitors, users and others who wish to access or useService.
        </p>
        <p className="mt-4">Thank you for being responsible.</p>
      </div>
      <h2 className="mt-4 font-bold">
        2. <span className="underline">Communications</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li>
            By creating an Account on our Service, you agree to subscribe to
            newsletters, marketing or promotional materials and other information we
            may send. However, you may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or by emailing
            at{" "}
            <a className="underline" href="mailto:members@tirios">
              members@tirios
            </a>
            .
          </li>
        </ol>
        <p className="mt-4"></p>
      </div>
      <h2 className="mt-4 font-bold">
        3. <span className="underline">Membership</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            If you wish to continue our service made available through Service
            (&quot;<strong>Service</strong>&quot;), you may be asked to supply
            certain information relevant to your Service including, without
            limitation, yourbank account number, bank routing number, credit card
            number, date of birth, the expiration date of your credit card, your
            billing address.
          </li>
          <li className="mt-4">
            You represent and warrant that: (i) you have the legal right to use any
            credit card(s) or other payment method(s) in connection with any Service;
            and that (ii) the information you supply to us is true, correct and
            complete
          </li>
          <li className="mt-4">
            We may employ the use of third party services for the purpose of
            facilitating payment and the completion of Purchases. By submitting your
            information, you grant us the right to provide the information to these
            third parties subject to our Privacy Policy.
          </li>
          <li className="mt-4">
            We reserve the right to refuse or cancel your order at any time for
            reasons including but not limited to: product or service availability,
            errors in the description or price of the product or service, error in
            your order or other reasons.
          </li>
          <li className="mt-4">
            We reserve the right to refuse or cancel your order if fraud or an
            unauthorized or illegal transaction is suspected.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        4. <span className="underline"> Contests, Sweepstakes and Promotions</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Any contests, sweepstakes or other promotions (collectively, &quot;
            <strong>Promotions</strong>&quot;) made available through Service may be
            governed by rules that are separate from these Terms of Service. If you
            participate in any Promotions, please review the applicable rules as well
            as our Privacy Policy. If the rules for a Promotion conflict with these
            Terms of Service, Promotion rules will apply
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        5. <span className="underline">Subscriptions</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Some parts of Service are billed on a subscription basis (&quot;
            <strong>Subscription(s)</strong>&quot;). You will be billed in advance on
            a recurring and periodic basis (&quot;<strong>Billing Cycle</strong>
            &quot;). Billing cycles are set on a monthly basis.
          </li>
          <li className="mt-4">
            At the end of each Billing Cycle, your Subscription will automatically
            renew under the exact same conditions unless you cancel it or we cancel
            it. You may cancel your Subscription renewal either through your
            activating or deactivating your membership or by contacting customer
            support team at{" "}
            <a className="underline" href="mailto:members@tirios.ai">
              members@tirios.ai
            </a>
            .
          </li>
          <li className="mt-4">
            A valid payment method, including bank account or credit card, is
            required to process the payment for your cription. You shall provide
            Tirios Corporation with accurate and complete billing information
            including full name, address, state, zip code, telephone number, and a
            valid payment method information. By submitting such payment information,
            you automatically authorize Tirios Corporation to charge all Subscription
            fees incurred through your account to any such payment instruments.
          </li>
          <li className="mt-4">
            Should automatic billing fail to occur for any reason, Tirios Corporation
            will issue an electronic invoice indicating that you must proceed
            manually, within a certain deadline date, with the fullpayment
            corresponding to the billing period as indicated on the invoice.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        6. <span className="underline">Payments</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Facilitation of Charges. All Charges are facilitated through a
            third-party payment processor. Tirios may replace its third-party payment
            processor without notice to you. Charges shall only be made through the
            Platform. Your payment of Charges to Tirios satisfies your payment
            obligation for your use of the Platform and Services. Certain Charges may
            be collectively billed as a single purchase transaction to your selected
            payment method based on the payment frequency indicated in your settings.
            If you don&apos;t recognize a transaction, then check your ride receipts
            and payment history.
          </li>
          <li className="mt-4">
            If you are a Service Provider, you will receive payment for your
            provision of Services pursuant to the terms of the{" "}
            <a href="https://www.tirios.ai/terms/driver-addendum">
              Service Provider Addendum
            </a>
            , which shall form part of this Agreement between you and Tirios.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        7. <span className="underline">Free Trial</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Tirios Corporation may, at its sole discretion, offer a Subscription with
            a free trial for a limited period of time (&quot;
            <strong>Free Trial</strong>&quot;).
          </li>
          <li className="mt-4">
            You may be required to enter your billing information in order to sign up
            for Free Trial.
          </li>
          <li className="mt-4">
            If you do enter your billing information when signing up for Free Trial,
            you will not be charged by Tirios Corporation until Free Trial has
            expired. On the last day of Free Trial period, unless you cancelled your
            Subscription, you will be automatically charged the applicable
            Subscription fees for the type of Subscription you have selected.{" "}
          </li>
          <li className="mt-4">
            At any time and without notice, Tirios Corporation reserves the right to
            (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free
            Trial offer.{" "}
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        8. <span className="underline">Fee Changes</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Tirios Corporation, in its sole discretion and at any time, may modify
            Subscription fees for the Subscriptions. Any Subscription fee change will
            become effective at the end of the then-currentBilling Cycle.
          </li>
          <li className="mt-4">
            Tirios Corporation will provide you with a reasonable prior notice of any
            change in Subscription fees to give you an opportunity to terminate your
            Subscription before such change becomes effective.
          </li>
          <li className="mt-4">
            Your continued use of Service after Subscription fee change comes into
            effect constitutes your agreement to pay the modified Subscription fee
            amount.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        9. <span className="underline">Refunds</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Except when required by law, paid Subscription fees are non-refundable.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        10. <span className="underline">Content</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Our Service allows you to post, link, store, share and otherwise make
            available certain information, text, graphics, videos, or other material
            (“Content”). You are responsible for Content that you post on or through
            Service, including its legality, reliability, and appropriateness.
          </li>
          <li className="mt-4">
            By posting Content on or through Service, You represent and warrant that:
            (i) Content is yours (you own it) and/or you have the right to use it and
            the right to grant us the rights and license as provided in these Terms,
            and (ii) that the posting of your Content on or through Service does not
            violate the privacy rights, publicity rights, copyrights, contract rights
            or any other rights of any person or entity. We reserve the right to
            terminate the account of anyone found to be infringing on a copyright.
          </li>
          <li className="mt-4">
            You retain any and all of your rights to any Content you submit, post or
            display on or through Service and you are responsible for protecting
            those rights. We take no responsibility and assume no liability for
            Content you or any third party posts on or through Service. However, by
            posting Content using Service you grant us the right and license to use,
            modify, publicly perform, publicly display, reproduce, and distribute
            such Content on and through Service. You agree that this license includes
            the right for us to make your Content available to other users of
            Service, who may also use your Content subject to these Terms.
          </li>
          <li className="mt-4">
            Tirios Corporation has the right but not the obligation to monitor and
            edit all Content provided by users.
          </li>
          <li className="mt-4">
            In addition, Content found on or through this Service are the property of
            Tirios Corporation or used with permission. You may not distribute,
            modify, transmit, reuse, download, repost, copy, or use said Content,
            whether in whole or in part, for commercial purposes or for personal
            gain, without express advance written permission from us.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        11. <span className="underline">Prohibited Uses</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            You may use Service only for lawful purposes and in accordance with
            Terms. You agree not to use Service:
            <ol>
              <li>
                (a) In any way that violates any applicable national or international
                law or regulation.
              </li>
              <li>
                (b) For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate content
                or otherwise.
              </li>
              <li>
                (c) To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail”, “chain letter,”
                “spam,” or any other similar solicitation.
              </li>
              <li>
                (d) To impersonate or attempt to impersonate Company, a Company
                employee, another user, or any other person or entity.
              </li>
              <li>
                (e) In any way that infringes upon the rights of others, or in any
                way is illegal, threatening, fraudulent, or harmful, or in connection
                with any unlawful, illegal, fraudulent, or harmful purpose or
                activity.
              </li>
              <li className="mb-4">
                (f) To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of Service, or which, as determined by us,
                may harm or offend Company or users of Service or expose them to
                liability.
              </li>
              <li>Additionally, you agree not to:</li>
              <li>
                (a) Use Service in any manner that could disable, overburden, damage,
                or impair Service or interfere with any other party’s use of Service,
                including their ability to engage in real time activities through
                Service.
              </li>
              <li>
                (b) Use any robot, spider, or other automatic device, process, or
                means to access Service for any purpose, including monitoring or
                copying any of the material on Service.
              </li>
              <li>
                (c) Use any manual process to monitor or copy any of the material on
                Service or for any other unauthorized purpose without our prior
                written consent.
              </li>
              <li>
                (d) Use any device, software, or routine that interferes with the
                proper working of Service.
              </li>
              <li>
                (e) Introduce any viruses, trojan horses, worms, logic bombs, or
                other material which ismalicious or technologically harmful.
              </li>
              <li>
                (f) Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of Service, the server on which Service is
                stored, or any server, computer, or database connected to Service.
              </li>
              <li>
                (g) Attack Service via a denial-of-service attack or a distributed
                denial-of-service attack.
              </li>
              <li>(h) Take any action that may damage or falsify Company rating.</li>
              <li>
                (i) Otherwise attempt to interfere with the proper working of
                Service.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        12. <span className="underline">No Use by Minors</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Service is intended only for access and use by individuals at least
            eighteen (18) years old. By accessing or using any of Company, you
            warrant and represent that you are at least eighteen (18) years of age
            and with the full authority, right, and capacity to enter into this
            agreement and abide by all of the terms and conditions of Terms. If you
            are not at least eighteen (18) years old, you are prohibited from both
            the access and usage of Service.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        13. <span className="underline">Accounts</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            When you create an account with us, you guarantee that you are above the
            age of 18, and that the information you provide us is accurate, complete,
            and current at all times. Inaccurate, incomplete, or obsolete information
            may result in the immediate termination of your account on Service.
          </li>
          <li className="mt-4">
            You are responsible for maintaining the confidentiality of your account
            and password, including but not limited to the restriction of access to
            your computer and/or account. You agree to accept responsibility for any
            and all activities or actions that occur under your account and/or
            password, whether your password is with our Service or a third-party
            service. You mustnotify us immediately upon becoming aware of any breach
            of security or unauthorized use of your account.{" "}
          </li>
          <li className="mt-4">
            You may not use as a username the name of another person or entity or
            that is not lawfully available for use, a name or trademark that is
            subject to any rights of another person or entity other than you, without
            appropriate authorization. You may not use as a username any name that is
            offensive, vulgar or obscene.
          </li>
          <li className="mt-4">
            We reserve the right to refuse service, terminate accounts, remove or
            edit content, or cancel orders in our sole discretion.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        14. <span className="underline">Intellectual Property</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Service and its original content (excluding Content provided by users),
            features and functionality are and will remain the exclusive property of
            Tirios Corporationand its licensors. Service is protected by copyright,
            trademark, and other laws of the United States. Our trademarks and trade
            dress may not be used in connection with any product or service
            withoutthe prior written consent of Tirios Corporation.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        15. <span className="underline">Copyright Policy</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            We respect the intellectual property rights of others. It is our policy
            to respond to any claim that Content posted on Service infringes on the
            copyright or other intellectual property rights (&quot;
            <strong>Infringement</strong>&quot;) of any person or entity.
          </li>
          <li className="mt-4">
            If you are a copyright owner, or authorized on behalf of one, and you
            believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via email to
            members@tirios.ai, with the subject line: &quot;Copyright
            Infringement&quot; and include in your claim a detailed description of
            the alleged Infringement as detailed below, under &quot;DMCA Notice and
            Procedure for Copyright Infringement Claims&quot;
          </li>
          <li className="mt-4">
            You may be held accountable for damages (including costs and
            attorneys&apos; fees) for misrepresentation or bad-faith claims on the
            infringement of any Content found on and/or through Service on your
            copyright.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        16.{" "}
        <span className="underline">
          DMCA Notice and Procedure for Copyright Infringement Claims
        </span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the following
            information in writing (see 17 U.S.C 512(c)(3) for further detail):
          </li>
          <li className="mt-4">
            (a) an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright&apos;s interest;{" "}
          </li>
          <li>
            (b) a description of the copyrighted work that you claim has been
            infringed, including the URL (i.e., web page address) of the location
            where the copyrighted work exists or a copy of the copyrighted work;
          </li>
          <li>
            (c) identification of the URL or other specific location on Service where
            the material that you claim is infringing is located;{" "}
          </li>
          <li>(d) your address, telephone number, and email address;</li>
          <li>
            (e) a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or the
            law;
          </li>
          <li>
            (f) a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the copyright
            owner or authorized to act on the copyright owner&apos;s behalf.{" "}
          </li>
          <li className="mt-4">
            You can contact us via email at{" "}
            <a href="mailto:members@tirios.ai">members@tirios.ai</a>.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        17. <span className="underline">Links To Other Web Sites</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Our Service may contain links to third party web sites or services that
            are not owned or controlled by Tirios Corporation.
          </li>
          <li className="mt-4">
            Tirios Corporation has no control over, and assumes no responsibility for
            the content, privacy policies, or practices of any third party web sites
            or services. We do not warrant the offerings of any of these
            entities/individuals or their websites.
          </li>
          <li className="mt-4">
            YOU ACKNOWLEDGE AND AGREE THAT TIRIOS CORPORATION SHALL NOT BE
            RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS
            CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR
            RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH
            ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
          </li>
          <li className="mt-4">
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
            OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        18. <span className="underline">Disclaimer Of Warranty</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
            BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
            INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
            THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS
            OBTAINED FROM US IS AT YOUR SOLE RISK.
          </li>
          <li className="mt-4">
            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY
            OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
            LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
            COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
            SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
            RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
            THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
            OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
            EXPECTATIONS.
          </li>
          <li className="mt-4">
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
            WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.
          </li>
          <li className="mt-4">
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
            LIMITED UNDER APPLICABLE LAW.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        19. <span className="underline">Limitation Of Liability</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
            SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
            (INCLUDING ATTORNEYS&apos; FEES AND ALL RELATED COSTS AND EXPENSES OF
            LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
            NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
            CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
            CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
            FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
            ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
            RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
            LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
            PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
            THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
            SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        20. <span className="underline">Termination</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            We may terminate or suspend your account and bar access to Service
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation, including
            but not limited to a breach of Terms.{" "}
          </li>
          <li className="mt-4">
            If you wish to terminate your account, you may simply discontinue using
            Service.
          </li>
          <li className="mt-4">
            All provisions of Terms which by their nature should survive termination
            shall survive termination, including, without limitation, ownership
            provisions, warranty disclaimers, indemnity and limitations of liability.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        21. <span className="underline">Governing Law</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            These Terms shall be governed and construed in accordance with the laws
            of State of Delaware without regard to its conflict of law provisions.
          </li>
          <li className="mt-4">
            Our failure to enforce any right or provision of these Terms will not be
            considered a waiver of those rights. If any provision of these Terms is
            held to be invalid or unenforceable by a court, the remaining provisions
            of these Terms will remain in effect. These Terms constitute the entire
            agreement between us regarding our Service and supersede and replace any
            prior agreements we might have had between us regarding Service.{" "}
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        22. <span className="underline">Changes To Service</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            We reserve the right to withdraw or amend our Service, and any service or
            material we provide via Service, in our sole discretion without notice.
            We will not be liable if for any reason all or any part of Service is
            unavailable at any time or for any period. From time to time, we may
            restrict access to some parts of Service, or the entire Service, to
            users, including registeredusers.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        23. <span className="underline">Amendments To Terms</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            We may amend Terms at any time by posting the amended terms on this site.
            It is your responsibility to review these Terms periodically.
          </li>
          <li className="mt-4">
            Your continued use of the Platform following the posting of revised Terms
            means that you accept and agree to the changes. You are expected to check
            this page frequently so you are aware of any changes, as they are binding
            on you.
          </li>
          <li className="mt-4">
            By continuing to access or use our Service after any revisions become
            effective, you agree to be bound by the revised terms. If you do not
            agree to the new terms, you are no longer authorized to use Service.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        24. <span className="underline">Waiver And Severability</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            No waiver by Company of any term or condition set forth in Terms shall be
            deemed a further or continuing waiver of such term or condition or a
            waiver of any other term or condition, and any failure of Company to
            assert a right or provision under Terms shall not constitute a waiver of
            such right or provision.
          </li>
          <li className="mt-4">
            If any provision of Terms is held by a court or other tribunal of
            competent jurisdiction to be invalid, illegal or unenforceable for any
            reason, such provision shall be eliminated or limited to the minimum
            extent such that the remaining provisions of Terms will continue in full
            force and effect.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        25. <span className="underline">Acknowledgement</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
            YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
          </li>
        </ol>
      </div>
      <h2 className="mt-4 font-bold">
        26. <span className="underline">Contact Us</span>
      </h2>
      <div className="ml-6">
        <ol>
          <li className="mt-4">
            Please send your feedback, comments, requests for technical support:
          </li>
          <li>
            By email: <a href="mailto:members@tirios.ai">members@tirios.ai</a>
          </li>
          <li>
            By visiting this page on our website:{" "}
            <a href="https://www.tirios.ai/">https://www.tirios.ai/</a>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default TermsOfService
