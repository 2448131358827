import { GET_PROVIDER_PROFIEL, UPDATE_PROVIDER_PROFILE } from "../actions/types"

const initialState = {
  categories: [],
  company: {},
  rates: [],
}

function profileReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PROVIDER_PROFIEL:
      return {
        ...state,
        ...payload,
      }
    case UPDATE_PROVIDER_PROFILE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default profileReducer
