import { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { Field, Form } from "react-final-form"

import SearchLocation from "../../SignUp/LocationSearchInput"
import { TextInputField, TextInput, SelectMenu } from "../../../../components/inputs"
import { RoundedButton } from "../../../../components/buttons"

import Static from "../../../../static.json"
import {
  stateOptions,
  zipcodeValidationPattern,
} from "../../../../constants/constants"

const AddressPopup = ({ onSubmit, open, setOpen, address }) => {
  const {
    dashboard: {
      profile: {
        modal: {
          title,
          input: {
            address1label,
            address1placeholder,
            address2placeholder,
            cityplaceholder,
            stateplaceholder,
            zipplaceholder,
            buttontitle,
          },
          validation: {
            address1validation,
            cityvalidation,
            statevalidation,
            zipvalidation,
          },
        },
      },
    },
  } = Static

  const [error, setError] = useState({})
  const [addressObject, setAddressObject] = useState(address)

  const handleSubmit = (values) => {
    const addressError = {}
    const { line1, city, state, zip } = addressObject

    if (!line1) {
      addressError.address1 = address1validation
    }
    if (!city) {
      addressError.city = cityvalidation
    }
    if (!state) {
      addressError.state = statevalidation
    }
    if (!zip || !zipcodeValidationPattern.test(zip)) {
      addressError.zip = zipvalidation
    }

    if (Object.keys(addressError).length === 0) {
      onSubmit({
        ...values,
        ...addressObject,
      })
      setOpen(false)
      setError({})
    } else {
      setError(addressError)
      return error
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pb-2 text-left overflow-hidden shadow-xl transform transition-all sm:m-0 sm:align-middle sm:max-w-xs sm:w-full sm:p-4">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="text-left py-4 text-center border-b border-2ray-400">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-red-600"
                  >
                    {title}
                  </Dialog.Title>
                </div>
              </div>
              <div className="my-2">
                <Form
                  onSubmit={handleSubmit}
                  render={({ handleSubmit }) => {
                    return (
                      <form onSubmit={handleSubmit} className="space-y-4">
                        <SearchLocation
                          type="text"
                          label={address1label}
                          name="address1"
                          placeholder={address1placeholder}
                          widthFull
                          error={error.address1}
                          setAddress={({ address1, city, state, zip }) =>
                            setAddressObject({ line1: address1, city, state, zip })
                          }
                          value={addressObject.line1}
                          setValue={(value) => {
                            setAddressObject({
                              ...addressObject,
                              line1: value,
                            })
                          }}
                        />
                        <Field
                          type="text"
                          name="line2"
                          defaultValue={addressObject.line2}
                          placeholder={address2placeholder}
                          component={TextInputField}
                          widthFull
                        />
                        <TextInput
                          type="text"
                          name="city"
                          placeholder={cityplaceholder}
                          widthFull
                          error={error.city}
                          value={addressObject.city}
                          onChange={(event) => {
                            setAddressObject({
                              ...addressObject,
                              city: event.target.value,
                            })
                          }}
                        />
                        <SelectMenu
                          type="select"
                          name="state"
                          options={stateOptions}
                          widthFull
                          error={error.state}
                          value={
                            addressObject.state &&
                            stateOptions.find(
                              ({ value }) => value === addressObject.state
                            )
                          }
                          placeholder={stateplaceholder}
                          onChange={({ value }) => {
                            setAddressObject({
                              ...addressObject,
                              state: value,
                            })
                          }}
                        />
                        <TextInput
                          type="number"
                          name="zip"
                          placeholder={zipplaceholder}
                          widthFull
                          value={addressObject.zip}
                          onChange={(event) => {
                            setAddressObject({
                              ...addressObject,
                              zip: event.target.value,
                            })
                          }}
                          error={error.zip}
                        />

                        <RoundedButton
                          variant="primary"
                          type="submit"
                          title={buttontitle}
                        />
                      </form>
                    )
                  }}
                />
              </div>
              {/* <div className="justify-center flex my-2">
                <RoundedButton
                  variant="primary"
                  title={actionlabel}
                  onClick={submitHandler}
                />
              </div> */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

AddressPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AddressPopup
