import { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { SearchIcon, UserIcon } from "@heroicons/react/outline"
import { connect } from "react-redux"
import { RoundedButton } from "../../../../components/buttons"
import { MemberStatusPopupModal } from "../../../../components/popup"
import NewMemberModal from "./NewMemberModal"
import EditMemberModal from "./EditMemberModal"
import Table from "./Table"
import { Redirect } from "react-router-dom"

import { membersTableColumns } from "../../../../constants/constants"
import Static from "../../../../static.json"
import {
  createNewMember,
  disableProviderMember,
  enableProviderMember,
  updateProfile,
} from "../../../../actions/member"
import { showErrorNotification } from "../../../../utils/helper"

const ProviderMembers = ({
  createNewMember,
  enableProviderMember,
  disableProviderMember,
  updateProfile,
  members,
  roles,
  categories,
}) => {
  const {
    dashboard: {
      members: { pagetitle, title, searchplaceholder, memberbuttontitle },
    },
  } = Static
  const [openModel, setOpenModel] = useState(false)
  const [editMemberModalOpen, setEditMemberModalOpen] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedMember, setSelectedMember] = useState()
  const [isMobile, setIsMobile] = useState(null)

  const activeRoles = useMemo(
    () => roles.filter(({ isActive }) => isActive),
    [roles]
  )
  // console.log(roles)
  const activeCategories = useMemo(
    () => categories.filter(({ isActive }) => isActive),
    [categories]
  )

  const checkMediaScreen = () =>
    window.screen.width < 768 ? setIsMobile(true) : setIsMobile(false)

  useEffect(() => {
    document.title = pagetitle
    checkMediaScreen()
  }, [])

  const handleCreateMember = (data) => {
    return createNewMember(data)
  }

  const handleStatusUpdate = (data) => {
    setSelectedMember(data)
    setStatusModal(true)
  }

  const handleEditMember = (data) => {
    console.log("Seelcted Member", data)
    setSelectedMember(data)
    setEditMemberModalOpen(true)
  }

  const statusHandler = () => {
    const { active, memberId } = selectedMember
    setLoading(true)
    if (active) {
      disableProviderMember(memberId)
        .then(() => {
          setLoading(false)
          setStatusModal(false)
        })
        .catch((error) => {
          showErrorNotification(error)
          setLoading(false)
        })
    } else {
      enableProviderMember(memberId)
        .then(() => {
          setLoading(false)
          setStatusModal(false)
        })
        .catch((error) => {
          showErrorNotification(error)
          setLoading(false)
        })
    }
  }

  const handleUpdateProfile = (data) => {
    return updateProfile(data)
  }

  if (isMobile) return <Redirect to={`/provider/dashboard/home`} />

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <h3 className="text-gray-600 text-xl">{title}</h3>
      <div className="my-3">
        <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg p-2 bg-white">
          <div className="mb-2">
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder={searchplaceholder}
              />
            </div>
          </div>

          <Table
            columns={membersTableColumns}
            data={members}
            setStatusModal={handleStatusUpdate}
            handleEditMember={handleEditMember}
          />
        </div>
      </div>
      <RoundedButton
        variant="primary"
        title={memberbuttontitle}
        onClick={() => {
          setSelectedMember(null)
          setOpenModel(true)
        }}
        icon={<UserIcon className="h-4 w-4 text-white mr-2" />}
      />
      {openModel && (
        <NewMemberModal
          onSubmit={handleCreateMember}
          open={openModel}
          setOpen={setOpenModel}
          roles={activeRoles}
          categories={activeCategories}
        />
      )}
      {selectedMember && (
        <EditMemberModal
          onSubmit={handleUpdateProfile}
          open={editMemberModalOpen}
          setOpen={setEditMemberModalOpen}
          roles={activeRoles}
          categories={activeCategories}
          selectedMember={selectedMember}
          member={selectedMember}
          isSelectedMemberOwner={selectedMember.primaryOwner}
        />
      )}
      <MemberStatusPopupModal
        active={selectedMember ? selectedMember.active : false}
        open={statusModal}
        setOpen={setStatusModal}
        onSubmit={statusHandler}
        loading={loading}
      />
    </div>
  )
}

ProviderMembers.propTypes = {
  createNewMember: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  disableProviderMember: PropTypes.func.isRequired,
  enableProviderMember: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    members: state.members,
    roles: state.roles,
    categories: state.categories,
  }
}

const mapDispatchToProps = {
  createNewMember,
  disableProviderMember,
  enableProviderMember,
  updateProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderMembers)
