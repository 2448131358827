import { combineReducers } from "redux"
import user from "./user"
import members from "./member"
import roles from "./role"
import job from "./job"
import profile from "./profile"
import categories from "./categories"

export default combineReducers({
  user,
  members,
  roles,
  job,
  profile,
  categories,
})
