import { useEffect, useState } from "react"
import OpenPlaidLink from "./PlaidLink"
import { RoundedButton } from "../../../../components/buttons"
import PlaidImage from "../../../../assets/Plaid.png"
import PlaidIconImage from "../../../../assets/PlaidIcon.png"
import SecureImage from "../../../../assets/Fingerprint.png"
import PrivateImage from "../../../../assets/Private.png"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getCreateToken } from "../../../../actions/payment"
import { Link } from "react-router-dom"

import Static from "../../../../static.json"

const PlaidLinkComponent = ({ getCreateToken, onContinue }) => {
  const {
    dashboard: {
      payments: {
        plaid: {
          title1,
          securetitle,
          securedesc,
          privatetitle,
          privatedesc,
          actiontitle,
          termsdesc,
          termslink,
          desc1,
          errorDesc,
          errorSuccess,
        },
      },
    },
  } = Static
  const [loader, setLoader] = useState(true)
  const [showPlaid, setPlaid] = useState(false)
  const [plaidSuccess, setPlaidSuccess] = useState(true)
  const [token, setToken] = useState(null)

  useEffect(() => {
    if (!token) {
      getCreateToken().then((res) => {
        setToken(res)
        setLoader(false)
      })
    }
  })

  if (plaidSuccess) {
    return (
      <div className="bg-white">
        <div className="grid grid-cols sm:grid-cols-2">
          <div className="flex px-3 py-14 justify-center items-center flex-col">
            <img className="w-18" src={PlaidIconImage} alt="plaid" />
            <h2 className="my-6 text-2xl">{title1}</h2>
            <img className="w-6" src={SecureImage} alt="fingerprint" />
            <p>{securetitle}</p>
            <p>{securedesc}</p>
            <img className="w-6 mt-4" src={PrivateImage} alt="fingerprint" />
            <p>{privatetitle}</p>
            <p>{privatedesc}</p>
          </div>
          <div className="bg-blue-550 flex px-4 py-5 justify-center items-center flex-col">
            <img className="w-96" src={PlaidImage} alt="plaid" />
            <p>{termsdesc}</p>
            <p
              className="cursor-pointer"
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              {termslink}
            </p>
            <div className="my-5">
              {token && (
                <OpenPlaidLink
                  token={token}
                  showPlaid={showPlaid}
                  onContinue={onContinue}
                  setPlaidSuccess={setPlaidSuccess}
                  setPlaid={setPlaid}
                />
              )}
              <RoundedButton
                variant="continue"
                title={actiontitle}
                loading={loader}
                onClick={() => setPlaid(true)}
              />
            </div>
            <p>{desc1}</p>
          </div>
        </div>
        <div className="h-2 bg-gray-200 rounded-lg">
          <div className="h-2 w-full bg-primary rounded-lg" />
        </div>
      </div>
    )
  } else
    return (
      <div className="bg-white">
        <div className="grid grid-cols sm:grid-cols-2">
          <div className="flex px-2 py-14 justify-center items-center flex-col">
            <h2 className="my-2 text-2xl">{errorSuccess}</h2>
            <p>{errorDesc}</p>
            <div className="my-10" onClick={() => window.location.reload()}>
              <RoundedButton
                variant="primary"
                title="Go to Payments"
                loading={loader}
              />
            </div>
          </div>
          <div className="bg-blue-550 flex px-2 py-14 justify-center items-center flex-col">
            <img className="w-96" src={PlaidImage} alt="plaid" />
          </div>
        </div>
        <div className="h-2 bg-gray-200 rounded-lg">
          <div className="h-2 w-full bg-primary rounded-lg" />
        </div>
      </div>
    )
}

PlaidLinkComponent.propTypes = {
  getCreateToken: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    payment: state.entity,
  }
}

const mapDispatchToProps = {
  getCreateToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaidLinkComponent)
