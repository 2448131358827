import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Static from "../../../../../static.json"
import { Field, Form } from "react-final-form"
import { TextInputField } from "../../../../../components/inputs"
import { passwordCriteria } from "../../../../../constants/constants"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { Button } from "../../../../../components/buttons"
import { getDecoded, getUser } from "../../../../../utils/auth"
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../utils/helper"
import { changePassword } from "../../../../../actions/user"

const ChangePasswordSettings = ({ changePassword }) => {
  const {
    dashboard: {
      changePassword: {
        pagetitle,
        title,
        passwordInvalidCriteriaErrorText,
        passwordMatchErrorText,
        passwordEmptyFieldErrorText,
        passwordChangeSuccessText,
        passwordChangeFailureText,
        sectionHeading,
        changePwdCriteriaText,
        enterPasswordField,
        confirmPasswordField,
      },
    },
  } = Static

  const [loading, setLoading] = useState(false)
  const validatePassword = (value) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    if (!value) return passwordEmptyFieldErrorText
    return regex.test(value) ? undefined : passwordInvalidCriteriaErrorText
  }

  const handleSubmit = (values) => {
    if (values.enterPassword !== values.enterPassword) {
      return { confirmPassword: passwordMatchErrorText }
    }
    if (!values.enterPassword || !values.confirmPassword) {
      return {
        enterPassword: passwordEmptyFieldErrorText,
        confirmPassword: passwordEmptyFieldErrorText,
      }
    } else {
      const user = getUser()
      const {
        data: { idToken, accessToken },
      } = JSON.parse(user)
      const token = idToken
      const oldPassword = localStorage.getItem("oldPassword")
      const newPassword = values.confirmPassword
      setLoading(true)
      changePassword(token, accessToken, oldPassword, newPassword)
        .then((response) => {
          setLoading(false)
          showSuccessNotification(passwordChangeSuccessText)
        })
        .catch((error) => {
          setLoading(false)
          showErrorNotification(error, passwordChangeFailureText)
        })
    }
  }

  useEffect(() => {
    document.title = pagetitle
  }, [])

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <h3 className="text-gray-600 text-xl mb-4">{title}</h3>
      <div className="ml-16 mt-10">
        <h4 className="text-lg font-semibold mb-6">{sectionHeading}</h4>
        <Form
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {}
            if (values.enterPassword !== values.confirmPassword) {
              errors.confirmPassword = "Password does not match"
            }
            return errors
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* <Field
                type="password"
                name="enterOldPassword"
                placeholder="Enter Old Password"
                component={TextInputField}
                widthFull
                className="sm:w-96 xs:w-full"
                validate={validatePassword}
              /> */}
              <Field
                type="password"
                name="enterPassword"
                placeholder={enterPasswordField}
                component={TextInputField}
                widthFull
                className="sm:w-96 xs:w-full"
                validate={validatePassword}
              />
              <Field
                type="password"
                name="confirmPassword"
                placeholder={confirmPasswordField}
                component={TextInputField}
                widthFull
                className="sm:w-96 xs:w-full"
                validate={validatePassword}
              />
              <div className="font-semibold text-sm">
                <p className="mt-8 mb-3">{changePwdCriteriaText}</p>
                {passwordCriteria.map((criteria) => {
                  return (
                    <div
                      key={criteria.name}
                      className="flex items-center ml-2 leading-8"
                    >
                      <CheckCircleIcon
                        className="h-5 w-5 text-primary mr-2"
                        aria-hidden="true"
                      />
                      <span>{criteria.value}</span>
                    </div>
                  )
                })}
              </div>
              <div className="w-72 relative top-6">
                <Button type="submit" title="Continue" disabled={submitting} />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}

ChangePasswordSettings.propTypes = {
  changePassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = {
  changePassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordSettings)
