import PropTypes from "prop-types"
import { Switch } from "@headlessui/react"

import { classNames } from "../../utils/ClassNames"

export const ToggleField = ({ meta, title, label, input, widthFull }) => {
  const { touched, valid, error } = meta
  const errorStatus = touched && error && error
  const validStatus = touched && valid
  const { value } = input

  return (
    <Toggle
      title={title}
      label={label}
      {...input}
      value={!!value}
      error={errorStatus}
      validate={validStatus}
      widthFull={widthFull}
    />
  )
}

ToggleField.propTypes = {
  widthFull: PropTypes.bool,
  meta: PropTypes.object,
  label: PropTypes.string,
  title: PropTypes.string,
  input: PropTypes.object.isRequired,
}

ToggleField.defaultProps = {
  widthFull: false,
  meta: {},
  label: null,
  title: null,
}

const Toggle = ({ title, label, onChange, value, error }) => (
  <Switch.Group as="div" className="flex items-center justify-between">
    <span className="flex-grow flex flex-col mr-8">
      {title && (
        <Switch.Label
          as="span"
          className="text-md font-medium text-text-primary"
          passive
        >
          {title}
        </Switch.Label>
      )}
      {label && (
        <Switch.Description as="span" className="text-sm text-text-primary">
          {label}
        </Switch.Description>
      )}
      {error && (
        <Switch.Description as="span" className="text-sm text-red-400">
          {error}
        </Switch.Description>
      )}
    </span>
    <Switch
      checked={value}
      onChange={onChange}
      className={classNames(
        value ? "bg-primary" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          value ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </Switch>
  </Switch.Group>
)

Toggle.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
}

Toggle.defaultProps = {
  title: null,
  label: null,
  value: false,
  disabled: false,
  error: null,
}

export default Toggle
