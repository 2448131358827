import { signIn, logOut } from "../utils/auth"
import { SIGN_UP, SIGN_IN, LOG_OUT } from "../actions/types"

const initialState = null

function userReducer(user = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SIGN_UP:
      return null

    case SIGN_IN:
      signIn(payload)
      return payload

    case LOG_OUT:
      logOut()
      return null

    default:
      return user
  }
}

export default userReducer
