import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import moment from "moment"

import SpinLoader from "../../../../components/SpinLoader"
import Static from "../../../../static.json"
import { classNames } from "../../../../utils/ClassNames"
import AssignPopup from "./Popup"
import ContactModal from "./ContactModal"
import {
  scheduled,
  tradeCategories,
  jobActionLabels,
} from "../../../../constants/constants"
import userIcon from "../../../../assets/user.png"
import BellIcon from "../../../../assets/bell-icon.png"

const statusFlags = {
  ACCEPTED: "Scheduled",
  CHECKED_IN: "Jobs in Progress",
  CHECKED_OUT: "Invoices to be Completed",
  CLOSED: "Completed Jobs",
  INVOICE_SENT_FOR_REVIEW: "Invoices to be Completed",
  TROUBLESHOOT: "Troubleshooting",
}
const Table = ({
  columns,
  data,
  members,
  assignJob,
  getMembersList,
  executeScroll,
}) => {
  const {
    dashboard: {
      jobs: { actionassignbuttontitle, actionviewbuttontitle },
    },
  } = Static

  const {
    CLOSED,
    CHECKED_IN,
    INVOICE_SENT_FOR_REVIEW,
    TROUBLESHOOT,
    NEEDS_REVIEW,
    VIEW_INVOICE,
    CONTACT,
    VIEW_JOB,
    SCHEDULED,
  } = jobActionLabels

  const [openModal, setOpenModal] = useState(false)
  const [selectedJob, setSelectedJob] = useState()
  const [loading, setLoading] = useState(false)
  const [currentMember, setCurrentMembers] = useState(0)
  const [isContactModal, setIsContactModal] = useState(false)

  const handleAssign = (jobId, serviceType, memberId, serviceCategory) => {
    getMembersList(serviceType, "WORKER").then(() => {
      setSelectedJob(jobId)
      setCurrentMembers({ id: memberId, serviceCategory })
      setOpenModal(true)
    })
  }
  const onSubmit = async (newMemberId) => {
    console.log(currentMember, "currentMember")
    setLoading(true)
    await assignJob(currentMember, selectedJob, newMemberId)
    setOpenModal(false)
    setLoading(false)
  }
  let workerList = members.filter((x) => x.memberId !== currentMember.id)
  let filterType = data.filter((x) => x.status.description === scheduled)
  let columnList =
    filterType.length > 0 ? columns : columns.filter((x) => x.dataKey !== "action")

  const toggleContactModal = () => {
    setIsContactModal(true)
  }

  const scrollToTop = () => {
    executeScroll()
  }

  return (
    <>
      <AssignPopup
        onSubmit={onSubmit}
        open={openModal}
        setOpen={setOpenModal}
        members={workerList}
        category={currentMember.serviceCategory}
      />
      <ContactModal open={isContactModal} setOpen={setIsContactModal} />
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map(({ label, dataKey }) => (
              <th
                key={dataKey}
                scope="col"
                className="sm:px-4 py-3 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map(
            ({
              id,
              scheduledTime,
              property: { address_line1, city, state, zipCode },
              serviceCategory: { description },
              // assignedTo,
              status: { description: status },
              providerMember: { firstName, lastName, memberId },
            }) => (
              <tr key={id}>
                <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                  {moment(`${scheduledTime}z`).format("MMM DD, YYYY")}
                </td>
                <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                  {moment(`${scheduledTime}z`).format("hh:mm A")}
                </td>
                <td className="sm:px-4 py-3 px-2 whitespace-nowrap block">
                  <span className="px-2 text-xs block font-semibold text-gray-700">
                    {address_line1}
                  </span>
                  <span className="px-2 text-xs block font-semibold text-gray-700">
                    {`${city} ${state}, ${zipCode}`}
                  </span>
                </td>
                <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                  {tradeCategories[description]}
                </td>
                <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                  <span className="px-3 inline-flex text-xs leading-5 rounded-full bg-gray-200 text-gray-700">
                    {statusFlags[status]}
                  </span>
                </td>
                <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                  <div className="flex float-left">
                    <div className="px-2 flex items-center text-xs py-1 font-semibold rounded-full bg-gray-200 text-gray-700 mx-auto">
                      <img
                        className="h-5 w-5 rounded-full mr-2"
                        alt=""
                        src={userIcon}
                      />
                      <span className="px-1 block font-semibold text-gray-700">{`${firstName} ${lastName}`}</span>
                    </div>
                  </div>
                </td>
                {statusFlags[status] === SCHEDULED && (
                  <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                    <button
                      className={classNames(
                        "rounded-full shadow-md px-2 py-1 text-xs w-full focus:outline-none  bg-primary text-gray-200"
                      )}
                      onClick={() =>
                        handleAssign(id, description, memberId, description)
                      }
                    >
                      {loading && <SpinLoader />}
                      {actionassignbuttontitle}
                    </button>
                  </td>
                )}
                {statusFlags[status] === CHECKED_IN && (
                  <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                    <button
                      onClick={() => executeScroll()()}
                      className="rounded-full shadow-md px-2 py-1 text-xs w-full focus:outline-none bg-gray-200 text-gray-700"
                    >
                      {VIEW_JOB}
                    </button>
                  </td>
                )}
                {statusFlags[status] === TROUBLESHOOT && (
                  <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                    <button
                      onClick={() => toggleContactModal()}
                      className="rounded-full shadow-md  px-2 py-1 text-xs w-full focus:outline-none bg-red-350 text-white"
                    >
                      {CONTACT}
                    </button>
                  </td>
                )}
                {statusFlags[status] === INVOICE_SENT_FOR_REVIEW && (
                  <td className="sm:px-4 py-3 px-2 whitespace-nowrap relative">
                    <img
                      src={BellIcon}
                      alt="bell"
                      className="absolute -left-2 top-6 w-3"
                    />
                    <button className="rounded-full shadow-md px-2 py-1 text-xs w-full focus:outline-none bg-red-350 text-white">
                      <Link to="/provider/dashboard/payments/invoices">
                        {NEEDS_REVIEW}
                      </Link>
                    </button>
                  </td>
                )}
                {statusFlags[status] === CLOSED && (
                  <td className="sm:px-4 py-3 px-2 whitespace-nowrap">
                    <button className="rounded-full shadow-md px-2 py-1 text-xs w-full focus:outline-none bg-gray-200 text-gray-700">
                      <Link to="/provider/dashboard/payments/invoices">
                        {VIEW_INVOICE}
                      </Link>
                    </button>
                  </td>
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  members: PropTypes.array.isRequired,
  assignJob: PropTypes.func.isRequired,
  getMembersList: PropTypes.func.isRequired,
  executeScroll: PropTypes.any,
}

Table.defaultProps = {
  data: [],
}

export default Table
