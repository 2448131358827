import {
  GET_MEMBERS,
  CREATE_MEMBER,
  ENABLE_MEMBER,
  DISABLE_MEMBER,
  RESET_PASSWORD,
  UPDATE_MEMBER,
} from "./types"
import {
  getMembers,
  createMember,
  resetMemberPassword,
  disableMember,
  enableMember,
  updateMemberProfile,
} from "../services/api"
import { getProviderId } from "../utils/auth"

export const getAllMembers = () => async (dispatch) => {
  try {
    const res = await getMembers(getProviderId())

    dispatch({
      type: GET_MEMBERS,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const createNewMember = (data) => async (dispatch) => {
  try {
    const res = await createMember(getProviderId(), data)

    dispatch({
      type: CREATE_MEMBER,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const enableProviderMember = (memberId) => async (dispatch) => {
  try {
    const res = await enableMember(getProviderId(), memberId)

    dispatch({
      type: ENABLE_MEMBER,
      payload: memberId,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const disableProviderMember = (memberId) => async (dispatch) => {
  try {
    const res = await disableMember(getProviderId(), memberId)

    dispatch({
      type: DISABLE_MEMBER,
      payload: memberId,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const resetPassword = (data) => async (dispatch) => {
  try {
    const res = await resetMemberPassword(data)

    dispatch({
      type: RESET_PASSWORD,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const updateProfile = (data) => async (dispatch) => {
  try {
    const res = await updateMemberProfile(getProviderId(), data)

    dispatch({
      type: UPDATE_MEMBER,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
