import { useState, useEffect } from "react"
import { Field, Form } from "react-final-form"
import PropTypes from "prop-types"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import { RoundedButton } from "../../../../components/buttons"
import {
  TextInputField,
  SelectInputField,
  GreenCheckbox,
  CheckboxField,
  SelectMenu,
} from "../../../../components/inputs"
import {
  emailValidationPattern,
  phoneNumberValidationPattern,
  newOwnerCreation,
  stateOptions,
} from "../../../../constants/constants"
import Static from "../../../../static.json"
import { event } from "react-ga"

const AboutOwner = ({ onSubmit, entity: { members } }) => {
  const {
    dashboard: {
      payments: {
        actionbuttonlabel,
        ownerheaderlabel,
        input: {
          ownerlabel,
          contactlabel,
          homeaddressplaceholder,
          cityplaceholder,
          stateplaceholder,
          businesstitleplaceholder,
          ownershiplabel,
          address1placeholder,
          address2placeholder,
          zipplaceholder,
          countrydefault,
          countryplaceholder,
          emailplaceholder,
          mobileplaceholder,
          anotherownerlabel,
          firstnameplaceholder,
          lastnameplaceholder,
          doblabel,
          dayplaceholder,
          monthplaceholder,
          yearplaceholder,
          sssnlabel,
          ssnplaceholder1,
          ssnplaceholder2,
          ssnplaceholder3,
          ownershipdescription,
        },
        validation: {
          firstnamevalidation,
          lastnamevalidation,
          dobvalidation,
          ssnvalidation,
          dobValidation1,
          dobValidation2,
          dobValidation3,
          ssnValidation1,
          ssnValidation2,
          ssnValidation3,
          mobilevalidation,
          ownershipvalidation,
          addressvalidation,
          cityvalidation,
          statevalidation,
          zipvalidation,
          emailvalidation,
        },
        countryOptions,
      },
    },
  } = Static

  const [isAddOwner, setIsAddOwner] = useState(false)
  const [checkedIndex, setCheckedIndex] = useState(0)

  const handleAnotherOwner = (push, newOwnerCreation) => {
    push("members", newOwnerCreation)
  }

  // const handlePrimary = (event, fields) => {
  //   console.log(event.target.id)
  //   // const val = event == "1" ? "0" : "1"
  //   const getIndex = event.target.id.split("[")[1].charAt(0)
  //   fields.value.forEach((element) => {
  //     element["primary"] = false
  //   })
  //   fields.value[getIndex]["primary"] = true
  //   console.log("Fields", fields)
  // }

  const saveOwner = (values) => {
    // onSubmit({ ...values })

    // Set all primary value to false
    const membersArr = values.members
    membersArr.forEach((item) => {
      item.primary = false
    })
    setTimeout(() => {
      // Target selected index and set to true
      membersArr[checkedIndex].primary = true
      onSubmit({ members: membersArr })
    }, 200)
  }

  return (
    <div className="bg-white">
      <Form
        onSubmit={saveOwner}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{ members }}
        render={({
          handleSubmit,
          form: {
            mutators: { push, pop, update },
          },
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="members">
                {({ fields }) =>
                  fields.map((name, oindex) => {
                    if (oindex > 0) {
                      setIsAddOwner(true)
                    } else {
                      setIsAddOwner(false)
                    }
                    return (
                      <>
                        {oindex > 0 && (
                          <div className="rounded-t-xl bg-gray-600 h-12 flex px-8 items-center text-white">
                            {`${ownerheaderlabel} ${oindex + 1}`}
                          </div>
                        )}
                        <div
                          key={oindex}
                          className="grid grid-cols sm:grid-cols-2 gap-6 py-6 px-8"
                        >
                          <div>
                            <Field
                              type="text"
                              label={ownerlabel}
                              name={`${name}.first`}
                              placeholder={firstnameplaceholder}
                              component={TextInputField}
                              widthFull
                              validate={(value) =>
                                value ? undefined : firstnamevalidation
                              }
                              required
                            />
                            <Field
                              type="text"
                              name={`${name}.last`}
                              placeholder={lastnameplaceholder}
                              component={TextInputField}
                              widthFull
                              validate={(value) =>
                                value ? undefined : lastnamevalidation
                              }
                              required
                            />
                            <Field
                              type="text"
                              name={`${name}.businesstitle`}
                              placeholder={businesstitleplaceholder}
                              component={TextInputField}
                              widthFull
                            />
                            <Field
                              validateOnBlur
                              validate={(value) =>
                                !emailValidationPattern.test(value) &&
                                emailvalidation
                              }
                              required
                              type="email"
                              name={`${name}.email`}
                              placeholder={emailplaceholder}
                              component={TextInputField}
                              widthFull
                            />
                            <div className="flex justify-between">
                              <label className="w-12 flex text-sm font-medium text-text-primary items-center text-center">
                                {doblabel}
                              </label>
                              <Field
                                type="number"
                                name={`${name}.dob.month`}
                                placeholder={monthplaceholder}
                                component={TextInputField}
                                maxLength="2"
                                className="w-28"
                                widthFull
                                validate={(value) =>
                                  value >= 1 && value <= 12
                                    ? undefined
                                    : dobValidation1
                                }
                              />
                              <Field
                                type="number"
                                name={`${name}.dob.day`}
                                placeholder={dayplaceholder}
                                component={TextInputField}
                                maxLength="2"
                                className="w-28"
                                widthFull
                                validate={(value) =>
                                  value >= 1 && value <= 31
                                    ? undefined
                                    : dobValidation2
                                }
                              />
                              <Field
                                type="number"
                                name={`${name}.dob.year`}
                                placeholder={yearplaceholder}
                                component={TextInputField}
                                maxLength="4"
                                className="w-36"
                                widthFull
                                validate={(value) =>
                                  value && value.length === 4
                                    ? undefined
                                    : dobValidation3
                                }
                              />
                            </div>
                            <div className="flex justify-between">
                              <label className="w-12 flex text-sm font-medium text-text-primary items-center text-center">
                                {sssnlabel}
                              </label>
                              <Field
                                // type="text"
                                type="number"
                                name={`${name}.ssn.pre`}
                                placeholder={ssnplaceholder1}
                                component={TextInputField}
                                // maxLength="3"
                                className="w-32"
                                widthFull
                                validate={(value) =>
                                  value && value.length == 3
                                    ? undefined
                                    : ssnValidation1
                                }
                                required
                              />
                              <Field
                                // type="text"
                                type="number"
                                name={`${name}.ssn.mid`}
                                placeholder={ssnplaceholder2}
                                component={TextInputField}
                                className="w-24"
                                // maxLength="2"
                                widthFull
                                validate={(value) =>
                                  value && value.length == 2
                                    ? undefined
                                    : ssnValidation2
                                }
                                required
                              />
                              <Field
                                // type="text"
                                type="number"
                                name={`${name}.ssn.suf`}
                                placeholder={ssnplaceholder3}
                                component={TextInputField}
                                className="w-36"
                                // maxLength="4"
                                widthFull
                                validate={(value) =>
                                  value && value.length == 4
                                    ? undefined
                                    : ssnValidation3
                                }
                                required
                              />
                            </div>
                            <div className="flex">
                              <label className="flex w-35 text-sm font-medium text-text-primary items-center text-center">
                                {ownershiplabel}
                              </label>
                              <Field
                                type="number"
                                name={`${name}.ownership`}
                                component={TextInputField}
                                className="w-15 px-5"
                                maxLength="2"
                                widthFull
                                // validate={(value) =>
                                //   value && value >= 25
                                //     ? undefined
                                //     : ownershipvalidation
                                // }
                                validate={(value) =>
                                  value >= 25 && value <= 100
                                    ? undefined
                                    : ownershipvalidation
                                }
                              />
                              {oindex === 0 ? (
                                <div onClick={() => setCheckedIndex(oindex)}>
                                  <Field name={`${name}.primary`}>
                                    {({ input }) => (
                                      <div className="flex flex-col py-4 w-30">
                                        <GreenCheckbox
                                          {...input}
                                          id={`${name}.primary`}
                                          checked={true}
                                          // onChange={(event) => {
                                          //   console.log("Changed", oindex)
                                          // }}
                                          name={"Primary"}
                                          label="Primary"
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              ) : (
                                <div onClick={() => setCheckedIndex(oindex)}>
                                  <Field name={`${name}.primary`}>
                                    {({ input }) => (
                                      <div className="flex flex-col py-4 w-30">
                                        <GreenCheckbox
                                          {...input}
                                          id={`${name}.primary`}
                                          // onChange={(event) => {
                                          //   console.log("Changed", oindex)
                                          // }}
                                          name={"Primary"}
                                          label="Primary"
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              )}
                              {/* {oindex === 0 ? (
                                <div>
                                  <Field name={`${name}.primary`}>
                                    {({ input }) => (
                                      <div className="flex flex-col py-4 w-30">
                                        <GreenCheckbox
                                          {...input}
                                          id={`${name}.primary`}
                                          checked={true}
                                          onChange={(event) => {
                                            console.log("Changed", oindex)
                                            handlePrimary(event, fields)
                                          }}
                                          name={"Primary"}
                                          label="Primary"
                                        />
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              ) : (
                                <Field name={`${name}.primary`}>
                                  {({ input }) => (
                                    <div className="flex flex-col py-4 w-30">
                                      <GreenCheckbox
                                        {...input}
                                        id={`${name}.primary`}
                                        onChange={(event) => {
                                          console.log("Changed", oindex)
                                          handlePrimary(event, fields)
                                        }}
                                        name={"Primary"}
                                        label="Primary"
                                        value={false}
                                      />
                                    </div>
                                  )}
                                </Field>
                              )} */}
                            </div>
                          </div>
                          <div>
                            <Field
                              type="text"
                              label={contactlabel}
                              name={`${name}.address1`}
                              placeholder={address1placeholder}
                              component={TextInputField}
                              widthFull
                              validate={(value) =>
                                value ? undefined : addressvalidation
                              }
                              required
                            />
                            <Field
                              type="text"
                              name={`${name}.address2`}
                              placeholder={address2placeholder}
                              component={TextInputField}
                              widthFull
                            />
                            <Field
                              type="text"
                              name={`${name}.city`}
                              placeholder={cityplaceholder}
                              component={TextInputField}
                              widthFull
                              validate={(value) =>
                                value ? undefined : cityvalidation
                              }
                              required
                            />
                            <Field
                              type="select"
                              name={`${name}.state`}
                              options={stateOptions}
                              component={SelectInputField}
                              placeholder={stateplaceholder}
                              widthFull
                              validate={(value) =>
                                value ? undefined : statevalidation
                              }
                              required
                            />
                            <Field
                              type="number"
                              name={`${name}.zip`}
                              placeholder={zipplaceholder}
                              component={TextInputField}
                              widthFull
                              validate={(value) =>
                                value ? undefined : zipvalidation
                              }
                              required
                            />
                            <Field
                              type="select"
                              name={`${name}.country`}
                              options={countryOptions}
                              component={SelectInputField}
                              placeholder={countryplaceholder}
                              defaultValue={countrydefault}
                              widthFull
                              required
                            />
                            <Field
                              validateOnBlur
                              required
                              type="number"
                              name={`${name}.phone`}
                              placeholder={mobileplaceholder}
                              component={TextInputField}
                              widthFull
                              validate={(value) =>
                                value && value.length == 10
                                  ? undefined
                                  : mobilevalidation
                              }
                            />
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </FieldArray>

              <div className="py-6 px-8">
                <div className="flex">
                  <RoundedButton
                    variant="primary"
                    onClick={() => handleAnotherOwner(push, newOwnerCreation)}
                    title={anotherownerlabel}
                  />
                  {isAddOwner && (
                    <RoundedButton
                      variant="primary"
                      onClick={() => handleAnotherOwner(pop, newOwnerCreation)}
                      title="Cancel"
                      className="ml-5"
                    />
                  )}
                </div>
                <p className="my-4 italic">{ownershipdescription}</p>
                <div className="my-4 h-2 bg-gray-200 rounded-lg">
                  <div className="my-4 h-2 w-2/3 bg-primary rounded-lg" />
                </div>
                <RoundedButton
                  variant="primary"
                  type="submit"
                  title={actionbuttonlabel}
                />
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

AboutOwner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
}

export default AboutOwner
