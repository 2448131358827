// Users
export const SIGN_IN = "SIGN_IN"
export const SIGN_UP = "SIGN_UP"
export const LOG_OUT = "LOG OUT"
export const FORGOT_PASSWORD = "FORGOT PASSWORD"
export const CHANGE_PASSWORD = "CHANGE PASSWORD"
export const SEND_VERIFICATION_NOTIFICATION = "SEND_VERIFICATION_NOTIFICATION"
export const VERIFY_EMAIL = "VERIFY_EMAIL"
export const SET_PASSWORD = "SET_PASSWORD"

// Members
export const GET_MEMBERS = "GET_MEMBERS"
export const CREATE_MEMBER = "CREATE_MEMBER"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const ENABLE_MEMBER = "ENABLE_MEMBER"
export const DISABLE_MEMBER = "DISABLE_MEMBER"
export const UPDATE_MEMBER = "UPDATE_MEMBER"
export const GET_MEMBERS_BY_JOB = "GET_MEMBERS_BY_JOB"

// Jobs
export const GET_ROLES = "GET_ROLES"

// Jobs
export const GET_JOB_OFFERS = "GET_JOB_OFFERS"
export const GET_ALL_JOBS = "GET_ALL_JOBS"
export const ACCEPT_JOB_OFFER = "ACCEPT_JOB_OFFER"
export const GET_ACCEPTED_JOBS = "GET_ACCEPETED_JOBS"
export const DECLINE_JOB_OFFER = "DECLINE_JOB_OFFER"
export const GET_JOB_DETAIL = "GET_JOB_DETAIL"
export const ASSIGN_JOB = "ASSIGN_JOB"
export const ACCEPT_JOB_AND_ASSIGN_MEMBER = "ACCEPT_JOB_AND_ASSIGN_MEMBER"
export const FULL_DECLINE_JOB_OFFER = "FULL_DECLINE_JOB_OFFER"
export const GET_ALL_JOBS_TYPE = "GET_ALL_JOBS_TYPE"

// Profile
export const GET_PROVIDER_PROFIEL = "GET_PROVIDER_PROFIEL"
export const UPDATE_PROVIDER_PROFILE = "UPDATE_PROVIDER_PROFILE"

// Company
export const GET_CATEGORIES = "GET_CATEGORIES"

// Payment
export const UPDATE_PAYMENT_DETAILS = "UPDATE_PAYMENT_DETAILS"
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS"
export const CREATE_LINKTOKEN = "CREATE_LINKTOKEN"
