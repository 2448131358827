import http from "./http"
import { API_URL } from "../config"

export const getRoles = () => http.get("authorization/roles")

export const getCategories = (providerId) =>
  http.get(`serviceProvider/providers/${providerId}/categories`)

// To get all members under providerID
export const getMembers = (providerId) =>
  http.get(`serviceProvider/providers/${providerId}/members?includeOwner=true`)

export const createMember = (providerId, data) =>
  http.post(`serviceProvider/providers/${providerId}/members`, data)

export const resetMemberPassword = (data) =>
  http.post("authorization/users/password", data)

export const getJobOffersForServiceProvider = (providerId) =>
  http.get(`jobs/providers/${providerId}/jobOffers`)

export const acceptJobOfferByMember = (providerId, memberId, jobId, data) =>
  http.put(
    `jobs/providers/${providerId}/members/${memberId}/jobOffers/${jobId}/acceptAndAssign`,
    data
  )

export const declineJobOfferByMember = (providerId, memberId, jobId, data) =>
  http.put(
    `jobs/providers/${providerId}/members/${memberId}/jobOffers/${jobId}/decline`,
    data
  )

export const acceptJobAndAssignToMember = (providerId, memberId, jobId, data) =>
  http.put(
    `jobs/providers/${providerId}/members/${memberId}/jobs/${jobId}/assign`,
    data
  )

// Decline Job offer by Owner
export const fullDeclineJobFromProvider = (providerId, memberId, jobOfferId, data) =>
  http.put(
    `jobs/providers/${providerId}/members/${memberId}/jobOffers/${jobOfferId}/fullDecline`,
    data
  )

export const getJobDetail = (providerId, memberId, jobId) =>
  http.get(`jobs/providers/${providerId}/members/${memberId}/jobs/${jobId}`)

export const checkInJob = (providerId, memberId, jobId) =>
  http.put(`jobs/providers/${providerId}/members/${memberId}/jobs/${jobId}/checkin`)

export const checkOutJob = (providerId, memberId, jobId) =>
  http.put(`jobs/providers/${providerId}/members/${memberId}/jobs/${jobId}/checkout`)

export const assignJob = (providerId, memberId, jobId, anotherMemberId) =>
  http.put(`jobs/providers/${providerId}/members/${memberId}/jobs/${jobId}/assign`, {
    anotherMemberId,
  })

export const getAccepetedJobsByMember = (providerId, memberId) =>
  http.get(`jobs/providers/${providerId}/members/${memberId}/jobs?status=ACCEPTED`)

export const disableMember = (providerId, memberId) =>
  http.put(`serviceProvider/providers/${providerId}/members/disable`, {
    memberId,
  })

export const enableMember = (providerId, memberId) =>
  http.put(`serviceProvider/providers/${providerId}/members/enable`, {
    memberId,
  })

export const updateMemberProfile = (providerId, data) =>
  http.put(`serviceProvider/providers/${providerId}/members/profile`, data)

// export const getAllJobsForProvider = (providerId, memberId) =>
// http.get(`jobs/providers/${providerId}/jobs`)
export const getAllJobsForProvider = (providerId) =>
  http.get(`jobs/providers/${providerId}/jobs`)

export const getJobsByFilter = (providerId, filterValue) =>
  http.get(`jobs/providers/${providerId}/jobs?status=${filterValue}`)

export const sendRequestLink = (providerId, memberId) =>
  http.post(
    `serviceProvider/providers/${providerId}/members/${memberId}/notifyNewOwnerSignup`
  )

export const getCompanyProfileInfo = (providerId) =>
  http.get(`serviceProvider/providers/${providerId}/profile`)

export const updateProviderProfile = (providerId, data) =>
  http.put(`serviceProvider/providers/${providerId}/profile`, data)

export const getAllMembersByJob = (providerId, typeOfWork, role) =>
  http.get(
    `serviceProvider/providers/${providerId}/members?category=${typeOfWork}&role=${role}&active=true`
  )

export const createLinkToken = (data) =>
  http.post(`serviceProvider/payments/createlinktoken`, data)

export const getPaymentDetails = (providerId) =>
  http.get(`serviceProvider/payments/entities?providerId=${providerId}`)

export const updatePaymentDetails = (data) =>
  http.post(`serviceProvider/payments/entities`, data)

export const getBankInfo = (providerId) =>
  http.get(`${API_URL}serviceProvider/payments/accounts?providerId=${providerId}`)

// Persona Identity
export const updatePersonaIdentity = (username) =>
  http.put(`${API_URL}authorization/users/${username}/status`, {
    status: "inquiry_completed",
  })

export const getPersonaIdentityStatus = (username) =>
  http.get(`${API_URL}authorization/users/${username}/status`)

// Persona Insruance and Business
export const updatePersonaInsurance = () =>
  http.put(`${API_URL}serviceProvider/providers/verificationStatus`, {
    insuranceStatus: "inquiry_completed",
  })

export const updatePersonaLicense = () =>
  http.put(`${API_URL}serviceProvider/providers/verificationStatus`, {
    licenseStatus: "inquiry_completed",
  })

export const getPersonaInsuranceStatus = (providerId) =>
  http.get(`${API_URL}serviceProvider/providers/${providerId}/verificationStatus`)

export const getAllInvoices = (providerId) =>
  http.get(`jobs/providers/${providerId}/invoices`)

export const getInvoiceById = (invoiceId) => http.get(`jobs/invoices/${invoiceId}`)

export const updateInvoiceById = (invoiceId, data) =>
  http.put(`jobs/invoices/${invoiceId}`, data)

export const submitInvoice = (invoiceId) =>
  http.patch(`jobs/invoices/${invoiceId}/status`, {
    status: "tiriosReview",
  })

export const requestBackgroundCheck = (providerId, memberId) =>
  http.post(
    `${API_URL}serviceProvider/providers/${providerId}/members/${memberId}/notifyBGcheckReq`,
    {
      data: {
        success: true,
      },
    }
  )
