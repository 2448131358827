import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { OnboardingCard } from "../../../../components/cards"
import Toolbar from "../../../../components/Toolbar"
import { sendVerification } from "../../../../actions/user"
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/helper"
import Persona from "persona"

import SpinLoader from "../../../../components/SpinLoader"
import {
  CARD_STATUS,
  APPROVED,
  IDENTITY_TEMPLATE_ID,
  BUSINESS_LICENSE_TEMPLATE_ID,
  BUSINESS_INSURANCE_TEMPLATE_ID,
  ENVIRONMENTS,
} from "../../../../constants/constants"
import Static from "../../../../static.json"
import { getUserName, getProviderId, getMemberId } from "../../../../utils/auth"
import {
  updatePersonaIdentity,
  getPersonaIdentityStatus,
  updatePersonaInsurance,
  updatePersonaLicense,
  getPersonaInsuranceStatus,
  requestBackgroundCheck,
} from "../../../../services/api"

const ProviderOnboarding = ({ sendVerification }) => {
  const {
    dashboard: {
      onboarding: {
        pagetitle,
        title,
        identitytitle,
        identitydescription,
        identitylabel,
        businesslicensetitle,
        businesslicensedescription,
        businesslicenselabel,
        businessinsurancetitle,
        businessinsurancedescription,
        businessinsurancelabel,
        backgroundtitle,
        backgrounddescription,
        backgroundlabel,
        successnotification,
      },
    },
  } = Static

  const [identityStatus, setIdentityStatus] = useState("")
  const [tradeStatus, setTradeStatus] = useState("")
  const [insuranceStatus, setInsuranceStatus] = useState("")
  const [backgroundCheck, setBackgroundCheck] = useState(false)

  useEffect(() => {
    document.title = pagetitle
  }, [])

  const reloadPage = () => {
    setTimeout(() => {
      location.reload()
    }, 100)
  }

  // console.log("USERNAME: ", getUserName())

  const getPersonaStatus = async () => {
    try {
      const identityRes = await getPersonaIdentityStatus(getUserName())
      const identityData = identityRes.data.data.status
      console.log("IDENTITY RES: ", identityData)
      setIdentityStatus(identityData)
    } catch (err) {
      console.log(err)
    }
  }
  const getPersonaLicenseAndinsuranceStatus = async () => {
    try {
      const licenseRes = await getPersonaInsuranceStatus(getProviderId())
      // console.log("Lincese  and Insurance Res: ", licenseRes)
      const trade = licenseRes.data.data.licenseStatus
      const insurance = licenseRes.data.data.insuranceStatus
      setTradeStatus(trade)
      setInsuranceStatus(insurance)
      console.log("TRADE STATUS: ", trade)
      console.log("INSURANCE STATUS: ", insurance)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getPersonaStatus()
    getPersonaLicenseAndinsuranceStatus()
  }, [])

  useEffect(() => {
    if (
      identityStatus === APPROVED &&
      tradeStatus === APPROVED &&
      insuranceStatus === APPROVED
    ) {
      setBackgroundCheck(true)
    }
  }, [identityStatus, tradeStatus, insuranceStatus])

  const onIdentifyHandler = () => {
    const client = new Persona.Client({
      templateId: IDENTITY_TEMPLATE_ID,
      referenceId: getUserName(),
      environment: ENVIRONMENTS["production"],
      onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        ;(async () => {
          try {
            const res = await updatePersonaIdentity(getUserName())
            console.log(res)
            reloadPage()
            return res
          } catch (err) {
            console.log(err)
          }
        })()
        console.log("onComplete")
      },
      onCancel: ({ inquiryId, sessionToken }) => console.log("onCancel"),
      onError: (error) => console.log(error),
    })
  }

  const onBusinessLicenseHandler = () => {
    const client = new Persona.Client({
      templateId: BUSINESS_LICENSE_TEMPLATE_ID,
      referenceId: getUserName(),
      environment: ENVIRONMENTS["production"],
      onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        ;(async () => {
          try {
            const res = await updatePersonaLicense(getUserName())
            console.log(res)
            reloadPage()
            return res
          } catch (err) {
            console.log(err)
          }
        })()
        console.log("onComplete")
      },
      onCancel: ({ inquiryId, sessionToken }) => console.log("onCancel"),
      onError: (error) => console.log(error),
    })
  }

  const onBusinessInsuranceHandler = () => {
    const client = new Persona.Client({
      templateId: BUSINESS_INSURANCE_TEMPLATE_ID,
      referenceId: getUserName(),
      environment: ENVIRONMENTS["production"],
      onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        ;(async () => {
          try {
            const res = await updatePersonaInsurance(getUserName())
            console.log(res)
            reloadPage()
            return res
          } catch (err) {
            console.log(err)
          }
        })()
        console.log("onComplete")
      },
      onCancel: ({ inquiryId, sessionToken }) => console.log("onCancel"),
      onError: (error) => console.log(error),
    })
  }

  const onCompleteCheckHandler = async () => {
    console.log("[Clicked Complete Background Check Send link]")
    try {
      await requestBackgroundCheck(getProviderId(), getMemberId())
      showSuccessNotification(successnotification)
    } catch (err) {
      console.log(err)
      showErrorNotification(err)
    }
  }
  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <h3 className="text-gray-600 text-xl">{title}</h3>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium">{identitytitle}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          <OnboardingCard
            onClick={onIdentifyHandler}
            description={identitydescription}
            label={identitylabel}
            disabled={identityStatus !== CARD_STATUS["not_started_status"] && true}
          />
          {identityStatus && (
            <span className="mt-2 sm:mt-0 mr-0 sm:ml-36 px-6 py-4 inline-flex text-base leading-5 font-semibold rounded-full bg-gray-200 text-gray-700">
              {CARD_STATUS[identityStatus]}
            </span>
          )}
        </div>
      </div>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium">{businesslicensetitle}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          <OnboardingCard
            onClick={onBusinessLicenseHandler}
            description={businesslicensedescription}
            label={businesslicenselabel}
            disabled={tradeStatus !== CARD_STATUS["not_started_status"] && true}
          />
          {tradeStatus && (
            <span className="mt-2 sm:mt-0 mr-0 sm:ml-36 px-6 py-4 inline-flex text-base leading-5 font-semibold rounded-full bg-gray-200 text-gray-700">
              {CARD_STATUS[tradeStatus]}
            </span>
          )}
        </div>
      </div>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium">{businessinsurancetitle}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          <OnboardingCard
            onClick={onBusinessInsuranceHandler}
            description={businessinsurancedescription}
            label={businessinsurancelabel}
            disabled={insuranceStatus !== CARD_STATUS["not_started_status"] && true}
          />
          {insuranceStatus && (
            <span className="mt-2 sm:mt-0 mr-0 sm:ml-36 px-6 py-4 inline-flex text-base leading-5 font-semibold rounded-full bg-gray-200 text-gray-700">
              {CARD_STATUS[insuranceStatus]}
            </span>
          )}
        </div>
      </div>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium">{backgroundtitle}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2 relative">
          {!backgroundCheck ? (
            <OnboardingCard
              description={backgrounddescription}
              label={backgroundlabel}
              disabled={true}
            />
          ) : (
            <OnboardingCard
              onClick={onCompleteCheckHandler}
              description={backgrounddescription}
              label={backgroundlabel}
            />
          )}
          {/* {backgroundCheck && (
            <span className="mt-2 sm:mt-0 mr-0 sm:ml-36 px-6 py-4 inline-flex text-base leading-5 font-semibold rounded-full bg-gray-200 text-gray-700">
              {backgroundCheck}
            </span>
          )} */}
        </div>
      </div>

      <Toolbar />
    </div>
  )
}

ProviderOnboarding.propTypes = {
  sendVerification: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    members: state.members,
    jobOffers: state.job.jobOffers,
  }
}

const mapDispatchToProps = {
  sendVerification,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderOnboarding)
