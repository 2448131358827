import { React, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Field, Form } from "react-final-form"
import LogoImage from "../../../assets/logo.svg"
import { Link, useLocation, useHistory } from "react-router-dom"
import { Button } from "../../../components/buttons"
import { TextInputField } from "../../../components/inputs"
import BetterImage from "../../../assets/Better.png"
import SignUpMainImage from "../../../assets/SignUp.png"
import { forgotPassword } from "../../../actions/user"
import Static from "../../../static.json"
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/helper"
import {
  emailValidationPattern,
  phoneNumberValidationPattern,
  number,
} from "../../../constants/constants"
import SplashPage from "../SignIn/SplashPage"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ForgotPasswordPage = ({ forgotPassword }) => {
  const {
    signup: {
      index: {
        subtitle2,
        subtitle3,
        subtitle4,
        successmessageforgotpassword,
        description1,
        errormessageforgotpassword,
        description2,
        description3,
        dividerdescription,
        loginbuttontext,
        input: { forgotpasswordplaceholder },
        validation: { emailvalidation, phonenumbervalidation },
      },
    },
  } = Static
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    document.title = "Tirios Service Provider - Forgot Password"
  }, [])
  let query = useQuery()
  const FormValidation = (values) => {
    const errors = {}
    if (number.test(values.email)) {
      if (!values.email || !phoneNumberValidationPattern.test(values.email)) {
        errors.email = phonenumbervalidation
      }
    } else {
      if (!values.email || !emailValidationPattern.test(values.email)) {
        errors.email = emailvalidation
      }
    }
    return errors
  }
  const onSubmit = (values) => {
    const { email } = values
    //let data = { username: query.get("username"), password: password }
    let data = number.test(values.email)
      ? { phoneNumber: `+1${values.email}` }
      : { email: email }
    setLoading(true)
    forgotPassword(data)
      .then(() => {
        setLoading(false)
        showSuccessNotification(successmessageforgotpassword)
        history.push("/forgot-password?success=true")
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error, errormessageforgotpassword)
        console.log(error)
      })
  }
  return (
    <div className="flex min-h-screen mx-auto">
      <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full" style={{ maxWidth: 370 }}>
          <div>
            <img className="h-12 w-auto m-auto" src={LogoImage} alt="Workflow" />
            <h4 className="mt-6 text-2xl font-regular text-gray-900 text-center">
              Invites-Only Network for
            </h4>
            <h4 className="text-2xl font-regular text-gray-900 text-center">
              Service Providers
            </h4>
          </div>

          <div className="mt-8">
            {query.get("success") && (
              <section aria-label="forgot-password-success">
                <p className="mb-2">
                  If an account exists with this email/ mobile number, you will
                  receive a link to reset your password. Please also check your spam
                  folder.
                </p>

                <div className="mt-5 text-center">
                  <Link
                    to="/signin"
                    className="inline-flex justify-center py-3 px-4 text-lg w-60 rounded-md shadow-md font-medium text-white bg-primary hover:opacity-70"
                  >
                    Login
                  </Link>
                </div>
              </section>
            )}

            {!query.get("success") && (
              <div className="mt-6">
                <div>
                  <h2 className="text-xl font-medium text-gray-700">
                    Forgot Password?
                  </h2>
                </div>
                <Form
                  onSubmit={onSubmit}
                  validate={FormValidation}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="space-y-6 mt-3">
                      <Field
                        type="text"
                        name="email"
                        placeholder={forgotpasswordplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      <Button
                        loading={loading}
                        type="submit"
                        title="Continue"
                        className="flex justify-center py-3 px-4 w-full rounded-md shadow-md font-semibold text-white bg-primary hover:opacity-70"
                      />
                    </form>
                  )}
                />
                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      {dividerdescription}
                    </span>
                  </div>
                </div>
                <div className="mt-2 text-center">
                  <Link
                    to="/signin"
                    className="font-medium text-gray-600 hover:text-gray-500 text-right underline"
                  >
                    {loginbuttontext}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <SplashPage />
      {/* <div
        className="hidden lg:flex flex-col relative w-0 w-1/2 flex-1 justify-center"
        style={{
          background: "linear-gradient(109.83deg, #F1BF00 4.84%, #FFE924 98.74%)",
        }}
      >
        <div className="flex justify-center mt-8">
          <h2 className="text-5xl text-center mb-4 relative">
            {subtitle2} <b>{subtitle3}</b> {subtitle4}
            <img
              className="w-auto max-w-full absolute -bottom-8 left-24"
              src={BetterImage}
              alt="Workflow"
            />
          </h2>
        </div>
        <div className="flex justify-center mt-12">
          <img className="w-auto max-w-full" src={SignUpMainImage} alt="Workflow" />
        </div>
        <div className="flex flex-col justify-center p-12 w-full mx-auto">
          <p className="text-xl font-regular whitespace-pre-wrap text-center">
            {description1}
          </p>
          <p className="text-xl font-regular whitespace-pre-wrap text-center">
            {description2}
          </p>
          <p className="text-xl font-semibold whitespace-pre-wrap text-center mt-8">
            {description3}
          </p>
        </div>
      </div> */}
    </div>
  )
}

ForgotPasswordPage.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  forgotPassword,
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
