import {
  UPDATE_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS,
  CREATE_LINKTOKEN,
} from "./types"
import {
  getPaymentDetails,
  updatePaymentDetails,
  createLinkToken,
  getBankInfo,
} from "../services/api"
import { getProviderId } from "../utils/auth"

export const getCreateToken = () => async (dispatch) => {
  try {
    const check = {
      client_user_id: "12345678",
    }
    const res = await createLinkToken(check)

    dispatch({
      type: CREATE_LINKTOKEN,
      payload: res.data.link_token,
    })

    return Promise.resolve(res.data.link_token)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getPaymentInfo = (providerId) => async (dispatch) => {
  try {
    const res = await getPaymentDetails(providerId)

    dispatch({
      type: GET_PAYMENT_DETAILS,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const updatePaymentInfo = (data) => async (dispatch) => {
  try {
    data["providerId"] = getProviderId()
    const res = await updatePaymentDetails(data)
    dispatch({
      type: UPDATE_PAYMENT_DETAILS,
      payload: res.data.data,
    })
    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getBankDetails = async () => {
  try {
    const res = await getBankInfo(getProviderId())
    // const res = await getBankInfo(337)

    return res.data
  } catch (err) {
    // console.log(err.response)
    return Promise.reject(err)
  }
}
