import React from "react"
import RegistrationLine from "../../../assets/registration-line-2.png"
import Create from "../../../assets/create-circle-2.png"
import Verify from "../../../assets/verify-circle-2.png"
import Arrow from "../../../assets/arrow-2.png"
import Smile from "../../../assets/smile-circle-2.png"
import CricleBg from "../../../assets/circle-bg-2.png"
import CustomerService from "../../../assets/customer-service.png"
const textColor = { color: "rgba(0, 0, 0, 1)" }
const SplashPage = () => {
  return (
    <div
      className="hidden lg:flex flex-col relative w-0 w-1/2 flex-1 to-blue-550 items-center justify-center"
      style={{
        background: "linear-gradient(109.83deg, #F1BF00 4.84%, #FFE924 98.74%)",
      }}
    >
      <div
        className="flex flex-col justify-center items-center"
        style={{ marginBottom: "120px" }}
      >
        <h2 className="text-4xl font-bold text-center font-serif" style={textColor}>
          Setup in 3 simple steps
        </h2>
        <img src={RegistrationLine} alt="Workflow" style={{ width: "200px" }} />
        <p className="text-2xl text-center w-3/4 mt-10">
          Tirios is a property management platform for single-family rental homes. We
          are currently accepting service provider applications. Join us today!
        </p>
      </div>
      <div
        className="mt-100 flex justify-between w-auto relative"
        // md:w-full max-w-screen-lg
        style={{ width: "99%", maxWidth: "850px" }}
      >
        <div
          className="flex justify-center flex-col items-center md:w-1/3 p-2"
          // style={{ width: "33%" }}
        >
          <div
            className="relative flex justify-center items-center"
            style={{ height: "100px", width: "100px" }}
          >
            <img
              src={CricleBg}
              alt="img"
              className="absolute z-10"
              style={{ height: "100%", width: "100%" }}
            />
            <img src={Create} alt="Create" className="z-10 h-7 w-7" />
          </div>
          <h2
            className="text-center text-sm tracking-widest font-bold uppercase mt-8 mb-2"
            style={textColor}
          >
            Create Your Account
          </h2>
          <p className="text-center text-base" style={textColor}>
            by registering your trade, determining your service area, and setting
            your rates.
          </p>
          <img
            src={Arrow}
            alt="->"
            className="absolute z-15 top-12 left-36 md:hidden 2xl:flex"
          />
        </div>
        <div
          className="flex justify-center flex-col items-center md:w-1/3 relative p-2"
          // style={{ width: "33%" }}
        >
          <div
            className="relative flex justify-center items-center top-0"
            style={{ height: "100px", width: "100px" }}
          >
            <img
              src={CricleBg}
              alt="img"
              className="absolute z-10"
              style={{ height: "100%", width: "100%" }}
            />
            <img src={Verify} alt="Create" className="z-10 h-7 w-7" />
          </div>
          <h2
            className="text-center text-sm tracking-widest font-bold uppercase mt-8 mb-2"
            style={textColor}
          >
            Verify Your Business
          </h2>
          <p className="text-center text-base" style={textColor}>
            by providing your licence, insurance and completing a background check.
          </p>
          <img
            src={Arrow}
            alt="->"
            className="absolute z-15 top-12 left-36 md:hidden 2xl:flex"
          />
        </div>
        <div
          className="flex justify-center flex-col items-center md:w-1/3 p-2"
          // style={{ width: "33%" }}
        >
          <div
            className="relative flex justify-center items-center"
            style={{ height: "100px", width: "100px" }}
          >
            <img
              src={CricleBg}
              alt="img"
              className="absolute"
              style={{ height: "100%", width: "100%" }}
            />
            <img src={Smile} alt="Create" className="z-10 h-7 w-7" />
          </div>
          <h2
            className="text-center text-sm tracking-widest font-bold uppercase mt-8 mb-2"
            style={textColor}
          >
            Recieve Work Orders
          </h2>
          <p className="text-center text-base" style={textColor}>
            straight to the App - no bidding, no tedious administration, no
            headaches.
          </p>
        </div>
      </div>
      <div
        className="flex flex-col align-center text-center p-5 mt-12"
        onClick={() =>
          window.open(
            "https://calendly.com/members_tirios/consultation-call?month=2021-12",
            "_blank"
          )
        }
      >
        <p
          className="text-center text-md tracking-widest font-bold mt-8 mb-2"
          style={textColor}
        >
          Questions?
        </p>
        <p className="text-md"> Schedule a time to chat with us </p>
        <div className="flex items-center border-2 border-black rounded-2xl p-2 mt-4 cursor-pointer">
          <img src={CustomerService} alt="#" className="mr-2" />
          <h2 className="font-bold"> Get a free consultation </h2>
        </div>
      </div>
    </div>
  )
}

export default SplashPage
