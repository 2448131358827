import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Field, Form } from "react-final-form"
import { Link, useHistory } from "react-router-dom"
import { connect } from "react-redux"

import { signIn } from "../../../actions/user"
import LogoImage from "../../../assets/logo.svg"

import SplashPage from "./SplashPage"

import { Button } from "../../../components/buttons"
import { CheckboxField, TextInputField } from "../../../components/inputs"

import {
  emailValidationPattern,
  passwordValidationPattern,
  gaTrackingId,
  fbPixelTrackingIdOne,
  fbPixel,
  ga,
} from "../../../constants/constants"
import Static from "../../../static.json"
import {
  showErrorNotification,
  showSuccessNotification,
  analyticsPageView,
} from "../../../utils/helper"

const ProviderSignIn = ({ signIn }) => {
  const {
    signin: {
      pagetitle,
      subtitle1,
      subtitleSignIn,
      emailplaceholder,
      emailvalidation,
      passwordplaceholder,
      passwordvalidation,
      forgotpasswordtitle,
      termvalidation,
      termlabel,
      termlinklabel,
      termconnectpolicylabel,
      policylinklabel,
      buttontitle,
      dividerdescription,
      signupbuttontext,
      errormessage,
      successmessage,
    },
  } = Static
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = pagetitle

    analyticsPageView(ga, history.location.pathname, gaTrackingId, null, null)
    analyticsPageView(
      fbPixel,
      history.location.pathname,
      fbPixelTrackingIdOne,
      "signin",
      { page: history.location.pathname }
    )
  }, [])

  const FormValidation = (values) => {
    const errors = {}
    if (!values.email) {
      errors.email = emailvalidation
    }
    // if (!values.password || !passwordValidationPattern.test(values.password)) {
    //   errors.password = passwordvalidation
    // }
    if (!values.password || values.password.length < 1) {
      errors.password = passwordvalidation
    }

    // if (!agreeTerm) {
    //   errors.agreeTerm = termvalidation
    // }
    // if (!values.agreeTerm) {
    //   errors.agreeTerm = termvalidation
    // }
    return errors
  }

  const onSubmit = (values) => {
    console.log("on sumit", values)
    console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL)
    const { email, password, agreeTerm } = values
    setLoading(true)
    signIn(email, password)
      .then((user) => {
        localStorage.setItem("oldPassword", password)
        setLoading(false)
        console.log("USER", user)
        // if (user.data.details.roles[0] === "PENDING_OWNER")
        if (user.data.details.roles.includes("PENDING_OWNER"))
          history.push("/provider/dashboard/onboarding")
        else history.push("/provider/dashboard/jobs")
      })
      .catch((error) => {
        setLoading(false)
        showErrorNotification(error, errormessage)
      })
  }

  return (
    <div className="flex min-h-screen mx-auto">
      <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-500">
          <div>
            <img className="h-12 w-auto m-auto" src={LogoImage} alt="Workflow" />
            <h4 className="mt-6 text-2xl font-regular text-gray-900">{subtitle1}</h4>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <Form
                onSubmit={onSubmit}
                validate={FormValidation}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <Field
                      type="email"
                      name="email"
                      placeholder={emailplaceholder}
                      component={TextInputField}
                      widthFull
                    />
                    <Field
                      type="password"
                      name="password"
                      placeholder={passwordplaceholder}
                      component={TextInputField}
                      widthFull
                    />
                    <div className="mt-2 text-left">
                      <Link
                        to="/forgot-password"
                        className="font-medium text-gray-600 hover:text-gray-500 text-right underline"
                      >
                        {forgotpasswordtitle}
                      </Link>
                    </div>
                    {/* <div className="flex items-center">
                      <Field
                        type="input"
                        name="agreeTerm"
                        component={CheckboxField}
                        label={
                          <>
                            {termlabel}
                            <Link
                              target="_blank"
                              to="/terms-of-service"
                              className="ml-2 mr-2 font-medium text-sm text-gray-600 hover:text-gray-500 text-right underline"
                            >
                              {termlinklabel}
                            </Link>
                            {termconnectpolicylabel}
                            <Link
                              target="_blank"
                              to="/privacy-policy"
                              className="ml-2 font-medium text-sm text-gray-600 hover:text-gray-500 text-right underline"
                            >
                              {policylinklabel}
                            </Link>
                          </>
                        }
                      />
                    </div> */}
                    <Button loading={loading} type="submit" title={buttontitle} />
                  </form>
                )}
              />
              <div className="mt-6 relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    {dividerdescription}
                  </span>
                </div>
              </div>
              <div className="mt-2 text-center">
                <Link
                  to="/signup"
                  className="font-medium text-gray-600 hover:text-gray-500 text-right underline"
                >
                  {signupbuttontext}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SplashPage />
    </div>
  )
}

ProviderSignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  signIn,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSignIn)
