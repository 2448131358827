import PropTypes from "prop-types"
import { Button } from "../../components/buttons"
import { CheckCircleIcon } from "@heroicons/react/solid"
const ConfirmationPopup = ({ heading, body, actionLabel }) => {
  return (
    <div className="p-4 bg-white flex flex-col justify-center items-center">
      <CheckCircleIcon className="h-12 w-12 text-primary" aria-hidden="true" />
      <h2 className="text-black font-bold mb-1">{heading}</h2>
      <p className="text-black text-center mb-2">{body}</p>
      <Button title={actionLabel} />
    </div>
  )
}
ConfirmationPopup.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  actionLabel: PropTypes.string,
}
ConfirmationPopup.defaultProps = {
  heading: "Success!",
  body: "saved",
  actionLabel: "OK",
}
export default ConfirmationPopup
