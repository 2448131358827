import {
  GET_MEMBERS,
  CREATE_MEMBER,
  DISABLE_MEMBER,
  ENABLE_MEMBER,
} from "../actions/types"

const initialState = []

function memberReducer(members = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_MEMBERS:
      return payload
    case CREATE_MEMBER:
      return [...members, payload]
    case DISABLE_MEMBER:
      return members.map(({ memberId, ...rest }) =>
        memberId === payload
          ? { ...rest, memberId, active: false }
          : { ...rest, memberId }
      )
    case ENABLE_MEMBER:
      return members.map(({ memberId, ...rest }) =>
        memberId === payload
          ? { ...rest, memberId, active: true }
          : { ...rest, memberId }
      )
    default:
      return members
  }
}

export default memberReducer
