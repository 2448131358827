import { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import Layout from "../../../components/layout"
import { getAllRoles } from "../../../actions/role"
import { getJobOffers, getJobs, getJobsByfilterName } from "../../../actions/job"
import { getAllCategories } from "../../../actions/categories"
import { getAllMembers } from "../../../actions/member"

import Onboarding from "./Onboarding"
import Members from "./Members"
import Jobs from "./Jobs"
import Dashboard from "./Dashboard"
import Profile from "./Profile"
import Payments from "./Payments"

import { showErrorNotification } from "../../../utils/helper"
import Settings from "./Settings"
import ChangePasswordSettings from "./Settings/ChangePassword"

const ProviderDashboard = ({
  getAllRoles,
  getJobOffers,
  getAllMembers,
  getJobs,
  getJobsByfilterName,
  getAllCategories,
}) => {
  const { path } = useRouteMatch()
  useEffect(() => {
    let x = localStorage.getItem("auth")
    x = JSON.parse(x).data.details.roles.toString()
    const getRoleFromAuth = x.split(",")
    if (!getRoleFromAuth.includes("PENDING_OWNER")) {
      // if (x !== "PENDING_OWNER") {
      getAllRoles().catch((error) => {
        showErrorNotification(error)
      })
      getJobOffers().catch((error) => {
        showErrorNotification(error)
      })
      getAllMembers().catch((error) => {
        showErrorNotification(error)
      })
      getJobs().catch((error) => {
        showErrorNotification(error)
      })
      getAllCategories().catch((error) => {
        showErrorNotification(error)
      })
      getJobsByfilterName("ACCEPTED").catch((error) => {
        showErrorNotification(error)
      })
    }
  }, [])

  return (
    <Layout>
      <Switch>
        <Route path={`${path}/onboarding`}>
          <Onboarding />
        </Route>
        <Route path={`${path}/members`}>
          <Members />
        </Route>
        <Route path={`${path}/jobs`}>
          <Jobs />
        </Route>
        <Route path={`${path}/profile`}>
          <Profile />
        </Route>
        <Route path={`${path}/payments`}>
          <Payments />
        </Route>
        <Route path={`${path}/settings`}>
          <Settings />
        </Route>
        <Route path={`${path}/changePassword`}>
          <ChangePasswordSettings />
        </Route>
        <Route path={`${path}/home`}>
          <Dashboard />
        </Route>
        <Redirect from={`${path}/`} to={`${path}/home`} />
      </Switch>
    </Layout>
  )
}

ProviderDashboard.propTypes = {
  // getAllRoles: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  getJobOffers: PropTypes.func.isRequired,
  getAllMembers: PropTypes.func.isRequired,
  getJobs: PropTypes.func.isRequired,
  getJobsByfilterName: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles,
  }
}

const mapDispatchToProps = {
  getAllRoles,
  getJobOffers,
  getAllMembers,
  getJobs,
  getJobsByfilterName,
  getAllCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDashboard)
