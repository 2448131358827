import { useState, useEffect, useRef } from "react"
import { SearchIcon } from "@heroicons/react/outline"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { JobCard } from "../../../../components/cards"
import {
  AcceptJobPopupModal,
  DeclineJobPopupModal,
  ConfirmationPopup,
} from "../../../../components/popup"
import Table from "./Table"
import { toast } from "react-toastify"
import { Switch } from "@headlessui/react"

import {
  memberAssignJob,
  acceptJobOfferAndAssignToMember,
  fullDeclineJobOffer,
  acceptJobOffer,
  getJobsByfilterName,
  getJobs,
  getJobOffers,
  getAllMembersByJobs,
} from "../../../../actions/job"
import {
  jobsTableColumns,
  scheduled,
  toastSuccessOptions,
  jobStatusTypes,
} from "../../../../constants/constants"
import { classNames } from "../../../../utils/ClassNames"
import { showErrorNotification } from "../../../../utils/helper"
import Static from "../../../../static.json"
import { getAllJobsForProvider } from "../../../../services/api"
import { getProviderId } from "../../../../utils/auth"

const ProviderJobs = ({
  jobOffers,
  members,
  jobs,
  memberAssignJob,
  acceptJobOfferAndAssignToMember,
  fullDeclineJobOffer,
  acceptJobOffer,
  getJobsByfilterName,
  getJobs,
  getJobOffers,
  jobsByType,
  getAllMembersByJobs,
  membersByJob,
}) => {
  const {
    dashboard: {
      jobs: {
        pagetitle,
        title,
        subtitle,
        scheduledjobtabtitle,
        incompletejobtabtitle,
        completedjobtabtitle,
        alljobtabtitle,
        searchinputplaceholder,
        successfulReassign,
      },
    },
  } = Static

  let current = null
  let next = null
  const sortedJobOffers = jobOffers.sort((a, b) => a.endTime - b.endTime)
  const openJobOffers = sortedJobOffers.filter(
    ({ status: { description } }) => description === "OPEN"
  )

  const { CHECKED_OUT, INVOICE_SENT_FOR_REVIEW } = jobStatusTypes

  // const checkExpiry = (expiryTime) => {
  //   const toMoment = moment(expiryTime)
  //   const fromMoment = moment(new Date())
  //   return toMoment.diff(fromMoment, "minutes")
  // }
  if (openJobOffers.length > 0) {
    current = openJobOffers[0]
  }

  const sortedJobs = jobsByType
    .sort((a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime))
    .reverse()
  const upComingJob = jobs
    .sort((a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime))
    .filter((x) => x.status.description === "ACCEPTED")
  next = upComingJob.length > 0 ? upComingJob.reverse()[0] : null

  const filterTabs = [
    { id: 0, title: "Scheduled Jobs", value: "ACCEPTED", isToggled: true },
    { id: 1, title: "Jobs in Progress", value: "CHECKED_IN", isToggled: true },
    {
      id: 2,
      title: "Invoices to be completed",
      value: "CHECKED_OUT",
      isToggled: true,
    },
    { id: 3, title: "Completed Jobs", value: "CLOSED", isToggled: true },
    { id: 4, title: "Troubleshoot Jobs", value: "TROUBLESHOOT", isToggled: true },
  ]

  const [currentTab, setCurrentTab] = useState(scheduledjobtabtitle)
  const [acceptModalOpen, setAcceptModalOpen] = useState(false)
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  const [acceptJobId, setAcceptJobId] = useState()
  const [declineJobId, setDeclineJobId] = useState()
  const [acceptJobOfferLoading, setAcceptJobOfferLoading] = useState(false)
  const [declineJobOfferLoading, setDeclineJobOfferLoading] = useState(false)
  const [assignJobOfferLoading, setAssignJobOfferLoading] = useState(false)
  const [jobAction, setJobActions] = useState(scheduled)
  const [filteredJobsList, setFilterdJobsList] = useState()
  const [jobsFromApi, setJobsFromApi] = useState()
  const [jobTabs, setJobTabs] = useState(filterTabs)

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()

  useEffect(() => {
    document.title = pagetitle
  }, [])

  useEffect(() => {
    const getAllJobs = async () => {
      try {
        const response = await getAllJobsForProvider(getProviderId())
        console.log("JOB RESPONSE:", response.data)
        setFilterdJobsList(response.data.data)
        setJobsFromApi(response.data.data)
      } catch (err) {
        console.log(err)
      }
    }
    getAllJobs()
  }, [setFilterdJobsList, setJobsFromApi])

  // If all jobJobs are checked to true -> Default back to jobs []
  const checkIsToggled = () => {
    let count = 0
    const filterToggle = [...jobTabs]
    for (let i = 0; i < filterToggle.length; i++) {
      if (filterToggle[i].isToggled) {
        count++
      }
    }
    if (count === 6) {
      setFilterdJobsList(jobs)
    }
  }

  useEffect(() => {
    checkIsToggled()
  }, [jobTabs])

  const handleSelectFilterToggle = (index, value) => {
    const newTabStatus = [...jobTabs]
    const findIndex = newTabStatus.find((i) => i.id === index)
    findIndex.isToggled = findIndex.isToggled ? false : true
    setJobTabs(newTabStatus)

    if (findIndex.isToggled === false) {
      removeFromJobsList(value)
    }
    if (findIndex.isToggled === true) {
      addToJobsList(value)
    }
  }

  const removeFromJobsList = (value) => {
    const currentList = [...filteredJobsList]
    const newList = []

    if (value === CHECKED_OUT) {
      currentList.forEach((job) => {
        if (
          job.status.description !== value &&
          job.status.description !== INVOICE_SENT_FOR_REVIEW
        ) {
          newList.push(job)
        }
      })
    } else {
      newList.push(...currentList.filter((obj) => obj.status.description !== value))
    }
    setFilterdJobsList(newList)
  }

  const addToJobsList = (value) => {
    const jobsArr = [...jobsFromApi]
    const oldJobsArr = [...filteredJobsList]
    const newList = []

    if (value === CHECKED_OUT) {
      jobsArr.forEach((job) => {
        if (
          job.status.description === value ||
          job.status.description === INVOICE_SENT_FOR_REVIEW
        ) {
          newList.push(job)
        }
      })
    } else {
      newList.push(...jobsArr.filter((obj) => obj.status.description === value))
    }

    if (oldJobsArr.length === 0) {
      setFilterdJobsList(newList)
    } else if (oldJobsArr.length >= 1) {
      for (let jobs of newList) {
        oldJobsArr.push(jobs)
        setFilterdJobsList(oldJobsArr)
      }
    }
  }

  const onAcceptHandler = (jobId) => {
    console.log("[Clicked onAccept]")
    setAcceptJobId(jobId)
    setAcceptModalOpen(true)
  }

  const onAcceptSubmitHandler = (memberId) => {
    setAcceptJobOfferLoading(true)
    acceptJobOffer(memberId, acceptJobId)
      .then(() => {
        getJobOffers()
        getJobsByfilterName(jobAction)
        setAcceptJobOfferLoading(false)
        setAcceptModalOpen(false)
      })
      .catch((err) => {
        setAcceptJobOfferLoading(false)
        showErrorNotification(err)
      })
  }

  const onDeclineHandler = (jobId) => {
    console.log("[Clicked onDecline]")
    setDeclineJobId(jobId)
    setDeclineModalOpen(true)
  }

  const onDeclineSubmitHandler = (reason) => {
    setDeclineJobOfferLoading(true)
    fullDeclineJobOffer(declineJobId, reason)
      .then(() => {
        getJobOffers()
        setDeclineJobOfferLoading(false)
        setDeclineModalOpen(false)
      })
      .catch((err) => {
        showErrorNotification(err)
        setDeclineJobOfferLoading(false)
      })
  }

  const handleAssignJob = (memberId, jobId, newMemberId) => {
    setAssignJobOfferLoading(true)
    memberAssignJob(memberId, jobId, newMemberId)
      .then(() => {
        getJobsByfilterName(jobAction)
        setAssignJobOfferLoading(false)
        toast(
          <ConfirmationPopup
            heading="Success!"
            body={successfulReassign}
            actionLabel="OK"
          />,
          {
            ...toastSuccessOptions,
            style: { backgroundColor: "#ffffff" },
          }
        )
      })
      .catch((err) => {
        showErrorNotification(err)
        setAssignJobOfferLoading(false)
      })
  }
  const onSelectTab = (tab) => {
    setCurrentTab(tab.title)
    getJobsByfilterName(tab.value)
    setJobActions(tab.value)
  }
  const searchInTable = (value) => {}
  const getMembers = async (jobType, role) => {
    return await getAllMembersByJobs(jobType, role)
  }

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <AcceptJobPopupModal
        onSubmit={onAcceptSubmitHandler}
        open={acceptModalOpen}
        setOpen={setAcceptModalOpen}
        members={members.filter(
          ({ categories, roles, active }) =>
            categories &&
            current &&
            roles &&
            active &&
            roles.includes("WORKER") &&
            categories.includes(current.job.serviceCategory.description)
        )}
        loading={acceptJobOfferLoading}
      />
      <DeclineJobPopupModal
        onSubmit={onDeclineSubmitHandler}
        open={declineModalOpen}
        setOpen={setDeclineModalOpen}
        loading={declineJobOfferLoading}
      />
      <h3 className="text-gray-600 text-xl">{title}</h3>
      <div className="my-3" ref={myRef}>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          {current && (
            <JobCard
              job={current}
              onAccept={onAcceptHandler}
              onDecline={onDeclineHandler}
            />
          )}
        </div>
        <h2 className="text-gray-700 font-medium">{subtitle}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          {next && (
            <JobCard
              job={next}
              onAccept={onAcceptHandler}
              onDecline={onDeclineHandler}
              isNext
            />
          )}
        </div>
        <div className="block">
          <div className="flex flex-wrap mb-4">
            {jobTabs?.map(({ title, value, isToggled }, i) => {
              return (
                <div
                  key={i}
                  className="border border-gray p-2"
                  style={{
                    backgroundColor: "#E7EAEE",
                    borderRadius: "16px",
                    border: "1px solid rgba(0, 22, 10, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "1rem",
                    margin: "0.5rem",
                  }}
                >
                  <p
                    style={{
                      color: "#rgba(84, 86, 90, 1)",
                      marginRight: "0.5rem",
                    }}
                  >
                    {title}
                  </p>
                  <Switch
                    checked={isToggled}
                    onChange={() => handleSelectFilterToggle(i, value)}
                    className={classNames(
                      isToggled ? "bg-primary" : "bg-gray-200",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isToggled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </div>
              )
            })}
          </div>
          <div className="">
            <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-b-lg p-2 bg-white">
              {/* Search */}
              <div className="mb-2">
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder={searchinputplaceholder}
                    onChange={(event) => searchInTable(event.target.value)}
                  />
                </div>
              </div>
              <Table
                columns={jobsTableColumns}
                // data={sortedJobs}
                data={filteredJobsList}
                members={membersByJob}
                assignJob={handleAssignJob}
                getMembersList={getMembers}
                executeScroll={executeScroll}
                myRef={myRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProviderJobs.propTypes = {
  members: PropTypes.array.isRequired,
  jobOffers: PropTypes.array.isRequired,
  jobs: PropTypes.array.isRequired,
  memberAssignJob: PropTypes.func.isRequired,
  acceptJobOfferAndAssignToMember: PropTypes.func.isRequired,
  fullDeclineJobOffer: PropTypes.func.isRequired,
  acceptJobOffer: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    members: state.members,
    jobOffers: state.job.jobOffers,
    jobs: state.job.jobs,
    jobsByType: state.job.jobsByType,
    membersByJob: state.job.membersByJob,
  }
}

const mapDispatchToProps = {
  memberAssignJob,
  acceptJobOfferAndAssignToMember,
  fullDeclineJobOffer,
  acceptJobOffer,
  getJobsByfilterName,
  getJobs,
  getJobOffers,
  getAllMembersByJobs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderJobs)
