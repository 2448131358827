import React, { useEffect } from "react"
import App from "./App"
import { useHistory } from "react-router-dom"
import { envTypes } from "./constants/constants"
const { production } = envTypes

const Root = () => {
  const history = useHistory()

  useEffect(() => {
    if (process.env.REACT_APP_ENV === production) {
      return history.listen((location) => {
        console.log(`You changed the page to: ${location.pathname}`)
        window._mfq.push(["newPageView", location.pathname])
      })
    }
  }, [history])

  return <App />
}

export default Root
