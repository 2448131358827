import moment from "moment"
import { toast } from "react-toastify"
import ReactGA from "react-ga"
import ReactPixel from "react-facebook-pixel"
import { Redirect } from "react-router-dom"

import {
  toastErrorOptions,
  toastSuccessOptions,
  fbPixel,
  ga,
} from "../constants/constants"
import Static from "../static.json"
import { ConfirmationPopup } from "../components/popup"

export function CalculateDifferenceBetweenTwoDates(from, to) {
  const toMoment = moment(to)
  const fromMoment = moment(from)
  if (toMoment.diff(fromMoment, "minutes") <= 0) return `0 minutes`
  const diffInDays = toMoment.diff(fromMoment, "days")
  const diffInHours = toMoment.diff(fromMoment, "hours") % 24
  const diffInMinutes = toMoment.diff(fromMoment, "minutes") % 60
  const dayString =
    diffInDays > 0
      ? diffInDays > 1
        ? `${diffInDays} days `
        : `${diffInDays} day `
      : ""
  const hourString =
    diffInHours > 0
      ? diffInHours > 1
        ? `${diffInHours} hours `
        : `${diffInHours} hour `
      : ""
  const miniuteString =
    diffInMinutes > 0
      ? diffInMinutes > 1
        ? `${diffInMinutes} minutes`
        : `${diffInMinutes} minute`
      : ""
  return `${dayString}${hourString}${miniuteString}`
}

export const getErrorMessage = (error) => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    return error.response.data.error.message
  }
  return null
}

export const showSuccessNotification = (message) => {
  toast(<ConfirmationPopup heading="Success!" body={message} actionLabel="OK" />, {
    ...toastSuccessOptions,
    style: { backgroundColor: "#ffffff" },
  })
}

export const showErrorNotification = (error, message) => {
  const {
    global: { errormessage },
  } = Static
  toast(getErrorMessage(error) || message || errormessage, toastErrorOptions)
}

export const analyticsPageView = (type, url, id, event, data) => {
  console.log(ReactPixel)
  if (type === ga) {
    ReactGA.initialize(id)
    ReactGA.pageview(url)
  } else if (type === fbPixel) {
    const advancedMatching = null
    const options = {
      autoConfig: true,
      debug: false,
    }
    ReactPixel.init(id, advancedMatching, options)
    ReactPixel.pageView()
    ReactPixel.track(event, data)
  }
}
