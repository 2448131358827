import { GET_CATEGORIES } from "./types"
import { getCategories } from "../services/api"
import { getProviderId } from "../utils/auth"

export const getAllCategories = () => async (dispatch) => {
  try {
    const res = await getCategories(getProviderId())

    dispatch({
      type: GET_CATEGORIES,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
