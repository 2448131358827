import PropTypes from "prop-types"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { useHistory } from "react-router-dom"

import { RoundedButton } from "../../../components/buttons"
import Toolbar from "../../../components/Toolbar"

import Static from "../../../static.json"

const SuccessScreen = ({ onSubmit }) => {
  const {
    signup: {
      success: {
        description1,
        description2,
        description3,
        description4,
        description5,
        description6,
        buttontitle,
      },
    },
  } = Static
  const history = useHistory()
  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none  flex flex-col justify-between">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <CheckCircleIcon
            className="h-24 w-24 text-primary mx-auto"
            aria-hidden="true"
          />
          <h1 className="text-2xl font-semibold text-text-primary mb-4 text-center">
            {description1}
          </h1>
          <p className="text-text-primary mb-4 whitespace-pre-wrap">
            {description2}
          </p>
          <p className="text-text-primary mb-4">{description3}</p>
          <p className="text-text-primary mb-4">{description4}</p>
          <p className="text-text-primary mb-4 whitespace-pre-wrap">
            {description5}
          </p>
          <p className="text-text-primary mb-4 whitespace-pre-wrap">
            {description6}
          </p>
          <div className="flex pt-8">
            <RoundedButton
              variant="primary"
              // onClick={() => onSubmit({}, "signin")}
              onClick={() => history.push("/signin")}
              title={buttontitle}
            />
          </div>
        </div>
      </div>
      <div className="my-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <Toolbar />
        </div>
      </div>
    </main>
  )
}

SuccessScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SuccessScreen
