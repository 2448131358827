import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { classNames } from "../../../utils/ClassNames"

let autoComplete

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "us" } }
  )
  autoComplete.setFields(["address_components", "formatted_address"])
  autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery))
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace()

  if (!addressObject) return

  let address1 = ""
  let state = ""
  let city = ""
  let postcode = ""

  for (const component of addressObject.address_components) {
    const { types, long_name, short_name } = component
    const componentType = types[0]

    switch (componentType) {
      case "street_number": {
        address1 = `${long_name} ${address1}`
        break
      }

      case "route": {
        address1 += short_name
        break
      }

      case "postal_code": {
        postcode = `${long_name}${postcode}`
        break
      }
      case "locality":
        city = long_name
        break

      case "administrative_area_level_1": {
        state = short_name
        break
      }
    }
  }

  updateQuery({
    address1,
    city,
    state,
    zip: postcode,
  })
}

function SearchLocationInput({
  label,
  value,
  setValue,
  setAddress,
  type,
  name,
  required,
  error,
  widthFull,
  validate,
  className,
  placeholder,
}) {
  const autoCompleteRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      handleScriptLoad(setAddress, autoCompleteRef)
    }, 100)
  }, [])

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor="name"
          className="block text-base font-normal text-text-primary"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative">
        <input
          id={name}
          name={name}
          type={type}
          ref={autoCompleteRef}
          onChange={(event) => setValue(event.target.value)}
          placeholder={placeholder}
          value={value}
          required={required}
          className={classNames(
            "appearance-none block p-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:border-green-primary sm:text-sm",
            error
              ? "border-red-400 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-400 focus:border-red-400"
              : "",
            widthFull ? "w-full" : ""
          )}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
        )}
        {validate && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon className="h-5 w-5 text-primary" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-400" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

SearchLocationInput.propTypes = {
  widthFull: PropTypes.bool,
  validate: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

SearchLocationInput.defaultProps = {
  widthFull: false,
  validate: false,
  name: "input",
  className: "",
  label: null,
  type: "text",
  required: false,
  error: null,
  autoComplete: null,
  placeholder: "",
}

export default SearchLocationInput
