import {
  GET_JOB_OFFERS,
  ASSIGN_JOB,
  ACCEPT_JOB_OFFER,
  DECLINE_JOB_OFFER,
  GET_ALL_JOBS,
  FULL_DECLINE_JOB_OFFER,
  ACCEPT_JOB_AND_ASSIGN_MEMBER,
  GET_ALL_JOBS_TYPE,
  GET_MEMBERS_BY_JOB,
} from "./types"
import {
  getJobOffersForServiceProvider,
  assignJob,
  acceptJobOfferByMember,
  getAllJobsForProvider,
  declineJobOfferByMember,
  fullDeclineJobFromProvider,
  acceptJobAndAssignToMember,
  getJobsByFilter,
  getAllMembersByJob,
} from "../services/api"
import { getProviderId, getMemberId } from "../utils/auth"

export const acceptJobOfferAndAssignToMember =
  (memberId, jobId) => async (dispatch) => {
    try {
      const res = await acceptJobAndAssignToMember(
        getProviderId(),
        getMemberId(),
        jobId,
        { anotherMemberId: memberId }
      )
      console.log("--accept", res.data)
      dispatch({
        type: ACCEPT_JOB_AND_ASSIGN_MEMBER,
        payload: jobId,
      })

      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

export const fullDeclineJobOffer = (jobId, reason) => async (dispatch) => {
  try {
    const res = await fullDeclineJobFromProvider(
      getProviderId(),
      getMemberId(),
      jobId,
      { reason }
    )
    console.log("--decline", res.data)
    dispatch({
      type: FULL_DECLINE_JOB_OFFER,
      payload: jobId,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getJobOffers = () => async (dispatch) => {
  try {
    const res = await getJobOffersForServiceProvider(getProviderId())
    dispatch({
      type: GET_JOB_OFFERS,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getJobs = () => async (dispatch) => {
  try {
    const res = await getAllJobsForProvider(getProviderId(), getMemberId())

    // console.log("RES FROM API:", res.data)
    dispatch({
      type: GET_ALL_JOBS,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getJobsByfilterName = (filterValue) => async (dispatch) => {
  try {
    let res = null
    if (filterValue === "")
      res = await getAllJobsForProvider(getProviderId(), getMemberId())
    else res = await getJobsByFilter(getProviderId(), filterValue)
    dispatch({
      type: GET_ALL_JOBS_TYPE,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const memberAssignJob =
  (memberId, jobId, newMemberId) => async (dispatch) => {
    try {
      const res = await assignJob(getProviderId(), getMemberId(), jobId, newMemberId)

      dispatch({
        type: ASSIGN_JOB,
        payload: jobId,
      })

      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

export const acceptJobOffer = (memberId, jobId) => async (dispatch) => {
  try {
    const res = await acceptJobOfferByMember(getProviderId(), getMemberId(), jobId, {
      memberId: memberId,
    })

    dispatch({
      type: ACCEPT_JOB_OFFER,
      payload: jobId,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const declineJobOffer = (jobId, reason) => async (dispatch) => {
  try {
    const res = await declineJobOfferByMember(
      getProviderId(),
      getProviderId(),
      jobId,
      { reason }
    )

    dispatch({
      type: DECLINE_JOB_OFFER,
      payload: jobId,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
export const getAllMembersByJobs = (typeOfJob, role) => async (dispatch) => {
  try {
    const res = await getAllMembersByJob(getProviderId(), typeOfJob, role)

    dispatch({
      type: GET_MEMBERS_BY_JOB,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
