import PropTypes from "prop-types"
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { classNames } from "../../utils/ClassNames"

export const TextInputField = ({
  meta,
  autoComplete,
  maxLength,
  label,
  input,
  placeholder,
  widthFull,
  className,
  disabled,
}) => {
  const { touched, valid, error } = meta
  const errorStatus = touched && error && error
  const validStatus = touched && valid
  const { value } = input
  return (
    <TextInput
      autoComplete={autoComplete}
      label={label}
      {...input}
      placeholder={placeholder}
      value={value}
      error={errorStatus}
      maxLength={maxLength}
      validate={validStatus}
      widthFull={widthFull}
      className={className}
      disabled={disabled}
    />
  )
}

TextInputField.propTypes = {
  widthFull: PropTypes.bool,
  meta: PropTypes.object,
  autoComplete: PropTypes.string,
  maxLength: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

TextInputField.defaultProps = {
  widthFull: false,
  meta: {},
  autoComplete: null,
  label: null,
  placeholder: null,
  className: "",
  maxLength: "",
  disabled: false,
}

export const TextInput = ({
  validate,
  maxLength,
  widthFull,
  className,
  label,
  name,
  type,
  autoComplete,
  required,
  error,
  ...props
}) => {
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor="name"
          className="block text-base font-normal text-text-primary"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative">
        <input
          {...props}
          id={name}
          name={name}
          type={type}
          maxLength={maxLength}
          autoComplete={autoComplete}
          required={required}
          className={classNames(
            "appearance-none block p-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:border-green-primary sm:text-sm",
            error
              ? "border-red-400 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-400 focus:border-red-400"
              : "",
            widthFull ? "w-full" : ""
          )}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
        )}
        {validate && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon className="h-5 w-5 text-primary" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-400" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

TextInput.propTypes = {
  widthFull: PropTypes.bool,
  maxLength: PropTypes.string,
  validate: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
}

TextInput.defaultProps = {
  widthFull: false,
  maxLength: null,
  validate: false,
  name: "input",
  className: "",
  label: null,
  type: "text",
  required: false,
  error: null,
  autoComplete: null,
}
