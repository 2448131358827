import PropTypes from "prop-types"

export const CheckBoxFieldMember = ({
  // name,
  onChange,
  onClick,
  input,
  label,
  checked,
  value,
}) => {
  // const { checked, value, name } = input

  return (
    <Checkbox
      label={label}
      {...input}
      checked={checked}
      // name={name}
      onChange={onChange}
    />
  )
}

export const CheckboxField = ({ meta, label, input, widthFull }) => {
  const { touched, valid, error } = meta
  const errorStatus = touched && error && error
  const validStatus = touched && valid
  const { checked, value, name } = input

  return (
    <Checkbox
      label={label}
      {...input}
      checked={checked || value}
      error={errorStatus}
      validate={validStatus}
      widthFull={widthFull}
      name={name}
    />
  )
}

CheckboxField.propTypes = {
  widthFull: PropTypes.bool,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  input: PropTypes.object.isRequired,
}

CheckboxField.defaultProps = {
  widthFull: false,
  meta: {},
  label: null,
}

const Checkbox = ({ label, onChange, checked, error, name, disabled }) => (
  <div className="flex flex-col">
    <div className="flex items-center w-full">
      <input
        id={name}
        name={name}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className="h-4 w-4 text-primary focus:ring-primary border-primary"
      />
      {label && (
        <label htmlFor={name} className="ml-2 block text-sm text-text-primary">
          {label}
        </label>
      )}
    </div>
    {error && (
      <p className="mt-2 text-sm text-red-400" id="email-error">
        {error}
      </p>
    )}
  </div>
)

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  label: null,
  type: "number",
  disabled: false,
  error: null,
}

export const GreenCheckbox = ({ id, label, onChange, checked, error, name }) => (
  <div className="flex flex-col">
    <div className="flex items-center w-full">
      <input
        id={id}
        name={name}
        type="radio"
        checked={checked}
        onChange={onChange}
        className="h-4 w-4 text-primary focus:outline-none focus:ring-0 bg-opacity-40 bg-primary border-opacity-40 border-primary"
      />
      {label && (
        <label htmlFor={id} className="ml-2 block text-sm text-text-primary">
          {label}
        </label>
      )}
    </div>
    {error && (
      <p className="mt-2 text-sm text-red-400" id="email-error">
        {error}
      </p>
    )}
  </div>
)

GreenCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

GreenCheckbox.defaultProps = {
  label: null,
  type: "number",
  disabled: false,
  error: null,
}

export default Checkbox
