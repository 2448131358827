import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ArrowSmRightIcon, PencilIcon } from "@heroicons/react/outline"
import DatePicker from "react-datepicker"
import { ToastContainer, toast } from "react-toastify"

import "react-datepicker/dist/react-datepicker.css"

import { CheckBox } from "../../../../components/inputs"
import { classNames } from "../../../../utils/ClassNames"

const BusinessHourCard = ({
  label,
  property,
  slots,
  checked,
  onChange,
  // addSlot,
  // removeSlot,
  handleFromTime,
  handleToTime,
  editable,
  setEditable,
  mandatory,
  isNotified,
  setNotifySetTime,
  notifySetTime,
  isEdit,
}) => {
  const { from, to } = slots

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotifySetTime(!notifySetTime)
    }, 2000)
    return () => clearTimeout(timer)
  }, [notifySetTime])

  return (
    <div className="bg-white rounded-lg shadow-xl px-4 py-6 flex justify-between my-2 relative">
      <div className="flex items-center">
        <CheckBox
          disabled={!isEdit}
          name={label}
          label={label}
          checked={mandatory ? true : checked}
          onChange={onChange}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex justify-center items-center sm:flex-row flex-col my-1">
          <DatePicker
            selected={from}
            onChange={handleFromTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            disabled={!isEdit}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className={classNames(
              !isEdit ? "border-none" : "",
              "focus:ring-indigo-500 focus:border-indigo-500 block w-24 sm:text-sm border-gray-300 rounded-md"
            )}
          />
          <ArrowSmRightIcon className=" mr-6 w-16 text-red-600" />
          <DatePicker
            selected={to}
            onChange={handleToTime}
            showTimeSelect
            disabled={!isEdit}
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className={classNames(
              !isEdit ? "border-none" : "",
              "focus:ring-indigo-500 focus:border-indigo-500 block w-24 sm:text-sm border-gray-300 rounded-md"
            )}
          />
        </div>
      </div>
      {/* {isNotified && (
        <div
          className="absolute z-5 right-16 top-16 pl-14 pr-14 pt-2 pb-2 leading-normal text-center text-red-700 bg-red-100 rounded-lg"
          role="alert"
        >
          <p>Please Set Time</p>
        </div>
      )} */}
    </div>
  )
}

BusinessHourCard.propTypes = {
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  slots: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // addSlot: PropTypes.func.isRequired,
  // removeSlot: PropTypes.func.isRequired,
  handleFromTime: PropTypes.func.isRequired,
  handleToTime: PropTypes.func.isRequired,
  editable: PropTypes.string.isRequired,
  setEditable: PropTypes.func.isRequired,
  mandatory: PropTypes.bool,
  isNotified: PropTypes.bool,
  setNotifySetTime: PropTypes.func,
  notifySetTime: PropTypes.bool,
  isEdit: PropTypes.bool,
}

BusinessHourCard.defaultProps = {
  slots: {},
  checked: false,
  mandatory: false,
}

export default BusinessHourCard
