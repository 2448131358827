import jwt_decode from "jwt-decode"

export const signIn = (user) => {
  localStorage.setItem("auth", JSON.stringify(user))
}

export const logOut = () => {
  localStorage.removeItem("auth")
  localStorage.removeItem("oldPassword")
}

export const getUser = () => {
  return localStorage.getItem("auth")
}

export const getDecoded = () => {
  const user = localStorage.getItem("auth")
  if (!user) return
  const {
    data: { idToken },
  } = JSON.parse(user)
  const decoded = jwt_decode(idToken)

  return decoded
}

export const getProviderId = () => {
  return getDecoded().pid
}

export const getMemberId = () => {
  return getDecoded().mid
}

export const getUserName = () => {
  return getDecoded()["cognito:username"]
}
