import { useState } from "react"
import PropTypes from "prop-types"
// import { useHistory } from "react-router-dom"
import { SearchIcon, MenuAlt2Icon } from "@heroicons/react/outline"
// import { Menu, Transition } from "@headlessui/react"
import ProviderSidebar from "./Sidebar"
// import { classNames } from "../../utils/ClassNames"
// import { logOut } from "../../utils/auth"

// import { userNavigation } from "../../constants/constants"
import Static from "../../static.json"

const DashboardLayout = ({ children }) => {
  const {
    dashboard: {
      layout: {
        // screennotificationtitle,
        screenopenmenutitle,
        screensearchtitle,
        // screenusermenutitle,
        searchinputplaceholder,
      },
    },
  } = Static
  // const history = useHistory()

  // const handleMenu = (type) => {
  //   if (type === "Sign out") {
  //     logOut()
  //     history.push("/signin")
  //   }
  // }

  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <div className="flex flex-col">
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <ProviderSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-gray-300 shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">{screenopenmenutitle}</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {/*  <div className="relative z-10 flex-shrink-0 flex h-16 bg-gray-300 shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">{screenopenmenutitle}</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search_field" className="sr-only">
                    {screensearchtitle}
                  </label>
                  <div className="relative w-full text-gray-500 focus-within:text-gray-500">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search_field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent bg-gray-300 text-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-500 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder={searchinputplaceholder}
                      type="search"
                      name="search"
                    />
                  </div>
                </form>
              </div>
           <div className="ml-4 flex items-center md:ml-6">
                <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">{screennotificationtitle}</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">{screenusermenutitle}</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {userNavigation.map(({ name }) => (
                            <Menu.Item key={name}>
                              {({ active }) => (
                                <a
                                  onClick={() => handleMenu(name)}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div> 
            </div>
          </div>*/}
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
}

export default DashboardLayout
