import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Field, Form } from "react-final-form"
import { Link, useHistory } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { connect } from "react-redux"
import PhoneInput from "react-phone-input-2"
import { signUp, verifyEmailAndPhone } from "../../../actions/user"

import { ExclamationCircleIcon } from "@heroicons/react/solid"
import { classNames } from "../../../utils/ClassNames"

import LogoImage from "../../../assets/logo.svg"

import SplashPage from "../SignIn/SplashPage"

import { Button } from "../../../components/buttons"
import {
  CheckboxField,
  SelectMenu,
  TextInputField,
} from "../../../components/inputs"
import SignUpInformation from "./SignUpInformation"
import RoleErrorModal from "./RoleErrorModal"

import {
  emailValidationPattern,
  passwordValidationPattern,
  ga,
  fbPixel,
  fbPixelTrackingIdOne,
  fbPixelTrackingIdTwo,
  gaTrackingId,
} from "../../../constants/constants"
import {
  showErrorNotification,
  showSuccessNotification,
  analyticsPageView,
} from "../../../utils/helper"
import Static from "../../../static.json"

const ProviderSignUp = ({ signUp, verifyEmailAndPhone }) => {
  const {
    signup: {
      pagetitle,
      index: {
        title,
        subtitle1,
        subtitle5,
        dividerdescription,
        loginbuttontext,
        errormessage,
        successmessage,
        signUpPageText,
        input: {
          firstnameplaceholder,
          lastnameplaceholder,
          emailplaceholder,
          phonenumberplaceholder,
          passwordplaceholder,
          confirmpasswordplaceholder,
          buttontitle,
          termlabel,
          termlinklabel,
          termconnectpolicylabel,
          policylinklabel,
        },
        validation: {
          firstnamevalidation,
          lastnamevalidation,
          emailvalidation,
          phonenumbervalidation,
          passwordvalidation,
          passwordconfirmationvalidation,
          termsvalidation,
          rechaptchavalidation,
        },
        jobRoles: {
          jobRolePlaceholder,
          roleSelection,
          popupMessage,
          owner,
          admin,
          technician,
          others,
        },
      },
    },
  } = Static
  const history = useHistory()
  const [steps, setSteps] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})
  const [policyInfo, setPolicyInfo] = useState()
  const [loading, setLoading] = useState(false)
  const [captchaKey, setCaptchaKey] = useState()
  const [phone, setPhone] = useState()
  const [phoneError, setPhoneError] = useState(false)
  const [captchaTouch, setCaptchaTouch] = useState()
  const [verifiedPhoneAndNumber, setVerifiedPhoneAndNumber] = useState(null)
  const [role, setRole] = useState("")
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.title = pagetitle
    if (process.env.REACT_APP_ENV === "production") {
      analyticsPageView(ga, history.location.pathname, gaTrackingId, null, null)
      analyticsPageView(
        fbPixel,
        history.location.pathname,
        fbPixelTrackingIdOne,
        "signup",
        { page: history.location.pathname }
      )
      analyticsPageView(
        fbPixel,
        history.location.pathname,
        fbPixelTrackingIdTwo,
        "signup",
        { page: history.location.pathname }
      )
    }
  }, [])

  useEffect(() => {
    setPhone(null)
  }, [])

  const handleJobRoleChange = (role) => {
    setRole(role.label)
    switch (role.value) {
      case admin:
        setIsOpen(true)
        break
      case technician:
        setIsOpen(true)
        break
      case others:
        setIsOpen(true)
        break
      default:
        return
    }
  }

  function formatPhoneNumber(value) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, "")
    const phoneNumberLength = phoneNumber.length

    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }

  const handleInput = (e) => {
    e.preventDefault()
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setPhone(formattedPhoneNumber)
    setPhoneError(false)
  }

  const encodeEmail = (email) => {
    let encodedEmail = encodeURIComponent(email)
    let spliceIndex
    for (let i = 0; i < encodedEmail.length; i++) {
      if (encodedEmail[i] === "%") {
        spliceIndex = i
      }
    }
    const splitArr = encodedEmail.split("")
    splitArr.splice(spliceIndex, 3, "@")
    const newEmail = splitArr.join("")
    return newEmail
  }

  const onSubmit = (values, type) => {
    const { email, firstName, lastName } = values
    const onlyPhoneNumbers = phone.replace(/\D/g, "")
    const phoneToSend = "%2B1".concat(onlyPhoneNumbers)
    const emailToSend = encodeEmail(email)

    if (!phone || phone.length !== 14) {
      setPhoneError(true)
      return
    }

    if (role === owner) {
      verifyEmailAndPhone(emailToSend, phoneToSend, firstName, lastName)
        .then(() => {
          submitUser(values, type)
        })
        .catch((error) => {
          setLoading(false)
          showErrorNotification(error, errormessage)
          setSteps(0)
        })
    } else {
      setIsOpen(true)
    }
  }

  const submitUser = (values, type) => {
    if (type === "signup") {
      // if (!captchaKey || !phone || phone.length !== 11) {
      setUserInfo(values)
      setSteps(1)
    } else if (type === "company") {
      setCompanyInfo(values)
      setSteps(2)
    } else if (type === "policy") {
      setPolicyInfo(values)
      const currentDate = new Date()
      setLoading(true)

      const {
        firstName: userFirstname,
        lastName: userLastname,
        email: userEmail,
        password: userPassword,
      } = userInfo

      const {
        businessName,
        categories,
        serviceRadius,
        address1,
        address2,
        city,
        zip,
        state,
      } = companyInfo

      const bodyData = {
        user: {
          firstname: userFirstname,
          lastname: userLastname,
          email: userEmail,
          password: userPassword,
          phoneNumber: `+1${phone.replace(/\D/g, "")}`,
        },
        company: {
          businessName,
          categories: categories.map(({ value }) => value),
          serviceRadius: serviceRadius.value,
          phoneNumber: `+1${phone.replace(/\D/g, "")}`,
          address: {
            line1: address1,
            line2: address2,
            city,
            zip,
            state,
          },
        },
        agreement: {
          terms: currentDate,
          smsEmailOptIn: currentDate,
        },
      }
      // console.log("BODY DATA", bodyData)
      signUp(bodyData)
        .then(() => {
          setLoading(false)
          setSteps(3)
          showSuccessNotification(successmessage)
        })
        .catch((error) => {
          setLoading(false)
          showErrorNotification(error, errormessage)
        })
    } else {
      history.push("/signin")
    }
  }

  const FormValidation = (values) => {
    const errors = {}
    // if (!phone || phone.length !== 11) {
    //   setPhoneError(true)
    //   setCaptchaTouch(true)
    //   errors.phone = true
    // } else {
    //   setPhoneError(false)
    // }
    const { firstName, lastName, email, password, passwordConfirmation, agreeTerm } =
      values

    if (!firstName || firstName.trim().length === 0) {
      errors.firstName = firstnamevalidation
    }

    if (!lastName || lastName.trim().length === 0) {
      errors.lastName = lastnamevalidation
    }

    if (!email || !emailValidationPattern.test(email)) {
      errors.email = emailvalidation
    }

    if (!password || !passwordValidationPattern.test(password)) {
      errors.password = passwordvalidation
    }

    if (password !== passwordConfirmation) {
      errors.passwordConfirmation = passwordconfirmationvalidation
    }

    if (!agreeTerm) {
      errors.agreeTerm = termsvalidation
    }
    return errors
  }

  const onCaptcha = (event) => {
    setCaptchaKey(event)
  }
  if (steps > 0) {
    return (
      <SignUpInformation
        steps={steps}
        onSubmit={onSubmit}
        setSteps={setSteps}
        loading={loading}
      />
    )
  }

  return (
    <div className="flex min-h-screen mx-auto">
      <div className="flex-1 w-1/2 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-500">
          <div>
            <img className="h-12 w-auto m-auto" src={LogoImage} alt="Workflow" />
            <h4 className="mt-6 text-2xl font-regular text-gray-900 text-center">
              {subtitle5}
            </h4>
            <h4 className="text-2xl font-regular text-gray-900 text-center">
              {subtitle1}
            </h4>
          </div>

          {setIsOpen && (
            <RoleErrorModal
              message={popupMessage}
              open={isOpen}
              setOpen={setIsOpen}
            />
          )}

          <div className="mt-8">
            <div className="mt-6">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-medium text-gray-700">{title}</h2>
              </div>
              <Form
                onSubmit={(values) => onSubmit(values, "signup")}
                validate={FormValidation}
                render={({ handleSubmit, values, ...rest }) => {
                  return (
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <Field
                        type="text"
                        name="firstName"
                        placeholder={firstnameplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      <Field
                        type="text"
                        name="lastName"
                        placeholder={lastnameplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      <Field
                        type="email"
                        name="email"
                        placeholder={emailplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      {/* -------------------------------------- Custom input for phone -------------------------------------- */}
                      <>
                        <div
                          className={classNames(
                            "flex appearance-none block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:border-green-primary sm:text-sm whitespace-nowrap",
                            phoneError
                              ? "border-red-400 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-400 focus:border-red-400"
                              : ""
                          )}
                        >
                          <div className="w-12 flex items-center justify-center">
                            <p className="text-gray-400"> + 1 </p>
                          </div>
                          <input
                            className={
                              "appearance-none w-full p-3  border-none rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:ring-2 focus:border-green-primary sm:text-sm"
                            }
                            // onInput={(e) =>
                            //   (e.target.value = e.target.value.slice(0, 10))
                            // }
                            value={phone}
                            onChange={(e) => handleInput(e)}
                            placeholder={phonenumberplaceholder}
                          />
                          {phoneError && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {phoneError && (
                          <p
                            className="mt-2 text-sm text-red-400"
                            id="captcha-error"
                          >
                            {phonenumbervalidation}
                          </p>
                        )}
                      </>
                      {/* -------------------------------------- End Custom input for phone -------------------------------------- */}

                      {/* <Field name="phone">
                        {({ input, meta }) => (
                          <>
                            <PhoneInput
                              {...input}
                              specialLabel=""
                              country={"us"}
                              value={phone}
                              placeholder={phonenumberplaceholder}
                              onChange={(value) => {
                                setPhone(value)
                              }}
                              onlyCountries={["us"]}
                              inputClass="appearance-none block p-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:border-green-primary sm:text-sm w-full"
                            />
                            {(meta.error || meta.submitError) && meta.touched && (
                              <p
                                className="mt-2 text-sm text-red-400"
                                id="captcha-error"
                              >
                                {phonenumbervalidation}
                              </p>
                            )}
                          </>
                        )}
                      </Field> */}
                      <Field
                        type="select"
                        name="role"
                        options={roleSelection}
                        component={SelectMenu}
                        placeholder={!role ? jobRolePlaceholder : role}
                        widthFull
                        required
                        onChange={(value) => handleJobRoleChange(value)}
                      />
                      {/* <Field
                        type="select"
                        name="country"
                        options={roleSelection}
                        component={SelectInputField}
                        placeholder={jobRolePlaceholder}
                        onChange={(value) => handleJobRoleChange(value)}
                        widthFull
                        required
                      /> */}
                      <Field
                        type="password"
                        name="password"
                        placeholder={passwordplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      <Field
                        type="password"
                        name="passwordConfirmation"
                        placeholder={confirmpasswordplaceholder}
                        component={TextInputField}
                        widthFull
                      />
                      {/* <ReCAPTCHA sitekey={TEMPRecaptchaKey} onChange={onCaptcha} />
                    {captchaTouch && !captchaKey && (
                      <p className="mt-2 text-sm text-red-400" id="captcha-error">
                        {rechaptcha}
                      </p>
                    )} */}
                      <div className="flex items-center">
                        <Field
                          type="input"
                          name="agreeTerm"
                          component={CheckboxField}
                          label={
                            <>
                              {termlabel}
                              <Link
                                target="_blank"
                                to="/terms-of-service"
                                className="ml-2 mr-2 font-medium text-sm text-gray-600 hover:text-gray-500 text-right underline"
                              >
                                {termlinklabel}
                              </Link>
                              {termconnectpolicylabel}
                              <Link
                                target="_blank"
                                to="/privacy-policy"
                                className="ml-2 font-medium text-sm text-gray-600 hover:text-gray-500 text-right underline"
                              >
                                {policylinklabel}
                              </Link>
                            </>
                          }
                        />
                      </div>
                      <Button loading={loading} type="submit" title={buttontitle} />
                    </form>
                  )
                }}
              />
              <div className="mt-6 relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    {dividerdescription}
                  </span>
                </div>
              </div>
              <div className="mt-2 text-center">
                <Link
                  to="/signin"
                  className="font-medium text-gray-600 hover:text-gray-500 text-right underline"
                >
                  {loginbuttontext}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SplashPage />
    </div>
  )
}

ProviderSignUp.propTypes = {
  signUp: PropTypes.func.isRequired,
  verifyEmailAndPhone: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  signUp,
  verifyEmailAndPhone,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSignUp)
