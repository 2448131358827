import React, { useState, useEffect } from "react"
import { Link, useHistory, useRouteMatch, Switch, Route } from "react-router-dom"
import moment from "moment"

import { ChevronLeftIcon } from "@heroicons/react/outline"

import { invoicesData } from "../../../../../constants/constants"
import { SearchIcon, EyeIcon } from "@heroicons/react/outline"
// import InvoicePagination from "./InvoicePagination"
import ReviewInvoice from "./ReviewInvoice"
import PaidInvoice from "./PaidInvoice"
import { InvoiceContextProvider } from "./InvoiceContext"
import { getAllInvoices } from "../../../../../services/api"
import { getProviderId } from "../../../../../utils/auth"

const ViewInvoice = () => {
  const { invoiceTableData, invoiceStatuses } = invoicesData
  const [statusTabs, setStatusTabs] = useState()
  const [invoices, setInvoices] = useState([])
  const [invoiceDate, setInvoiceDate] = useState("")
  const history = useHistory()
  const { url } = useRouteMatch()

  const invoiceFilterTabs = [
    {
      tab: "All Invoices",
      value: "",
      active: true,
      func: () => getInvoices(),
    },
    {
      tab: "Paid",
      value: "paid",
      value2: "approved",
      active: false,
      func: async (v1, v2) => {
        try {
          const res = await getAllInvoices(getProviderId())
          const data = res.data.data
          console.log("INVOICES:", data)
          setInvoices(() =>
            data.filter(
              (item) => item.invoiceStatus === v1 || item.invoiceStatus === v2
            )
          )
        } catch (err) {
          console.log(err)
        }
      },
    },
    {
      tab: "Processing",
      value: "tiriosReview",
      active: false,
      func: (status) => getInvoices(status),
    },
    {
      tab: "Needs Review",
      value: "internalReview",
      active: false,
      func: (status) => getInvoices(status),
      isReview: true,
    },
  ]
  const handleInvoiceStatusTab = (status) => {
    let prevData = [...statusTabs]
    for (let i = 0; i < prevData.length; i++) {
      if (prevData[i].tab === status) {
        prevData[i].active = true
      } else {
        prevData[i].active = false
      }
    }
    setStatusTabs(prevData)
  }
  const getInvoices = async (filtered) => {
    try {
      const res = await getAllInvoices(getProviderId())
      const data = res.data.data
      console.log("RESPONSE INVOICE: ", data)
      if (filtered) {
        setInvoices(() => data.filter((item) => item.invoiceStatus === filtered))
      } else {
        setInvoices(data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getInvoices()
    setStatusTabs(invoiceFilterTabs)
  }, [])
  return (
    <>
      <Switch>
        <Route exact path={`${url}`}>
          <div className="py-4 pl-3 lg:pr-12 pr-3 ">
            <button
              onClick={() => history.goBack()}
              className="flex mb-6 mt-3 text-gray-500"
            >
              <ChevronLeftIcon className="text-gray-500 h-6 w-8" />
              Back to Payments
            </button>
            <h1 className="text-4xl mt-5 mb-5 tracking-wide text-gray-primary">
              Invoices
            </h1>
            {/* Top container */}
            <div className="flex justify-between w-full p-5">
              {/* All Invoices, Paid, Processing */}
              <div className="flex justify-between items-center w-96">
                {statusTabs?.map(
                  ({ tab, value, value2, active, func, isReview }) => {
                    if (isReview) {
                      return (
                        <>
                          <p
                            className={
                              active
                                ? "cursor-pointer tracking-wide font-bold text-red-350 border-b-2 border-red-350"
                                : "cursor-pointer tracking-wide font-bold text-red-350"
                            }
                            onClick={() => {
                              func(value, value2)
                              handleInvoiceStatusTab(tab)
                            }}
                          >
                            {tab}
                          </p>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <p
                            className={
                              active
                                ? "cursor-pointer tracking-wide font-bold border-b-2 border-black"
                                : "cursor-pointer tracking-wide font-bold text-gray-secondary"
                            }
                            onClick={() => {
                              func(value, value2)
                              handleInvoiceStatusTab(tab)
                            }}
                          >
                            {tab}
                          </p>
                        </>
                      )
                    }
                  }
                )}
              </div>
              {/* Search */}
              <div className="flex relative border-b-2 border-gray-secondary">
                <p className="w-4 pt-3 ml-2 flex items-center cursor-pointer absolute">
                  <SearchIcon />
                </p>
                <input
                  type="text"
                  placeholder="Search"
                  className="bg-transparent	text-right border-none outline-inherit"
                />
              </div>
            </div>
            {/* Table  */}
            <div className="table-fixed w-full bg-white mt-4 shadow border border-gray-light rounded-md p-4">
              <table className="table-fixed w-full bg-white">
                <thead className="border-b-2 border-gray-light">
                  <tr>
                    {invoiceTableData?.map(({ title, id }) => (
                      <th
                        key={id}
                        className="text-left text-gray-primary font-normal pb-2 "
                      >
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {invoices?.map(
                    (
                      {
                        invoiceId,
                        totalAmount,
                        invoiceStatus,
                        job: {
                          jobScheduledTime,
                          paymentDate,
                          address: { addressLine1, city, state, zipCode },
                        },
                      },
                      index
                    ) => {
                      if (invoiceStatus === "creating") return
                      return (
                        <>
                          <tr key={index} className="bg-gray-bg">
                            <td className="p-4 rounded-bl-2xl rounded-tl-2xl ">
                              {moment(`${jobScheduledTime}z`).format("MMM DD, YYYY")}
                            </td>
                            <td>
                              <p className="p-2"> {invoiceId}</p>
                            </td>
                            <td>
                              <p className="w-5/6">{`${addressLine1}`}</p>
                              <p className="w-5/6">{`${city} ${state} ${zipCode}`}</p>
                            </td>
                            <td> ${totalAmount.toFixed(2)} </td>
                            <td>
                              <p
                                className={`w-24 h-6 flex items-center justify-center rounded-full p-2 text-xs text-gray-primary`}
                                style={{
                                  background: invoiceStatuses[invoiceStatus].color,
                                  color:
                                    invoiceStatuses[invoiceStatus] ===
                                    invoiceStatuses["internalReview"]
                                      ? "white"
                                      : "#54565A",
                                }}
                              >
                                {invoiceStatuses[invoiceStatus].value}
                              </p>
                            </td>
                            {/* Date paid */}
                            <td>{paymentDate}</td>
                            {/* <td>{moment(`${paymentDate}z`).format("MMM DD, YYYY")}</td> */}
                            {/* Actions */}
                            <td className="rounded-br-2xl rounded-tr-2xl">
                              <div className="flex items-center">
                                {invoiceStatuses[invoiceStatus] ===
                                invoiceStatuses["internalReview"] ? (
                                  <Link
                                    onClick={() => setInvoiceDate(jobScheduledTime)}
                                    to={`/provider/dashboard/payments/invoices/review-invoice/${invoiceId}`}
                                    className="flex items-center justify-center cursor-pointer text-xs w-24 border border-gray-primary rounded-full h-6 bg-red-350 text-white"
                                  >
                                    <p> REVIEW </p>
                                  </Link>
                                ) : (
                                  <Link
                                    onClick={() => setInvoiceDate(jobScheduledTime)}
                                    to={`/provider/dashboard/payments/invoices/paid-invoice/${invoiceId}`}
                                    className="flex items-center justify-center cursor-pointer text-xs w-24 border border-gray-primary rounded-full h-6 "
                                  >
                                    <EyeIcon className="h-3 mr-1" />
                                    <p> Invoice </p>
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                          <div className="w-full mb-2"></div>
                        </>
                      )
                    }
                  )}
                </tbody>
              </table>
              {/* Create pagination here: */}
            </div>
            {/* <InvoicePagination data={[]} /> */}
          </div>
        </Route>
        <Route path={`${url}/review-invoice/:invoiceId`}>
          <InvoiceContextProvider>
            <ReviewInvoice date={invoiceDate} />
          </InvoiceContextProvider>
        </Route>
        <Route path={`${url}/paid-invoice/:invoiceId`}>
          <PaidInvoice date={invoiceDate} />
        </Route>
      </Switch>
    </>
  )
}

export default ViewInvoice
