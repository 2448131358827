import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { PencilIcon } from "@heroicons/react/outline"

import { TextInput } from "../../../../components/inputs"
import { classNames } from "../../../../utils/ClassNames"
import Static from "../../../../static.json"
import { tradeCategories } from "../../../../constants/constants"

const Table = ({
  columns,
  data,
  categories,
  onChange,
  editableCategoryId,
  setEditableCategoryId,
  notifySetRate,
  setNotifySetRate,
  isEdit,
}) => {
  const {
    dashboard: {
      members: { screenactioncolumntitle },
    },
  } = Static

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotifySetRate(!notifySetRate)
    }, 2000)
    return () => clearTimeout(timer)
  }, [notifySetRate])

  return (
    <table className="min-w-full divide-y divide-gray-200 ">
      <thead className="bg-gray-700">
        <tr>
          {columns.map(({ label, dataKey }, index) => (
            <th
              key={dataKey}
              scope="col"
              className={classNames(
                "sm:px-4 py-3 px-2 text-xs font-medium text-gray-50 tracking-wider",
                index === 0 ? " text-left " : "text-center "
              )}
            >
              <p className="mt-2 mb-2 ml-2">{label}</p>
            </th>
          ))}
          <th scope="col" className="relative px-6 py-3">
            <span className="sr-only">{screenactioncolumntitle}</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {categories.map(({ id, name }) => (
          <tr key={id}>
            {columns.map(({ dataKey, type }) => {
              if (dataKey === "tradeCategory") {
                return (
                  <td
                    key={`${id}-${dataKey}`}
                    className={classNames(
                      "sm:px-4 py-3 px-2 whitespace-nowrap text-text-primary  h-16",
                      dataKey !== "tradeCategory" ? "text-green-600" : ""
                    )}
                  >
                    <p className="mt-2 mb-2 ml-2">{tradeCategories[name]}</p>
                  </td>
                )
              }

              const findOne = data.find(
                ({ providerCategoryId, typeId }) =>
                  providerCategoryId === id && typeId === type
              )

              if (isEdit) {
                return (
                  <td key={`${id}-${dataKey}`} className="">
                    <div className="w-full flex justify-center">
                      <input
                        type="text"
                        name="textValue"
                        value={findOne ? findOne.value : 0}
                        onChange={(event) => {
                          onChange(id, type, event.target.value)
                        }}
                        className={
                          "text-text-primary w-32 text-center appearance-none block p-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-primary focus:border-green-primary"
                        }
                      />
                    </div>
                  </td>
                )
              } else {
                return (
                  <td
                    key={`${id}-${dataKey}`}
                    className={classNames(
                      "sm:px-4 py-3 px-2 whitespace-nowrap relative text-center text-text-primary"
                    )}
                  >
                    <p> ${findOne ? findOne.value : 0}</p>
                  </td>
                )
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  categories: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  setEditableCategoryId: PropTypes.func.isRequired,
  editableCategoryId: PropTypes.number,
  isEdit: PropTypes.bool,
}

Table.defaultProps = {
  data: [],
}

export default Table
