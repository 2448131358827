import { useState } from "react"
import PropTypes from "prop-types"
import { Field, Form } from "react-final-form"
import SearchLocation from "./LocationSearchInput"

import { RoundedButton } from "../../../components/buttons"
import {
  TextInputField,
  SelectInputField,
  SelectMenu,
  TextInput,
} from "../../../components/inputs"
import {
  tradeCategoryOptions,
  serviceAreaOptions,
  stateOptions,
  zipcodeValidationPattern,
} from "../../../constants/constants"

import Static from "../../../static.json"

const CompanyInformation = ({ onSubmit }) => {
  const {
    signup: {
      company: {
        title,
        input: {
          businessnamelabel,
          businessnameplaceholder,
          address1label,
          address1placeholder,
          address2placeholder,
          cityplaceholder,
          stateplaceholder,
          zipplaceholder,
          tradecategorylabel,
          tradecategorysublabel,
          servicearealabel,
          serviceareasublabel,
          buttontitle,
          tradeplaceholder,
          serviceplaceholder,
        },
        validation: {
          businessnamevalidation,
          address1validation,
          cityvalidation,
          statevalidation,
          zipvalidation,
          tradecategoryvalidation,
          serviceareavalidation,
        },
      },
    },
  } = Static

  const [addressObject, setAddressObject] = useState({})
  const [error, setError] = useState({})

  const submitHandler = (values) => {
    const { businessName, categories, serviceRadius } = values

    const addressError = {}
    const formError = {}
    const { address1, city, state, zip } = addressObject
    if (!address1) {
      addressError.address1 = address1validation
    }
    if (!city) {
      addressError.city = cityvalidation
    }
    if (!state) {
      addressError.state = statevalidation
    }
    if (!zip || !zipcodeValidationPattern.test(zip)) {
      addressError.zip = zipvalidation
    }

    if (!businessName) {
      formError.businessName = businessnamevalidation
    }
    if (!categories || categories.length === 0) {
      formError.categories = tradecategoryvalidation
    }
    if (!serviceRadius) {
      formError.serviceRadius = serviceareavalidation
    }

    if (
      Object.keys(addressError).length === 0 &&
      Object.keys(formError).length === 0
    ) {
      onSubmit(
        {
          ...values,
          ...addressObject,
        },
        "company"
      )
    } else {
      setError(addressError)
      return formError
    }
  }

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-text-primary mb-4">{title}</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <Form
            onSubmit={submitHandler}
            render={({ handleSubmit, ...rest }) => {
              return (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <Field
                    type="text"
                    label={businessnamelabel}
                    name="businessName"
                    placeholder={businessnameplaceholder}
                    component={TextInputField}
                    widthFull
                  />
                  <SearchLocation
                    type="text"
                    label={address1label}
                    name="address1"
                    placeholder={address1placeholder}
                    widthFull
                    error={error.address1}
                    setAddress={setAddressObject}
                    value={addressObject.address1}
                    setValue={(value) => {
                      setAddressObject({
                        ...addressObject,
                        address1: value,
                      })
                    }}
                  />
                  <Field
                    type="text"
                    name="address2"
                    placeholder={address2placeholder}
                    component={TextInputField}
                    widthFull
                  />
                  <TextInput
                    type="text"
                    name="city"
                    placeholder={cityplaceholder}
                    widthFull
                    error={error.city}
                    setAddress={setAddressObject}
                    value={addressObject.city}
                    onChange={(event) => {
                      setAddressObject({
                        ...addressObject,
                        city: event.target.value,
                      })
                    }}
                  />
                  <SelectMenu
                    type="select"
                    name="state"
                    options={stateOptions}
                    widthFull
                    error={error.state}
                    value={
                      addressObject.state &&
                      stateOptions.find(({ value }) => value === addressObject.state)
                    }
                    placeholder={stateplaceholder}
                    onChange={({ value }) => {
                      setAddressObject({
                        ...addressObject,
                        state: value,
                      })
                    }}
                  />
                  <TextInput
                    type="number"
                    name="zip"
                    placeholder={zipplaceholder}
                    widthFull
                    value={addressObject.zip}
                    onChange={(event) => {
                      setAddressObject({
                        ...addressObject,
                        zip: event.target.value,
                      })
                    }}
                    error={error.zip}
                  />

                  <Field
                    type="select"
                    name="categories"
                    label={tradecategorylabel}
                    subLabel={tradecategorysublabel}
                    options={tradeCategoryOptions}
                    component={SelectInputField}
                    placeholder={tradeplaceholder}
                    widthFull
                    isMulti
                  />
                  <Field
                    type="select"
                    name="serviceRadius"
                    label={servicearealabel}
                    subLabel={serviceareasublabel}
                    options={serviceAreaOptions}
                    component={SelectInputField}
                    widthFull
                    placeholder={serviceplaceholder}
                  />
                  <RoundedButton
                    variant="primary"
                    type="submit"
                    title={buttontitle}
                  />
                </form>
              )
            }}
          />
        </div>
      </div>
    </main>
  )
}

CompanyInformation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default CompanyInformation
