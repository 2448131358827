import {
  CheckIcon,
  CogIcon,
  UserIcon,
  UsersIcon,
  CreditCardIcon,
  AdjustmentsIcon,
  BriefcaseIcon,
  ClockIcon,
  LogoutIcon,
} from "@heroicons/react/outline"
import { toast } from "react-toastify"
import States from "./states.json"
import LockIcon from "../assets/LockIcon.svg"
import Notification from "../assets/Notification.svg"
import Document from "../assets/Document.svg"
import PrivacyPolicy from "../assets/PrivacyPolicy.svg"
import Share from "../assets/Share.svg"

export const toastErrorOptions = {
  type: toast.TYPE.ERROR,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
}

export const toastSuccessOptions = {
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
}

export const stateOptions = Object.keys(States).map((key) => ({
  value: key,
  label: States[key],
}))

export const accountTypeOptions = [
  {
    value: "8",
    label: "Personal Checking",
  },
  {
    value: "9",
    label: "Personal Savings",
  },
  {
    value: "10",
    label: "Business Checking",
  },
  {
    value: "11",
    label: "Business Saving",
  },
]

export const tradeCategoryOptions = [
  {
    value: "PLUMBER",
    label: "Plumber",
  },
  {
    value: "ELECTRICIAN",
    label: "Electrician",
  },
  {
    value: "HVAC",
    label: "HVAC",
  },
  {
    value: "LOCKSMITH",
    label: "Locksmith",
  },
  {
    value: "APPLIANCE_REPAIR",
    label: "Appliance Repair",
  },
  {
    value: "ROOFING",
    label: "Roofing",
  },
  {
    value: "HANDYMAN",
    label: "Handyman",
  },
  {
    value: "PEST_CONTROL",
    label: "Pest Control",
  },
  {
    value: "MOVERS",
    label: "Movers",
  },
  {
    value: "LANDSCAPING",
    label: "Landscaping",
  },
  {
    value: "GARAGE DOOR",
    label: "Garage Door",
  },
  {
    value: "FLOORING - CARPET",
    label: "Flooring - Carpet",
  },
  {
    value: "FLOORING - HARDWOOD",
    label: "Flooring - Hardwood",
  },
  {
    value: "FLOORING - SYNTHETIC",
    label: "Flooring - Synthetic",
  },
  {
    value: "WINDOWS",
    label: "Windows",
  },
  {
    value: "CHIMNEYS",
    label: "Chimneys",
  },
  {
    value: "GENERAL CARPENTRY",
    label: "General Carpentry",
  },
  {
    value: "PAINTING",
    label: "Painting",
  },
  {
    value: "IRRIGATION",
    label: "Irrigation",
  },
  {
    value: "GENERAL CONTRACTOR",
    label: "General Contractor",
  },
  {
    value: "DOORS",
    label: "Doors",
  },
  {
    value: "HOME_INSPECTIONS",
    label: "Home Inspector",
  },
  {
    value: "OTHER",
    label: "Other",
  },
]

export const tradeCategories = {
  PLUMBER: "Plumber",
  ELECTRICIAN: "Electrician",
  HVAC: "HVAC",
  LOCKSMITH: "Locksmith",
  APPLIANCE_REPAIR: "Appliance Repair",
  ROOFING: "Roofing",
  HANDYMAN: "Handyman",
  PEST_CONTROL: "Pest Control",
  MOVERS: "Movers",
  LANDSCAPING: "Landscaping",
  "GARAGE DOOR": "Garage Door",
  "FLOORING - CARPET": "Flooring - Carpet",
  "FLOORING - HARDWOOD": "Flooring - Hardwood",
  "FLOORING - SYNTHETIC": "Flooring - Synthetic",
  WINDOWS: "Windows",
  CHIMNEYS: "Chimneys",
  "GENERAL CARPENTRY": "General Carpentry",
  PAINTING: "Painting",
  IRRIGATION: "Irrigation",
  "GENERAL CONTRACTOR": "General Contractor",
  DOORS: "Doors",
  HOME_INSPECTIONS: "Home Inspector",
  OTHER: "Other",
}

export const businessTypeOptions = [
  {
    label: "Sole Proprietor",
    value: "0",
  },
  {
    label: "Corporation",
    value: "1",
  },
  {
    label: "Limited Liability Company",
    value: "2",
  },
  {
    label: "Partnership",
    value: "3",
  },
]

export const serviceAreaOptions = [
  {
    value: 10,
    label: "10 miles",
  },
  {
    value: 25,
    label: "25 miles",
  },
  {
    value: 50,
    label: "50 miles",
  },
]

export const signUpNavigation = [
  { name: "Company Information", icon: CheckIcon },
  { name: "Terms & Conditions", icon: CheckIcon },
  { name: "Submit", icon: CheckIcon },
]

export const CARD_STATUS = {
  not_started_status: "not_started",
  not_started: "Not Started",
  inquiry_completed: "In Review",
  in_review: "In Review",
  approved: "Approved",
  declined: "Declined",
}

export const APPROVED = "approved"

export const ENVIRONMENTS = {
  production: "production",
  dev: "dev",
  sandbox: "sandbox",
}

export const IDENTITY_TEMPLATE_ID = "tmpl_PSCndNF1LdYRdQdngJGxCe4k"
export const BUSINESS_LICENSE_TEMPLATE_ID = "tmpl_arZ9JCsVzTczUCnt5SjYjtFz"
export const BUSINESS_INSURANCE_TEMPLATE_ID = "tmpl_oSeonJ2CR36QqPV4ynoYTefk"

export const CARD_IDENTITY_URL =
  "https://withpersona.com/verify?template-id=tmpl_Dr9q3WHg9eUgsMXdKFomPr36"
export const CARD_BUSINESS_LICENSE_URL =
  "https://withpersona.com/verify?template-id=tmpl_arZ9JCsVzTczUCnt5SjYjtFz"
export const CARD_BUSINESS_INSURANCE_URL =
  "https://withpersona.com/verify?template-id=tmpl_oSeonJ2CR36QqPV4ynoYTefk"

export const membersTableColumns = [
  { label: "First Name", dataKey: "firstname" },
  {
    label: "Last Name",
    dataKey: "lastname",
  },
  {
    label: "Email",
    dataKey: "email",
  },
  {
    label: "Phone Number",
    dataKey: "phoneNumber",
  },
  {
    label: "Role",
    dataKey: "roles",
  },
  {
    label: "Trade Categories",
    dataKey: "categories",
  },
  {
    label: "Status",
    dataKey: "active",
  },
]

export const jobsTableColumns = [
  { label: "Date", dataKey: "date" },
  {
    label: "Time",
    dataKey: "time",
  },
  {
    label: "Location",
    dataKey: "address",
  },
  {
    label: "Trade Category",
    dataKey: "category",
  },
  {
    label: "Job Status",
    dataKey: "status",
  },
  {
    label: "Assigned to",
    dataKey: "assignedTo",
  },
  {
    label: "Actions",
    dataKey: "action",
  },
]

export const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
]

export const limitedNavigation = [
  {
    name: "Onboarding",
    route: "/provider/dashboard/onboarding",
    Icon: AdjustmentsIcon,
  },
  { name: "Logout", route: "/signin", Icon: LogoutIcon },
]

export const navigation = [
  { name: "Dashboard", route: "/provider/dashboard/home", Icon: ClockIcon },
  { name: "Jobs", route: "/provider/dashboard/jobs", Icon: BriefcaseIcon },
  { name: "Profile", route: "/provider/dashboard/profile", Icon: UserIcon },
  { name: "Members", route: "/provider/dashboard/members", Icon: UsersIcon },
  { name: "Payments", route: "/provider/dashboard/payments", Icon: CreditCardIcon },
  { name: "Settings", route: "/provider/dashboard/settings", Icon: CogIcon },
  { name: "Logout", route: "/signin", Icon: LogoutIcon },
]

export const mobileNavigation = [
  { name: "Dashboard", route: "/provider/dashboard/home", Icon: ClockIcon },
  { name: "Jobs", route: "/provider/dashboard/jobs", Icon: BriefcaseIcon },
  { name: "Logout", route: "/signin", Icon: LogoutIcon },
]

export const settingsLink = [
  {
    name: "Change Password",
    route: "/provider/dashboard/changePassword",
    Icon: LockIcon,
  },
  { name: "Notification Settings", route: false, Icon: Notification },
  { name: "Terms & Conditions", route: "/terms-of-service", Icon: Document },
  { name: "Privacy Policy", route: "/privacy-policy", Icon: PrivacyPolicy },
  { name: "Share", route: false, Icon: Share },
]

export const passwordCriteria = [
  {
    name: "characterLength",
    value: "At least 8 characters",
  },
  {
    name: "numberExists",
    value: "A number",
  },
  {
    name: "UpperCaseChar",
    value: "An uppercase letter",
  },
  {
    name: "LowerCaseChar",
    value: "A lowercase letter",
  },
  {
    name: "specialChar",
    value: "A special character",
  },
]

export const emailValidationPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const urlValidationPattern = new RegExp(
  "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
)

// export const VALIDATE_WEBSITE =
// /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const VALIDATE_WEBSITE = /(http(s?)):\/\//i

export const phoneNumberValidationPattern =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

// export const passwordValidationPattern = /^(?=.*\d)(?=.*[@,;.!#(){}+$[\]]).{8,}$/
export const passwordValidationPattern = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.?/,><':;\"|_~`\\[\\]{}()])(?=.{8,})"
)
export const number = /^[0-9]+$/
export const alphabetLower = /[a-z]/
export const alphabetUpper = /[A-Z]/
export const numberContains = /[0-9]/
/* eslint-disable no-useless-escape */
export const specialCharacter = new RegExp(
  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
)
export const zipcodeValidationPattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const billingRatesTableColumns = [
  { label: "Trade Category", dataKey: "tradeCategory", available: true },
  {
    label: "Weekdays",
    dataKey: "weekdays",
    available: true,
    type: 1,
  },
  {
    label: "Weekdays Afterhours",
    dataKey: "overtime_weekdays",
    available: true,
    type: 2,
  },
  {
    label: "Saturday",
    dataKey: "saturday",
    available: true,
    type: 3,
  },
  {
    label: "Sunday",
    dataKey: "sunday",
    available: true,
    type: 4,
  },
]

export const initialBusinessState = {
  weekdays: {
    label: "Monday to Friday",
    slots: {
      from: new Date("2000/01/01 8:00:00"),
      to: new Date("2000/01/01 18:00:00"),
    },

    checked: true,
    typeId: 1,
    mandatory: true,
  },
  overtime_weekdays: {
    label: "Monday to Friday (After hours): Optional",
    slots: {
      from: new Date("2000/01/01 18:00:00"),
      to: new Date("2000/01/01 23:59:00"),
    },
    checked: false,
    typeId: 2,
  },
  saturday: {
    label: "Saturday: Optional",
    slots: {
      from: new Date("2000/01/01 8:00:00"),
      to: new Date("2000/01/01 17:00:00"),
    },
    checked: false,
    typeId: 3,
  },
  sunday: {
    label: "Sunday: Optional",
    slots: {
      from: new Date("2000/01/01 8:00:00"),
      to: new Date("2000/01/01 17:00:00"),
    },
    checked: false,
    typeId: 4,
  },
}

export const addBankSteps = [
  { id: "Step 1", name: "About the Business", href: "#", status: "current" },
  { id: "Step 2", name: "About the Owner", href: "#", status: "upcoming" },
  { id: "Step 3", name: "Add Bank Account", href: "#", status: "upcoming" },
]

export const FBUrl = "https://www.facebook.com/Tirios-103319725173862"
export const LinkedInUrl = "https://www.linkedin.com/company/tirios"
export const TwitterUrl = "https://twitter.com/tirios_ai"

export const scheduled = "ACCEPTED"
export const inCompleted = "CHECKED_IN,CHECKED_OUT"
export const completed = "CLOSED"

export const gaTrackingId = "UA-204688692-2"
export const fbPixelTrackingIdOne = "366329721887221"
export const fbPixelTrackingIdTwo = "277244394220776"
export const ga = "GOOGLE_ANALYTICS"
export const fbPixel = "FACEBOOK_PIXELS"
export const tiriosURL = "https://www.tirios.ai/"

export const addMemberRoles = {
  owner: "OWNER",
  worker: "WORKER",
  technician: "TECHNICIAN",
  admin: "ADMIN",
  operations: "OPERATIONS",
  pendingWorker: "PENDING_WORKER",
  pendingOwner: "PENDING_OWNER",
}
export const roles = {
  owner: {
    value: "OWNER",
    label: "Owner",
  },
  technician: {
    value: "PENDING_WORKER",
    label: "Pending Technician",
  },
  approvedTechnician: {
    value: "WORKER",
    label: "Technician",
  },
  admin: {
    value: "ADMIN",
    label: "Admin",
  },
  operations: {
    value: "OPERATIONS",
    label: "Operations",
  },
}
export const newOwnerCreation = {
  first: "",
  last: "",
  businesstitle: "",
  email: "",
  ownership: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  dob: { day: "", month: "", year: "" },
  ssn: { pre: "", mid: "", suf: "" },
  zip: "",
  country: "United States",
  phone: "",
  primary: true,
}

export const invoicesData = {
  invoiceStatuses: {
    paid: { value: "Paid", color: "rgba(34, 151, 92, 0.5)" },
    creating: {
      value: "Creating",
      color: "rgba(248, 161, 15, 0.5)",
    },
    internalReview: {
      value: "Needs Review",
      color: "#F05359",
    },
    tiriosReview: {
      value: "Processing",
      color: "rgba(248, 161, 15, 0.5)",
    },
    approved: {
      value: "Paid",
      color: "rgba(34, 151, 92, 0.5)",
    },
    paymentFailed: {
      value: "Processing",
      color: "rgba(240, 83, 89, 1)",
    },
  },
  invoiceTableData: [
    {
      title: "Job Date",
      id: 1,
    },
    {
      title: "Invoice",
      id: 2,
    },
    {
      title: "Location",
      id: 3,
    },
    {
      title: "Amount Due",
      id: 4,
    },
    {
      title: "Status",
      id: 5,
    },
    {
      title: "Date Paid",
      id: 6,
    },
    {
      title: "Actions",
      id: 7,
    },
  ],
}
export const envTypes = {
  production: "production",
  qa: "qa",
  dev: "dev",
}

export const jobActionLabels = {
  CLOSED: "Completed Jobs",
  ACCEPTED: "Scheduled Jobs",
  SCHEDULED: "Scheduled",
  CHECKED_IN: "Jobs In Progress",
  INVOICE_SENT_FOR_REVIEW: "Invoices to be Completed",
  CHECKED_OUT: "Invoices to be Completed",
  TROUBLESHOOT: "Troubleshooting",
  NEEDS_REVIEW: "Needs Review",
  VIEW_INVOICE: "View Invoice",
  CONTACT: "Contact Us",
  VIEW_JOB: "View Job",
}

export const jobStatusTypes = {
  CHECKED_OUT: "CHECKED_OUT",
  INVOICE_SENT_FOR_REVIEW: "INVOICE_SENT_FOR_REVIEW",
}

export const nextJobCardConstants = {
  title: "Next job coming up",
  bonusIncentive: "Bonus Incentive:",
  tiriosFeeConstant: "Tirios Fee:",
  descriptionTitle: "Problem Description",
}

export const invoiceColumns = {
  description: "Description",
  quantity: "Quantity",
  pricePerUnit: "Price Per Unit",
  total: "Total",
}
