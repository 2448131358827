import { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Transition, Dialog } from "@headlessui/react"
import { Link, useLocation } from "react-router-dom"
import { XIcon } from "@heroicons/react/outline"
import LogoImage from "../../assets/logo.svg"
import { limitedNavigation } from "../../constants/constants"
import { navigation, mobileNavigation } from "../../constants/constants"
import { logOut } from "../../utils/auth"

const ProviderDashboardSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [currentTab, setCurrentTab] = useState("Onboarding")
  const [userRole, setUserRole] = useState(undefined)
  const { pathname } = useLocation()

  useEffect(() => {
    const currentSelectedTab = navigation.find(({ route }) =>
      pathname.includes(route)
    )
    if (currentSelectedTab) {
      setCurrentTab(currentSelectedTab.name)
    }
  }, [pathname])

  useEffect(() => {
    let x = localStorage.getItem("auth")
    setUserRole(JSON.parse(x).data.details.roles[1])
  }, [])
  return (
    <Fragment>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-bg-primary">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <a href="/">
                    <img className="h-8 w-auto" src={LogoImage} alt="Workflow" />
                  </a>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {(userRole === "PENDING_OWNER" || userRole === "PENDING_WORKER") &&
                    limitedNavigation.map(({ name, route, Icon }) =>
                      name === currentTab ? (
                        <Fragment key={name}>
                          <Link
                            to={route}
                            className="bg-gray-900 text-white px-3 py-2 flex items-center rounded-md text-sm font-medium"
                          >
                            <Icon
                              className={
                                "text-white group-hover:text-gray-300 mr-4 flex-shrink-0 h-4 w-4"
                              }
                              aria-hidden="true"
                            />
                            {name}
                          </Link>
                        </Fragment>
                      ) : (
                        <Link
                          key={name}
                          to={route}
                          onClick={() => {
                            if (name === "Logout") {
                              logOut()
                            }
                          }}
                          className={
                            "text-black hover:bg-gray-700 hover:text-white group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                          }
                        >
                          <Icon
                            className={
                              "text-black group-hover:text-gray-300 mr-4 flex-shrink-0 h-4 w-4"
                            }
                            aria-hidden="true"
                          />
                          {name}
                        </Link>
                      )
                    )}
                  {(userRole !== "PENDING_OWNER" || userRole !== "PENDING_WORKER") &&
                    mobileNavigation.map(({ name, route, Icon }) =>
                      name === currentTab ? (
                        <Fragment key={name}>
                          <Link
                            to={route}
                            className="bg-gray-900 text-white px-3 py-2 flex items-center rounded-md text-sm font-medium"
                          >
                            <Icon
                              className={
                                "text-white group-hover:text-gray-300 mr-4 flex-shrink-0 h-4 w-4"
                              }
                              aria-hidden="true"
                            />
                            {name}
                          </Link>
                        </Fragment>
                      ) : (
                        <Link
                          key={name}
                          to={route}
                          onClick={() => {
                            if (name === "Logout") {
                              logOut()
                            }
                          }}
                          className={
                            "text-black hover:bg-gray-700 hover:text-white group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                          }
                        >
                          <Icon
                            className={
                              "text-black group-hover:text-gray-300 mr-4 flex-shrink-0 h-4 w-4"
                            }
                            aria-hidden="true"
                          />
                          {name}
                        </Link>
                      )
                    )}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-bg-primary md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1">
            <div className="flex-1 flex flex-col pt-8 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-5">
                <a href="http://tirios.ai/" target="_blank" rel="noreferrer">
                  <img className="h-8 w-auto" src={LogoImage} alt="Workflow" />
                </a>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {userRole === "PENDING_OWNER" &&
                  limitedNavigation.map(({ name, route, Icon }) =>
                    name === currentTab ? (
                      <Fragment key={name}>
                        <Link
                          to={route}
                          href="#"
                          className="bg-black bg-opacity-10 text-black block px-3 py-2 flex items-center rounded-md text-sm font-medium"
                        >
                          <Icon
                            className={"text-black mr-3 flex-shrink-0 h-4 w-4"}
                            aria-hidden="true"
                          />
                          {name}
                        </Link>
                      </Fragment>
                    ) : (
                      <Link
                        key={name}
                        to={route}
                        onClick={() => {
                          if (name === "Logout") {
                            logOut()
                          }
                        }}
                        className={
                          "text-black hover:bg-gray-700 hover:text-white group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                        }
                      >
                        <Icon
                          className={
                            "text-black group-hover:text-gray-300 mr-3 flex-shrink-0 h-4 w-4"
                          }
                          aria-hidden="true"
                        />
                        {name}
                      </Link>
                    )
                  )}
                {userRole !== "PENDING_OWNER" &&
                  navigation.map(({ name, route, Icon }) =>
                    name === currentTab ? (
                      <Fragment key={name}>
                        <Link
                          to={route}
                          href="#"
                          className="bg-black bg-opacity-10 text-black block px-3 py-2 flex items-center rounded-md text-sm font-medium"
                        >
                          <Icon
                            className={"text-black mr-3 flex-shrink-0 h-4 w-4"}
                            aria-hidden="true"
                          />
                          {name}
                        </Link>
                      </Fragment>
                    ) : (
                      <Link
                        key={name}
                        to={route}
                        onClick={() => {
                          if (name === "Logout") {
                            logOut()
                          }
                        }}
                        className={
                          "text-black hover:bg-gray-700 hover:text-white group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                        }
                      >
                        <Icon
                          className={
                            "text-black group-hover:text-gray-300 mr-3 flex-shrink-0 h-4 w-4"
                          }
                          aria-hidden="true"
                        />
                        {name}
                      </Link>
                    )
                  )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

ProviderDashboardSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
}

export default ProviderDashboardSidebar
