import { GET_ROLES } from "./types"
import { getRoles } from "../services/api"

export const getAllRoles = () => async (dispatch) => {
  try {
    const res = await getRoles()

    dispatch({
      type: GET_ROLES,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
