import { useState } from "react"
import PropTypes from "prop-types"
import { PencilIcon, TrashIcon } from "@heroicons/react/outline"

import Static from "../../../../static.json"
import { roles, tradeCategories } from "../../../../constants/constants"

const Table = ({ columns, data, setStatusModal, handleEditMember }) => {
  const {
    dashboard: {
      members: { screenactioncolumntitle },
    },
  } = Static
  let dataOrderChange = data.sort((a, b) => b.active - a.active)

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {columns.map(({ label, dataKey }) => (
            <th
              key={dataKey}
              scope="col"
              className="sm:px-4 py-3 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {label}
            </th>
          ))}
          <th scope="col" className="relative px-6 py-3">
            <span className="sr-only">{screenactioncolumntitle}</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {dataOrderChange.map(({ memberId, ...person }, index) => (
          <TableRow
            key={memberId}
            columns={columns}
            person={{ memberId, ...person }}
            rowNum={index}
            setStatusModal={setStatusModal}
            handleEditMember={handleEditMember}
          />
        ))}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  setStatusModal: PropTypes.func.isRequired,
  handleEditMember: PropTypes.func.isRequired,
}

Table.defaultProps = {
  data: [],
}

const TableRow = ({ columns, person, rowNum, setStatusModal, handleEditMember }) => {
  const {
    dashboard: {
      members: { activestatus, inactivestatus },
    },
  } = Static
  const { memberId } = person
  const [hovered, setHovered] = useState(false)

  return (
    <tr
      className={
        hovered
          ? "bg-blue-50"
          : person.active === true
          ? "bg-green-50"
          : person.active === false
          ? "bg-red-50"
          : "bg-gray-50"
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {columns.map(({ dataKey }) =>
        dataKey === "active" ? (
          <td
            key={`${memberId}-${dataKey}`}
            className="sm:px-4 py-3 px-2 whitespace-nowrap"
          >
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-700">
              {person[dataKey] ? activestatus : inactivestatus}
            </span>
          </td>
        ) : dataKey === "tradeCategories" || dataKey === "roles" ? (
          <td
            key={`${memberId}-${dataKey}`}
            className="sm:px-2 py-3 px-2 whitespace-wrap"
          >
            {(person[dataKey] || []).map((item, index) => {
              switch (item) {
                case roles.technician.value:
                  item = roles.technician.label
                  break
                case roles.owner.value:
                  item = roles.owner.label
                  break
                case roles.operations.value:
                  item = roles.operations.label
                  break
                case roles.admin.value:
                  item = roles.admin.label
                  break
                case roles.approvedTechnician.value:
                  item = roles.approvedTechnician.label
                  break
                default:
                  item
              }
              return (
                <span
                  key={`${memberId}-${dataKey}-${item}-${index}`}
                  className="px-2 mx-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-700 text-center"
                >
                  {item}
                </span>
              )
            })}
          </td>
        ) : dataKey !== "categories" ? (
          <td
            key={`${memberId}-${dataKey}`}
            className="sm:px-4 py-3 px-2 whitespace-nowrap"
          >
            {person[dataKey]}
          </td>
        ) : (
          <td
            key={`${memberId}-${dataKey}`}
            className="sm:px-4 py-3 px-2 whitespace-nowrap"
          >
            {(person[dataKey] || []).map((item, index) => (
              <span
                key={`${memberId}-${dataKey}-${item}-${index}`}
                className="px-2 mx-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-700"
              >
                {tradeCategories[item]}
              </span>
            ))}
          </td>
        )
      )}
      <td className="sm:px-4 py-3 px-2 flex justify-around items-center h-full">
        <div
          className={`flex justify-around items-center w-full ${
            hovered ? "" : "invisible"
          }`}
        >
          <button onClick={() => handleEditMember(person)}>
            <PencilIcon className="p-1 h-6 w-6 rounded-full bg-green-400 text-white shadow-md" />
          </button>
          <button onClick={() => setStatusModal(person)}>
            {person.active === true ? (
              <TrashIcon className="p-1 h-6 w-6 rounded-full bg-red-700 text-white shadow-md" />
            ) : (
              <TrashIcon className="p-1 h-6 w-6 rounded-full bg-green-700 text-white shadow-md" />
            )}
          </button>
        </div>
      </td>
    </tr>
  )
}

TableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  person: PropTypes.object.isRequired,
  rowNum: PropTypes.number.isRequired,
  setStatusModal: PropTypes.func.isRequired,
  handleEditMember: PropTypes.func.isRequired,
}

export default Table
