import { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

import { RoundedButton } from "../buttons"

import Static from "../../static.json"

const DeclineJobPopupModal = ({ onSubmit, open, setOpen, loading }) => {
  const {
    dashboard: {
      jobs: {
        declinemodal: {
          title,
          description,
          actionlabel1,
          actionlabel2,
          actionlabel3,
        },
      },
    },
  } = Static

  const submitHandler = (reason) => {
    console.log("on submit", reason)
    onSubmit(reason)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pb-2 p-4 text-left overflow-hidden shadow-xl transform transition-all sm:m-0 sm:align-middle sm:max-w-xs sm:w-full sm:p-4">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-full p-1 text-gray-400 bg-gray-200 hover:text-gray-500 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="text-left pb-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <p className="text-gray-600 text-xs mt-4">{description}</p>
                </div>
              </div>
              <div className="my-2">
                <div></div>
              </div>
              <div className="justify-center flex my-2 flex-col">
                <RoundedButton
                  className="w-72 bg-red-500 mb-4 text-white"
                  title={actionlabel1}
                  onClick={() => submitHandler("BUSY")}
                  loading={loading}
                />
                <RoundedButton
                  className="w-72 bg-red-500 mb-4 text-white"
                  title={actionlabel2}
                  onClick={() => submitHandler("TOO_FAR")}
                  loading={loading}
                />
                <RoundedButton
                  className="w-72 bg-red-500 text-white"
                  title={actionlabel3}
                  onClick={() => submitHandler("OTHER")}
                  loading={loading}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

DeclineJobPopupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default DeclineJobPopupModal
