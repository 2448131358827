/* eslint-disable react/no-unescaped-entities */
const PrivacyPolicy = () => {
  const renderContentLine = (content) => <p className="mt-4">{content}</p>
  return (
    <div className="max-w-screen-lg m-auto my-24 text-gray-500 text-xl">
      <h1 className="text-center text-2xl font-bold uppercase">Privacy Policy</h1>

      <h2 className="mt-4 font-bold">
        1. <span className="underline">Introduction</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4">
          Welcome to <b>Tirios Corporation</b>
        </p>
        <p className="mt-4 text-justify">
          Tirios Corporation ("us", "we", or "our") operates Tirios mobile
          application (hereinafter referred to as "<b>Service</b>").
        </p>
        <p className="mt-4 text-justify">
          Our Privacy Policy governs your visit to Tirios mobile application, and
          explains how we collect, safeguard and disclose information that results
          from your use of our Service.
        </p>
        <p className="mt-4 text-justify">
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms used in
          this Privacy Policy have the same meanings as in our Terms and Conditions.
        </p>
        <p className="mt-4 text-justify">
          Our Terms and Conditions (“<b>Terms</b>”) govern all use of our Service and
          together with the Privacy Policy constitutes your agreement with us (“
          <b>agreement</b>”).
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        2. <span className="underline">Definitions</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          <b>SERVICE</b> means the{" "}
          <a
            className="underline text-blue-500"
            href="https://www.tirios.ai"
            target="_blank"
            rel="noreferrer"
          >
            https://www.tirios.ai
          </a>{" "}
          website and Tirios mobile applications operated by us.
        </p>
        <p className="mt-4 text-justify">
          <b>PERSONAL DATA</b> means data about a living individual who can be
          identified from those data (or from those and other information either in
          our possession or likely to come into our possession).
        </p>
        <p className="mt-4 text-justify">
          <b>USAGE DATA</b> is data collected automatically either generated by the
          use of Service or from Service infrastructure itself (for example, the
          duration of a page visit).
        </p>
        <p className="mt-4 text-justify">
          <b>COOKIES</b> are small files stored on your device (computer or mobile
          device).
        </p>
        <p className="mt-4 text-justify">
          <b>DATA CONTROLLER</b> means a natural or legal person who (either alone or
          jointly or in common with other persons) determines the purposes for which
          and the manner in which any personal data are, or are to be, processed. For
          the purpose of this Privacy Policy, we are a Data Controller of your data.
        </p>
        <p className="mt-4 text-justify">
          <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal
          person who processes the data on behalf of the Data Controller. We may use
          the services of various Service Providers in order to process your data
          more effectively.
        </p>
        <p className="mt-4 text-justify">
          <b>DATA SUBJECT</b> is any living individual who is the subject of Personal
          Data.
        </p>
        <p className="mt-4 text-justify">
          <b>THE USER</b> is the individual using our Service. The User corresponds
          to the Data Subject, who is the subject of Personal Data.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        3. <span className="underline">Information Collection and Use</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We collect several different types of information for various purposes to
          provide and improve our Service to you.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        4. <span className="underline">Types of Data Collected</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4  text-justify">
          <b>Personal Data</b>
          <br />
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or identify
          you (“<b>Personal Data</b>”). Personally identifiable information may
          include, but is not limited to:
        </p>
        <p className="mt-4">
          (a) Email address <br />
          (b) First name and last name <br />
          (c) Phone number <br />
          (d) Address, State, Province, ZIP/Postal code, City <br />
          (e) Cookies and Usage Data
        </p>
        <p className="mt-4 text-justify">
          We may use your Personal Data to contact you with newsletters, marketing or
          promotional materials and other information that may be of interest to you.
          You may opt out of receiving any, or all, of these communications from us
          by following the unsubscribe link or by emailing at members@tirios.ai.
        </p>
        <p className="mt-4  text-justify">
          <b>Usage Data</b> <br />
          We may also collect information that your browser sends whenever you visit
          our Service or when you access Service by or through a mobile device (“
          <b>Usage Data</b>”).
        </p>
        <p className="mt-4 text-justify">
          This Usage Data may include information such as your computer's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that you visit, the time and date of your visit, the
          time spent on those pages, unique device identifiers and other diagnostic
          data.
        </p>
        <p className="mt-4 text-justify">
          When you access Service with a mobile device, this Usage Data may include
          information such as the type of mobile device you use, your mobile device
          unique ID, the IP address of your mobile device, your mobile operating
          system, the type of mobile Internet browser you use, unique device
          identifiers and other diagnostic data.
        </p>
        <p className="mt-4 text-justify">
          <b>Location Data</b> <br /> We may use and store information about your
          location if you give us permission to do so (“<b>Location Data</b>”). We
          use this data to provide features of our Service, to improve and customise
          our Service.
        </p>
        <p className="mt-4 text-justify">
          You can enable or disable location services when you use our Service at any
          time by way of your device settings.
        </p>
        <p className="mt-4 text-justify">
          <b>Tracking Cookies Data</b> <br />
          We use cookies and similar tracking technologies to track the activity on
          our Service and we hold certain information.
        </p>
        <p className="mt-4 text-justify">
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are also
          used such as beacons, tags and scripts to collect and track information and
          to improve and analyze our Service.
        </p>
        <p className="mt-4 text-justify">
          You can instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may not be
          able to use some portions of our Service.
        </p>
        <p className="mt-4 text-justify">Examples of Cookies we use:</p>
        <p className="mt-4 text-justify">
          (a) <b>Session Cookies:</b> We use Session Cookies to operate our Service.{" "}
          <br />
          (b) <b>Preference Cookies:</b> We use Preference Cookies to remember your
          preferences and various settings.
          <br />
          (c) <b>Security Cookies:</b> We use Security Cookies for security purposes.
          <br />
          (d) <b>Advertising Cookies:</b> Advertising Cookies are used to serve you
          with advertisements that may be relevant to you and your interests.
        </p>
        <p className="mt-4 text-justify">
          <b>Other Data</b> <br />
          While using our Service, we may also collect the following information:
          sex, age, date of birth, place of birth, passport details, citizenship,
          registration at place of residence and actual address, telephone number
          (work, mobile), details of documents on education, qualification,
          professional training, employment agreements, non-disclosure agreements,
          information on bonuses and compensation, information on marital status,
          family members, social security (or other taxpayer identification) number,
          office location and other data.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        5. <span className="underline">Use of Data</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4">
          Tirios Corporation uses the collected data for various purposes:
        </p>
        <p className="mt-4  text-justify">
          (a) To provide and maintain our Service;
          <br />
          (b) To notify you about changes to our Service;
          <br />
          (c) To allow you to participate in interactive features of our Service when
          you choose to do so;
          <br />
          (d) To provide customer support;
          <br />
          (e) To gather analysis or valuable information so that we can improve our
          Service;
          <br />
          (f) To monitor the usage of our Service;
          <br />
          (g) To detect, prevent and address technical issues;
          <br />
          (h) To fulfill any other purpose for which you provide it;
          <br />
          (i) To carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us, including for billing and
          collection;
          <br />
          (j) To provide you with notices about your account and/or subscription,
          including expiration and renewal notices, email-instructions, etc.;
          <br />
          (k) To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to those
          that you have already purchased or enquired about unless you have opted not
          to receive such information;
          <br />
          (l) In any other way we may describe when you provide the information;
          <br />
          (m) For any other purpose with your consent.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        6. <span className="underline">Retention of Data</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We will retain your Personal Data only for as long as is necessary for the
          purposes set out in this Privacy Policy. We will retain and use your
          Personal Data to the extent necessary to comply with our legal obligations
          (for example, if we are required to retain your data to comply with
          applicable laws), resolve disputes, and enforce our legal agreements and
          policies.
        </p>
        <p className="mt-4 text-justify">
          We will also retain Usage Data for internal analysis purposes. Usage Data
          is generally retained for a shorter period, except when this data is used
          to strengthen the security or to improve the functionality of our Service,
          or we are legally obligated to retain this data for longer time periods.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        7. <span className="underline">Transfer of Data</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province, country
          or other governmental jurisdiction where the data protection laws may
          differ from those of your jurisdiction.
        </p>
        <p className="mt-4 text-justify">
          If you are located outside United States and choose to provide information
          to us, please note that we transfer the data, including Personal Data, to
          United States and process it there.
        </p>
        <p className="mt-4 text-justify">
          Your consent to this Privacy Policy followed by your submission of such
          information represents your agreement to that transfer. We will take all
          the steps reasonably necessary to ensure that your data is treated securely
          and in accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organisation or a country unless there are
          adequate controls in place including the security of your data and other
          personal information.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        8. <span className="underline">Disclosure of Data</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We may disclose personal information that we collect, or you provide:{" "}
          <br />
          <b>(a) Disclosure for Law Enforcement:</b> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Under certain circumstances, we
          may be required to disclose your Personal Data if required to do so by law
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;or in response to valid requests
          by public authorities.
        </p>
        <p className="mt-4 text-justify">
          <b>(b) Business Transaction:</b> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If we or our subsidiaries are
          involved in a merger, acquisition or asset sale, your Personal Data may be
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;transferred.
        </p>
        <p className="mt-4 text-justify">
          <b>(c) Other cases. We may disclose your information also:</b> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i)&nbsp;&nbsp; To our
          subsidiaries and affiliates;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii) &nbsp;To contractors,
          service providers, and other third parties we use to support our business;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iii) To fulfill the purpose for
          which you provide it;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iv) &nbsp;For the purpose of
          including your company’s logo on our website;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(v) &nbsp;&nbsp;For any other
          purpose disclosed by us when you provide the information;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(vi)&nbsp; With your consent in
          any other cases;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;(vii) If we believe disclosure is necessary or
          appropriate to protect the rights, property, or safety of the
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company,
          our customers, or others.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        9. <span className="underline">Security of Data</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          The security of your data is important to us but remember that no method of
          transmission over the Internet or method of electronic storage is 100%
          secure. While we strive to use commercially acceptable means to protect
          your Personal Data, we cannot guarantee its absolute security.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        10.{" "}
        <span className="underline">
          Your Data Protection Rights Under General Data Protection Regulation (GDPR)
        </span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          If you are a resident of the European Union (EU) and European Economic Area
          (EEA), you have certain data protection rights, covered by GDPR. – See more
          at https://eur- lex.europa.eu/eli/reg/2016/679/oj
        </p>
        <p className="mt-4 text-justify">
          We aim to take reasonable steps to allow you to correct, amend, delete, or
          limit the use of your Personal Data.
        </p>
        <p className="mt-4 text-justify">
          If you wish to be informed what Personal Data we hold about you and if you
          want it to be removed from our systems, please email us at info@titios.io.
        </p>
        <p className="mt-4 text-justify">
          In certain circumstances, you have the following data protection rights:
          <br />
          (a) The right to access, update or to delete the information we have on
          you.
          <br />
          (b) The right of rectification. You have the right to have your information
          rectified if that information is
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inaccurate or incomplete.
          <br />
          (c) The right to object. You have the right to object to our processing of
          your Personal Data.
          <br />
          (d) The right of restriction. You have the right to request that we
          restrict the processing of your personal
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;information.
          <br />
          (e) The right to data portability. You have the right to be provided with a
          copy of your Personal Data in a
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;structured, machine-readable and
          commonly used format.
          <br />
          (f) The right to withdraw consent. You also have the right to withdraw your
          consent at any time where we rely &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on
          your consent to process your personal information.
        </p>
        <p className="mt-4 text-justify">
          Please note that we may ask you to verify your identity before responding
          to such requests. Please note, we may not able to provide Service without
          some necessary data.
        </p>
        <p className="mt-4 text-justify">
          You have the right to complain to a Data Protection Authority about our
          collection and use of your Personal Data. For more information, please
          contact your local data protection authority in the European Economic Area
          (EEA).
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        11.{" "}
        <span className="underline">
          Your Data Protection Rights under the California Privacy Protection Act
          (CalOPPA)
        </span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          CalOPPA is the first state law in the nation to require commercial websites
          and online services to post a privacy policy. The law’s reach stretches
          well beyond California to require a person or company in the United States
          (and conceivable the world) that operates websites collecting personally
          identifiable information from California consumers to post a conspicuous
          privacy policy on its website stating exactly the information being
          collected and those individuals with whom it is being shared, and to comply
          with this policy. – See more at:
          https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-
          protection-act-caloppa-3/
        </p>
        <p className="mt-4">According to CalOPPA we agree to the following:</p>
        <p className="mt-4 text-justify">
          (a) Users can visit our site anonymously;
          <br />
          (b) Our Privacy Policy link includes the word “Privacy”, and can easily be
          found on the page specified above on
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the home page of our website;
          <br />
          (c) Users will be notified of any privacy policy changes on our Privacy
          Policy Page;
          <br />
          (d) Users are able to change their personal information by emailing us at
          members@tirios.ai. Our Policy on
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Do Not Track” Signals:
        </p>
        <p className="mt-4 text-justify">
          We honor Do Not Track signals and do not track, plant cookies, or use
          advertising when a Do Not Track browser mechanism is in place. Do Not Track
          is a preference you can set in your web browser to inform websites that you
          do not want to be tracked.
        </p>
        <p className="mt-4 text-justify">
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        12. <span className="underline">Service Providers</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We may employ third party companies and individuals to facilitate our
          Service (“<b>Service Providers</b>”), provide Service on our behalf,
          perform Service-related services or assist us in analysing how our Service
          is used.
        </p>
        <p className="mt-4 text-justify">
          These third parties have access to your Personal Data only to perform these
          tasks on our behalf and are obligated not to disclose or use it for any
          other purpose.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        13. <span className="underline">Analytics</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We may use third-party Service Providers to monitor and analyze the use of
          our Service.
        </p>
        <p className="mt-4 text-justify">
          <b>Google Analytics</b> <br />
          Google Analytics is a web analytics service offered by Google that tracks
          and reports website traffic. Google uses the data collected to track and
          monitor the use of our Service. This data is shared with other Google
          services. Google may use the collected data to contextualise and
          personalise the ads of its own advertising network.
        </p>
        <p className="mt-4 text-justify">
          For more information on the privacy practices of Google, please visit the
          Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
        </p>
        <p className="mt-4 text-justify">
          We also encourage you to review the Google's policy for safeguarding your
          data: https://support.google.com/analytics/answer/6004245.
        </p>
        <p className="mt-4 text-justify">
          <b>Firebase </b>
          <br />
          Firebase is analytics service provided by Google Inc. You may opt-out of
          certain Firebase features through your mobile device settings, such as your
          device advertising settings or by following the instructions provided by
          Google in their Privacy Policy: https://policies.google.com/privacy?hl=en
        </p>
        <p className="mt-4 text-justify">
          For more information on what type of information Firebase collects, please
          visit the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
        </p>
        <p className="mt-4 text-justify">
          <b>Flurry Analytics</b> <br />
          Flurry Analytics service is provided by Yahoo! Inc. You can opt-out from
          Flurry Analytics service to prevent Flurry Analytics from using and sharing
          your information by visiting the Flurry's Opt-out page:
          https://dev.flurry.com/secure/optOut.do
        </p>
        <p className="mt-4 text-justify">
          For more information on the privacy practices and policies of Yahoo!,
          please visit their Privacy Policy page:
          https://policies.yahoo.com/us/en/yahoo/privacy/policy/index.htm
        </p>
        <p className="mt-4">
          <b>Mixpanel</b> <br />
          Mixpanel is provided by Mixpanel Inc.
        </p>
        <p className="mt-4 text-justify">
          You can prevent Mixpanel from using your information for analytics purposes
          by opting-out. To opt-out of Mixpanel service, please visit this page:
          https://mixpanel.com/optout/
        </p>
        <p className="mt-4 text-justify">
          For more information on what type of information Mixpanel collects, please
          visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/
        </p>
        <p className="mt-4">
          <b>Appsflyer</b> <br />
          AppsFlyer is a mobile attribution platform
        </p>
        <p className="mt-4 text-justify">
          For more information on what type of information AppsFlyer collects, please
          visit their Privacy Policy page: https://www.appsflyer.com/privacy-policy/
          and terms of use at https://www.appsflyer.com/terms-of-use/.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        14. <span className="underline">Advertising</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We may use third-party Service Providers to show advertisements to you to
          help support and maintain our Service.
        </p>
        <p className="mt-4 text-justify">
          <b>Google AdSense DoubleClick Cookie</b> <br />
          Google, as a third party vendor, uses cookies to serve ads on our Service.
          Google's use of the DoubleClick cookie enables it and its partners to serve
          ads to our users based on their visit to our Service or other websites on
          the Internet.
        </p>
        <p className="mt-4 text-justify">
          You may opt out of the use of the DoubleClick Cookie for interest-based
          advertising by visiting the Google Ads Settings web page:
          http://www.google.com/ads/preferences/
        </p>
        <p className="mt-4">
          <b>Bing Ads</b> <br />
          Bing Ads is an advertising service provided by Microsoft Inc.
        </p>
        <p className="mt-4 text-justify">
          You can opt-out from Bing Ads by following the instructions on Bing Ads
          Opt-out page:
          https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
        </p>
        <p className="mt-4 text-justify">
          For more information about Bing Ads, please visit their Privacy Policy:
          https://privacy.microsoft.com/en-us/PrivacyStatement
        </p>
        <p className="mt-4">
          <b>AdMob by Google</b> <br />
          AdMob by Google is provided by Google Inc.
        </p>
        <p className="mt-4 text-justify">
          You can opt-out from the AdMob by Google service by following the
          instructions described by Google:
          https://support.google.com/ads/answer/2662922?hl=en
        </p>
        <p className="mt-4 text-justify">
          For more information on how Google uses the collected information, please
          visit the “How Google uses data when you use our partners' sites or app”
          page: http://www.google.com/policies/privacy/partners/ or visit the Privacy
          Policy of Google: http://www.google.com/policies/privacy/
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        15. <span className="underline">Behavioral Remarketing</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We use remarketing services to advertise on third party websites to you
          after you visited our Service. We and our third-party vendors use cookies
          to inform, optimise and serve ads based on your past visits to our Service.
        </p>
        <p className="mt-4">
          <b>Google Ads (AdWords)</b> <br />
          Google Ads (AdWords) remarketing service is provided by Google Inc.
        </p>
        <p className="mt-4 text-justify">
          You can opt-out of Google Analytics for Display Advertising and customise
          the Google Display Network ads by visiting the Google Ads Settings page:
          http://www.google.com/settings/ads
        </p>
        <p className="mt-4 text-justify">
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on – https://tools.google.com/dlpage/gaoptout – for your web browser.
          Google Analytics Opt-out Browser Add-on provides visitors with the ability
          to prevent their data from being collected and used by Google Analytics.
        </p>
        <p className="mt-4 text-justify">
          For more information on the privacy practices of Google, please visit the
          Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
        </p>
        <p className="mt-4 text-justify">
          <b>Bing Ads Remarketing</b> <br />
          Bing Ads remarketing service is provided by Microsoft Inc. You can opt-out
          of Bing Ads interest-based ads by following their instructions:
          https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          You can learn more about the privacy practices and policies of Microsoft by
          visiting their Privacy Policy page:
          https://privacy.microsoft.com/en-us/PrivacyStatement
        </p>
        <p className="mt-4 text-justify">
          <b>Twitter</b> <br />
          Twitter remarketing service is provided by Twitter Inc. You can opt-out
          from Twitter's interest-based ads by following their instructions:
          https://support.twitter.com/articles/20170405 You can learn more about the
          privacy practices and policies of Twitter by visiting their Privacy Policy
          page: https://twitter.com/privacy
        </p>
        <p className="mt-4">
          <b>Facebook</b> <br />
          Facebook remarketing service is provided by Facebook Inc.
        </p>
        <p className="mt-4 text-justify">
          You can learn more about interest-based advertising from Facebook by
          visiting this page: https://www.facebook.com/help/164968693837950
        </p>
        <p className="mt-4 text-justify">
          To opt-out from Facebook's interest-based ads, follow these instructions
          from Facebook: https://www.facebook.com/help/568137493302217
        </p>
        <p className="mt-4 text-justify">
          Facebook adheres to the Self-Regulatory Principles for Online Behavioural
          Advertising established by the Digital Advertising Alliance. You can also
          opt-out from Facebook and other participating companies through the Digital
          Advertising Alliance in the USA http://www.aboutads.info/choices/, the
          Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/
          or the European Interactive Digital Advertising Alliance in Europe
          http://www.youronlinechoices.eu/, or opt-out using your mobile device
          settings.
        </p>
        <p className="mt-4 text-justify">
          For more information on the privacy practices of Facebook, please visit
          Facebook's Data Policy: https://www.facebook.com/privacy/explanation
        </p>
        <p className="mt-4">
          <b>Pinterest</b> <br />
          Pinterest remarketing service is provided by Pinterest Inc.
        </p>
        <p className="mt-4 text-justify">
          You can opt-out from Pinterest's interest-based ads by enabling the “Do Not
          Track” functionality of your web browser or by following Pinterest
          instructions:
          http://help.pinterest.com/en/articles/personalization-and-data
        </p>
        <p className="mt-4 text-justify">
          You can learn more about the privacy practices and policies of Pinterest by
          visiting their Privacy Policy page:
          https://about.pinterest.com/en/privacy-policy
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        16. <span className="underline">Payments</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We may provide paid products and/or services within Service. In that case,
          we use third-party services for payment processing (e.g. payment
          processors).
        </p>
        <p className="mt-4 text-justify">
          We will not store or collect your payment card details. That information is
          provided directly to our third-party payment processors whose use of your
          personal information is governed by their Privacy Policy. These payment
          processors adhere to the standards set by PCI-DSS as managed by the PCI
          Security Standards Council, which is a joint effort of brands like Visa,
          Mastercard, American Express and Discover. PCI-DSS requirements help ensure
          the secure handling of payment information.
        </p>
        <p className="mt-4">The payment processors we work with are:</p>
        <p className="mt-4 text-justify">
          <b>PayPal or Braintree:</b> <br />
          Their Privacy Policy can be viewed at
          https://www.paypal.com/webapps/mpp/ua/privacy-full
        </p>
        <p className="mt-4">
          <b>Stripe:</b> <br />
          Their Privacy Policy can be viewed at: https://stripe.com/us/privacy
        </p>
        <p className="mt-4">
          <b>Payrix:</b> <br />
          Their Privacy Policy can be viewed at: https://portal.payrix.com/privacy
        </p>
        <p className="mt-4">
          <b>Square:</b> <br />
          Their Privacy Policy can be viewed at:
          https://squareup.com/us/en/legal/general/privacy
        </p>
        <p className="mt-4">
          <b>Go Cardless:</b> <br />
          Their Privacy Policy can be viewed at: https://gocardless.com/legal/privacy
        </p>
        <p className="mt-4">
          <b>Elavon:</b> <br />
          Their Privacy Policy can be viewed at:
          https://www.elavon.com/privacy-pledge.html
        </p>
        <p className="mt-4">
          <b>Verifone:</b> <br />
          Their Privacy Policy can be viewed at: https://www.verifone.com/en/privacy
        </p>
        <p className="mt-4">
          <b>Moneris:</b> <br />
          Their Privacy Policy can be viewed at:
          https://www.moneris.com/legal/privacy-policy
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        17. <span className="underline">Links to Other Sites</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          Our Service may contain links to other sites that are not operated by us.
          If you click a third party link, you will be directed to that third party's
          site. We strongly advise you to review the Privacy Policy of every site you
          visit.
        </p>
        <p className="mt-4 text-justify">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        18. <span className="underline">Children's Privacy</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          Our Service does not address anyone under the age of 18 (“<b>Children</b>
          ”).
        </p>
        <p className="mt-4 text-justify">
          We do not knowingly collect personally identifiable information from anyone
          under the age of 18. If you are a parent or guardian and you are aware that
          your Child has provided us with Personal Data, please contact us. If we
          become aware that we have collected Personal Data from children without
          verification of parental consent, we take steps to remove that information
          from our servers.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        19. <span className="underline">Changes to This Privacy Policy</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4 text-justify">
          We may update our Privacy Policy from time to time. We will notify you of
          any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="mt-4 text-justify">
          We will let you know via email and/or a prominent notice on our Service,
          prior to the change becoming effective and update “effective date” at the
          top of this Privacy Policy.
        </p>
        <p className="mt-4 text-justify">
          You are advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on this
          page.
        </p>
      </div>

      <h2 className="mt-4 font-bold">
        20. <span className="underline">Contact Us</span>
      </h2>
      <div className="ml-6">
        <p className="mt-4">
          If you have any questions about this Privacy Policy, please contact us:
        </p>
        <p className="mt-4">By email: members@tirios.ai</p>
        <p className="mt-4">
          By visiting this page on our website: https://www.tirios.ai
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
