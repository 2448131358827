import axios from "axios"
import { API_URL } from "../config"
import { getUser } from "../utils/auth"

const instance = axios.create({
  baseURL: API_URL,
})

export function authHeader() {
  if (!getUser()) return
  const user = JSON.parse(getUser())
  const {
    data: { idToken },
  } = user

  return idToken
}

instance.interceptors.request.use(
  (config) => {
    const token = authHeader()
    if (token) {
      config.headers.Authorization = token
    }

    return config
  },
  (error) => Promise.reject(error)
)

export default instance
