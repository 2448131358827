import { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

import { GreenCheckbox } from "../../../../components/inputs"
import { RoundedButton } from "../../../../components/buttons"
import userIcon from "../../../../assets/user.png"

import Static from "../../../../static.json"

const AssignPopupModal = ({ onSubmit, open, setOpen, members, category }) => {
  const {
    dashboard: {
      jobs: {
        modal: { title, actionlabel },
      },
    },
  } = Static
  //const [loading, setLoading] = useState(false)
  const [member, setMember] = useState()

  const submitHandler = (values) => {
    console.log("on submit", member)
    onSubmit(member)
  }
  let assignButtonEnable = members.length > 0 ? false : true
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pb-2 text-left overflow-hidden shadow-xl transform transition-all sm:m-0 sm:align-middle sm:max-w-xs sm:w-full sm:p-0">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className=" py-4 text-center border-b border-2ray-400">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-red-600"
                  >
                    {title}
                  </Dialog.Title>
                </div>
              </div>
              <div className="my-2">
                <div className="border-b border-gray-200 text-center p-2">
                  {members.length < 1 ? (
                    // <div>No {category} available</div>
                    <div>No Technician available</div>
                  ) : (
                    members.map(({ memberId, firstname, lastname }) => (
                      <div
                        key={memberId}
                        className="my-2 border-b border-gray-200 px-4"
                      >
                        <GreenCheckbox
                          id={memberId.toString()}
                          onChange={() => setMember(memberId)}
                          name={"assigner"}
                          label={
                            <div className="flex my-2 w-full font-medium text-base">
                              <img
                                className="h-5 w-5 rounded-full mr-2 ml-3"
                                src={userIcon}
                                alt=""
                              />
                              {`${firstname} ${lastname}`}
                            </div>
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              {
                <div className="justify-center flex my-2">
                  <RoundedButton
                    variant="primary"
                    title={actionlabel}
                    onClick={submitHandler}
                    disabled={assignButtonEnable}
                  />
                </div>
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

AssignPopupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AssignPopupModal
