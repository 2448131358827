import { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { JobCard } from "../../../../components/cards"
import Toolbar from "../../../../components/Toolbar"
import moment from "moment"
import {
  AcceptJobPopupModal,
  DeclineJobPopupModal,
} from "../../../../components/popup"
import { showErrorNotification } from "../../../../utils/helper"
import Static from "../../../../static.json"
import {
  memberAssignJob,
  acceptJobOffer,
  declineJobOffer,
} from "../../../../actions/job"
import { checkMediaScreen } from "../../../../utils/helper"

const ProviderDashboard = ({
  members,
  jobOffers,
  jobs,
  memberAssignJob,
  acceptJobOffer,
  declineJobOffer,
}) => {
  const {
    dashboard: {
      index: { pagetitle, title, subtitle1, subtitle2, subtitle3 },
    },
  } = Static

  let current = null
  let next = null
  const sortedJobOffers = jobOffers.sort((a, b) => a.endTime - b.endTime)
  const openJobOffers = sortedJobOffers.filter(
    ({ status: { description } }) => description === "OPEN"
  )

  const checkExpiry = (expiryTime) => {
    const toMoment = moment(expiryTime)
    const fromMoment = moment(new Date())
    return toMoment.diff(fromMoment, "minutes")
  }
  if (openJobOffers.length > 0) {
    current = openJobOffers[0]
  }

  const sortedJobs = jobs
    .sort((a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime))
    .filter(({ status: { description } }) => description === "ACCEPTED")
  next = sortedJobs.length > 0 ? sortedJobs[0] : null

  const [acceptJobId, setAcceptJobId] = useState()
  const [declineJobId, setDeclineJobId] = useState()
  const [acceptModalOpen, setAcceptModalOpen] = useState(false)
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  const [acceptJobOfferLoading, setAcceptJobOfferLoading] = useState(false)
  const [declineJobOfferLoading, setDeclineJobOfferLoading] = useState(false)

  useEffect(() => {
    document.title = pagetitle
  }, [])

  const onAcceptHandler = (jobId) => {
    console.log("[Clicked onAccept]")
    setAcceptJobId(jobId)
    setAcceptModalOpen(true)
  }

  const onAcceptSubmitHandler = (memberId) => {
    setAcceptJobOfferLoading(true)
    acceptJobOffer(memberId, acceptJobId)
      .then(() => {
        setAcceptJobOfferLoading(false)
        setAcceptModalOpen(false)
      })
      .catch((err) => {
        setAcceptJobOfferLoading(false)
        showErrorNotification(err)
      })
  }

  const onDeclineHandler = (jobId) => {
    console.log("[Clicked onDecline]")
    setDeclineModalOpen(true)
    setDeclineJobId(jobId)
  }

  const onDeclineSubmitHandler = (reason) => {
    setDeclineJobOfferLoading(true)
    declineJobOffer(declineJobId, reason)
      .then(() => {
        setDeclineJobOfferLoading(false)
        setDeclineModalOpen(false)
      })
      .catch((err) => {
        showErrorNotification(err)
        setDeclineJobOfferLoading(false)
      })
  }

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 flex flex-col">
      <AcceptJobPopupModal
        onSubmit={onAcceptSubmitHandler}
        open={acceptModalOpen}
        setOpen={setAcceptModalOpen}
        members={members.filter(
          ({ categories, roles }) =>
            categories &&
            current &&
            roles &&
            roles.includes("WORKER") &&
            categories.includes(current.job.serviceCategory.description)
        )}
        loading={acceptJobOfferLoading}
      />
      <DeclineJobPopupModal
        onSubmit={onDeclineSubmitHandler}
        open={declineModalOpen}
        setOpen={setDeclineModalOpen}
        loading={declineJobOfferLoading}
      />
      <h3 className="text-gray-600 text-xl">{title}</h3>
      <div className="my-3">
        <h2 className="text-gray-700 font-medium">{subtitle1}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          {current && (
            <JobCard
              job={current}
              onAccept={onAcceptHandler}
              onDecline={onDeclineHandler}
            />
          )}
        </div>
        <h2 className="text-gray-700 font-medium">{subtitle2}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2">
          {next && (
            <JobCard
              job={next}
              onAccept={onAcceptHandler}
              onDecline={onDeclineHandler}
              isNext
            />
          )}
        </div>
        <h2 className="text-gray-700 font-medium">{subtitle3}</h2>
        <div className="border-t border-gray-300 flex sm:flex-row flex-col items-center py-4 my-2" />
      </div>
      <Toolbar />
    </div>
  )
}

ProviderDashboard.propTypes = {
  members: PropTypes.array.isRequired,
  jobOffers: PropTypes.array.isRequired,
  jobs: PropTypes.array.isRequired,
  memberAssignJob: PropTypes.func.isRequired,
  acceptJobOffer: PropTypes.func.isRequired,
  declineJobOffer: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    members: state.members,
    jobOffers: state.job.jobOffers,
    jobs: state.job.jobs,
  }
}

const mapDispatchToProps = {
  memberAssignJob,
  acceptJobOffer,
  declineJobOffer,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDashboard)
