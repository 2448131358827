import React, { useState, useEffect, useCallback } from "react"
import { useHistory, useParams, Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import moment from "moment"

import { ChevronLeftIcon } from "@heroicons/react/outline"

// import { mockInvoiceDetails } from "../../../../../constants/invoices"
import { getInvoiceById } from "../../../../../services/api"
import { invoicesData, invoiceColumns } from "../../../../../constants/constants"

const tableHead = ["Description", "Quantity", "Price Per Unit", "Total"]

const PaidInvoice = ({ date }) => {
  const history = useHistory()
  const { invoiceId } = useParams()
  const [invoiceDetails, setInvoiceDetails] = useState()
  const [invoiceAddress, setInvoiceAddress] = useState()
  const [invoiceParts, setInvoiceParts] = useState()
  const [totalInvoiceLabor, setTotalInvoiceLabor] = useState()
  const [subTotal, setSubTotal] = useState()
  const [taxes, setTaxes] = useState(0)
  const [totalPayment, setTotalPayment] = useState()
  const [invoiceStatus, setInvoiceStatus] = useState()
  const [incentive, setIncentive] = useState()
  const [incentiveLabel, setIncentiveLabel] = useState()
  const [fee, setFee] = useState()
  const [feeLabel, setFeeLabel] = useState()

  if (!date) {
    return <Redirect to={"/provider/dashboard/payments/invoices/"} />
  }

  const { description, quantity, pricePerUnit, total } = invoiceColumns

  useEffect(() => {
    const getInvoiceDetailsById = async () => {
      try {
        const res = await getInvoiceById(invoiceId)
        const data = res.data.data
        console.log("DATA", data)
        const { invoice, job, invoiceLines } = data

        const sumArr = []
        const invoicePartsArr = invoiceLines
        invoicePartsArr.forEach((part) => {
          const total = part.itemQty * part.itemPrice
          sumArr.push(total)
        })
        const sTotal =
          sumArr.reduce((prev, curr) => prev + curr, 0) + invoice?.totalLabor
        setSubTotal(sTotal)
        setInvoiceDetails(invoice)
        setInvoiceAddress(job)
        setInvoiceParts(invoicePartsArr)
        setTotalInvoiceLabor(invoice?.totalLabor)
        setTotalPayment(invoice?.total)
        setTaxes(invoice?.totalTax)
        setInvoiceStatus(() => {
          return invoicesData.invoiceStatuses[invoice?.invoiceStatus].value
        })
        setIncentive(
          invoice?.tiriosIncentiveAmount ? invoice?.tiriosIncentiveAmount : 0
        )
        setFee(invoice?.tiriosFeeAmount ? invoice?.tiriosFeeAmount : 0)
        setIncentiveLabel(job?.tiriosIncentive)
        setFeeLabel(job?.tiriosFee)
        return
      } catch (err) {
        console.log(err)
      }
    }
    getInvoiceDetailsById()
  }, [])

  return (
    <div className="py-4 pl-3 lg:pr-12 pr-3 relative">
      <button
        onClick={() => history.goBack()}
        className="flex mb-6 mt-3 text-gray-500"
      >
        <ChevronLeftIcon className="text-gray-500 h-6 w-8" />
        Back to Invoices
      </button>
      <h1 className="text-4xl mt-5 mb-5 tracking-wide text-gray-primary">
        Invoices
      </h1>
      {/* Invoice Details Container */}
      <p className="text-green-primary font-bold">{invoiceStatus} </p>
      {/* Invoice Address */}
      <div className="absolute right-14 top-20 text-right font-bold">
        <p>{moment(`${date}z`).format("MMM DD, YYYY")}</p>
        <p> {invoiceAddress?.property.addressLine1} </p>
        <p>
          {invoiceAddress?.property.city}, {invoiceAddress?.property.state}{" "}
          {invoiceAddress?.property.zipCode}
        </p>
        <p> Invoice # {invoiceDetails?.invoiceId}</p>
      </div>
      <div
        className="table-fixed w-full bg-white mt-4 shadow flex p-4 rounded-md"
        // style={styles.reviewContainer}
      >
        <div className="w-2/3 max-w-5xl ">
          <table className="table-fixed bg-white w-full">
            <thead className="border-b-2 border-gray-light">
              <tr>
                <th className="text-left w-1/4 text-gray-primary font-normal pb-2">
                  {description}
                </th>
                <th className="text-left w-1/4 text-gray-primary font-normal pb-2 ">
                  {quantity}
                </th>
                <th className="text-left w-1/4 text-gray-primary font-normal pb-2 ">
                  {pricePerUnit}
                </th>
                <th className="text-right w-1/4 pr-12 text-gray-primary font-normal pb-2 ">
                  {total}
                </th>
              </tr>
            </thead>
          </table>
          <InvoiceRow
            description="Labor"
            quantity="--"
            pricePerUnit=" --"
            total={totalInvoiceLabor}
          />
          {invoiceParts?.map(({ partName, itemPrice, itemQty }, index) => {
            return (
              <div key={index}>
                <InvoiceRow
                  description={`${partName}`}
                  quantity={itemQty}
                  pricePerUnit={parseFloat(itemPrice).toFixed(2)}
                  total={itemQty * itemPrice}
                />
              </div>
            )
          })}
          <div className="rounded-md mt-12 pl-4 pr-12 pt-4 pb-6 bg-gray-bg">
            <div className="flex justify-between pb-6">
              <p>Subtotal</p>
              <p> ${subTotal ? subTotal.toFixed(2) : ""} </p>
            </div>
            <div className="flex justify-between pb-6">
              <p>Taxes, 8.25%</p>
              <p>
                {" "}
                +${taxes
                  ? parseFloat(Math.round(taxes * 100) / 100).toFixed(2)
                  : ""}{" "}
              </p>
            </div>
            {incentive > 0 && (
              <div className="flex justify-between pb-6">
                <p>Bonus Incentive, {incentiveLabel}</p>
                <p> +${incentive?.toFixed(2)} </p>
              </div>
            )}
            <div className="flex justify-between pb-6">
              <p>Tirios Service Fee, {feeLabel ? feeLabel : "0%"}</p>
              <p> -${fee?.toFixed(2)} </p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Total Payment</p>
              <p>
                {" "}
                $
                {totalPayment
                  ? parseFloat(Math.round(totalPayment * 100) / 100).toFixed(2)
                  : ""}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const InvoiceRow = ({ description, quantity, pricePerUnit, total }) => {
  return (
    <div className=" mt-2 mb-2 rounded-md flex justify-between bg-gray-bg">
      <td className="p-4 h-16  w-1/4">{description}</td>
      <td className="p-4 h-16  w-1/4">{quantity}</td>
      <td className="p-4 h-16  w-1/4">${pricePerUnit}</td>
      <td className="p-4 h-16  w-1/4 text-right mr-8">
        ${total && total.toFixed(2)}
      </td>
    </div>
  )
}

InvoiceRow.propTypes = {
  description: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  pricePerUnit: PropTypes.string.isRequired,
  total: PropTypes.string,
  date: PropTypes.string,
}

PaidInvoice.propTypes = {
  date: PropTypes.string,
}
export default PaidInvoice
