import { GET_PROVIDER_PROFIEL, UPDATE_PROVIDER_PROFILE } from "./types"
import { getCompanyProfileInfo, updateProviderProfile } from "../services/api"
import { getProviderId } from "../utils/auth"

export const getProviderProfile = () => async (dispatch) => {
  try {
    const res = await getCompanyProfileInfo(getProviderId())

    dispatch({
      type: GET_PROVIDER_PROFIEL,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const updateProviderProfileInfo = (data) => async (dispatch) => {
  try {
    const res = await updateProviderProfile(getProviderId(), data)

    dispatch({
      type: UPDATE_PROVIDER_PROFILE,
      payload: res.data.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
