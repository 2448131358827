import axios from "axios"
import { API_URL } from "../config"
import http from "./http"

export const Signin = ({ email, password }) =>
  axios.post(`${API_URL}authorization/users/authenticate`, { email, password })

export const Signup = (data) =>
  axios.post(`${API_URL}serviceProvider/provider/create`, data)

export const ForgotPassword = (data) =>
  axios.post(`${API_URL}authorization/users/forgotPassword`, data)

export const ChangePassword = (idToken, username, ObjData) =>
  axios.put(
    `${API_URL}authorization/users/${username}/password`,
    {
      accessToken: ObjData.accessToken,
      oldPassword: ObjData.oldPassword,
      newPassword: ObjData.newPassword,
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  )

export const SetPassword = (data) =>
  axios.post(`${API_URL}authorization/users/resetPassword`, data)
export const ResetPasswordByEmail = ({ email, password }) =>
  axios.patch(`${API_URL}authorization/users/password`, { email, password })

export const ResetPasswordByPhone = ({ phoneNumber, password }) =>
  axios.patch(`${API_URL}authorization/users/password`, { phoneNumber, password })

export const SendVerificationNotification = () =>
  axios.post(
    "https://iznuyhgua2.execute-api.us-east-1.amazonaws.com/gndev/notificationRequest",
    {
      channels: [
        {
          name: "EMAIL",
        },
      ],
      receipientDetails: [
        {
          firstName: "Tirios",
          lastName: "Administrator",
          toEmail: ["ngnsimon1991@gmail.com"],
          ccEmail: ["sachin@tirios.ai", "phani@tirios.ai"],
        },
      ],
      emailSubject: "[dev] ATTN: Provider Owner Requested a Background Check",
      emailMessageBody:
        "Owner Owner Requested a Background Check \n\n Verify they have successfully completed Identity Verification, and have their Insurance and License is loaded before initiating the background check.",
    }
  )

export const VerifyEmailAndPhonenumber = ({
  phoneNumber,
  email,
  firstName,
  lastName,
}) =>
  axios.get(
    `${API_URL}serviceProvider/provider/search?email=${email}&phoneNumber=${phoneNumber}&firstName=${firstName}&lastName=${lastName}`
  )
