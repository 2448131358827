import PropTypes from "prop-types"
import CompanyInformation from "./CompanyInformation"
import Conditions from "./Conditions"
import SuccessScreen from "./SuccessScreen"
import { signUpNavigation } from "../../../constants/constants"

import LogoImage from "../../../assets/logo.svg"

const SignUpInformation = ({ steps, onSubmit, setSteps, loading }) => {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Static sidebar for desktop */}
      <div className="hidden bg-bg-primary md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-8 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-5">
              <img className="h-8 w-auto" src={LogoImage} alt="Workflow" />
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 space-y-1">
                {signUpNavigation.map((item, index) => (
                  <div
                    key={item.name}
                    className={
                      "text-black hover:none flex items-center px-3 py-2 text-sm font-medium rounded-md"
                    }
                  >
                    <div className="inset-y-0 right-0 mr-3 p-0.5 bg-primary rounded-xl flex items-center pointer-events-none">
                      <item.icon
                        className={`${
                          index < steps
                            ? "h-4 w-4 text-white"
                            : "h-4 w-4 text-primary"
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                    {item.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-10 bg-primary shadow" />
        {steps === 1 ? (
          <CompanyInformation onSubmit={onSubmit} />
        ) : steps === 2 ? (
          <Conditions onSubmit={onSubmit} setSteps={setSteps} loading={loading} />
        ) : (
          <SuccessScreen onSubmit={onSubmit} />
        )}
        {/* <CompanyInformation onSubmit={onSubmit} /> */}
        {/*<Conditions onSubmit={onSubmit} setSteps={setSteps} loading={loading} />*/}
        {/*{steps === 1 ? (*/}
        {/*  <CompanyInformation onSubmit={onSubmit} />*/}
        {/*) : steps === 2 ? (*/}
        {/*  <Conditions onSubmit={onSubmit} setSteps={setSteps} loading={loading} />*/}
        {/*) : (*/}
        {/*  <SuccessScreen onSubmit={onSubmit} />*/}
        {/*)}*/}
      </div>
    </div>
  )
}

SignUpInformation.propTypes = {
  steps: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

SignUpInformation.defaultProps = {
  steps: 1,
  loading: false,
}

export default SignUpInformation
