import { useState, useEffect } from "react"
import { Field, Form } from "react-final-form"
import PropTypes from "prop-types"
import { RoundedButton } from "../../../../components/buttons"
import SearchLocation from "../../SignUp/LocationSearchInput"
import {
  TextInputField,
  SelectInputField,
  SelectMenu,
  TextInput,
} from "../../../../components/inputs"
import {
  emailValidationPattern,
  urlValidationPattern,
  businessTypeOptions,
  stateOptions,
  tiriosURL,
  VALIDATE_WEBSITE,
} from "../../../../constants/constants"
import Static from "../../../../static.json"

const AboutBusiness = ({ onSubmit }) => {
  const {
    dashboard: {
      payments: {
        actionbuttonlabel,
        input: {
          businesslabel,
          businessaddresslabel,
          businessnameplaceholder,
          businesscategoriesplaceholder,
          doingbusinessasplaceholder,
          taxlabel,
          address1placeholder,
          address2placeholder,
          taxidplaceholder2,
          taxidplaceholder3,
          datebusinessestablishedplaceholder,
          businessphoneplaceholder,
          employeesnumberplaceholder,
          cityplaceholder,
          stateplaceholder,
          zipplaceholder,
          countrydefault,
          emailplaceholder,
          anotheremployeelabel,
          websiteplaceholder,
          countryplaceholder,
        },
        validation: {
          categoryvalidation,
          businessdatevalidation,
          // einvalidation,
          einvalidation1,
          einvalidation2,
          businessphonevalidation,
          employeesnumbervalidation,
          addressvalidation,
          cityvalidation,
          statevalidation,
          zipvalidation,
          emailvalidation,
          websiteValidation,
        },
        countryOptions,
      },
    },
  } = Static
  const [addressObject, setAddressObject] = useState({})
  const [error, setError] = useState({})

  const addBusiness = (values) => {
    const addressError = {}
    const { address1, city, state, zip, website } = addressObject
    console.log(addressObject)
    if (!website) {
      addressObject.website = tiriosURL
      console.log("New website: ", addressObject.website)
    } else if (!VALIDATE_WEBSITE.test(website)) {
      let trimWebsite =
        addressObject.website !== null && addressObject.website.trim()
      addressObject.website = `http://${trimWebsite}`
    }
    if (!address1) {
      addressError.address1 = addressvalidation
    }
    if (!city) {
      addressError.city = cityvalidation
    }
    if (!state) {
      addressError.state = statevalidation
    }
    if (!zip) {
      addressError.zip = zipvalidation
    }
    if (Object.keys(addressError).length === 0) {
      onSubmit({ ...values, ...addressObject, country: "USA" })
    } else {
      setError(addressError)
    }
  }
  // Prevent default behavior of form submission on 'enter'.
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [])
  return (
    <div className="py-6 px-8 bg-white">
      <Form
        onSubmit={addBusiness}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols sm:grid-cols-2 gap-6">
              <div>
                <Field
                  type="text"
                  label={businesslabel}
                  name="name"
                  placeholder={businessnameplaceholder}
                  component={TextInputField}
                  widthFull
                />
                {/* <Field
                  type="text"
                  name="businessas"
                  placeholder={doingbusinessasplaceholder}
                  component={TextInputField}
                  widthFull
                /> */}
                <Field
                  type="select"
                  name="categories"
                  options={businessTypeOptions}
                  component={SelectInputField}
                  placeholder={businesscategoriesplaceholder}
                  widthFull
                  validate={(value) => (value ? undefined : categoryvalidation)}
                  required
                />
                <div className="flex">
                  <label className="flex w-40 text-sm font-medium text-text-primary items-center text-center">
                    {taxlabel}
                  </label>
                  <Field
                    // type="text"
                    type="number"
                    name="tax.mid"
                    // maxLength="2"
                    placeholder={taxidplaceholder2}
                    component={TextInputField}
                    validate={(value) =>
                      value && value.length == 2 ? undefined : einvalidation1
                    }
                    className="w-28 px-2"
                    widthFull
                  />
                  <Field
                    // type="text"
                    type="number"
                    name="tax.suf"
                    // maxLength="7"
                    placeholder={taxidplaceholder3}
                    component={TextInputField}
                    className="w-30"
                    widthFull
                    validate={(value) =>
                      value && value.length == 7 ? undefined : einvalidation2
                    }
                  />
                </div>
                {/* <Field
                  type="text"
                  name="tcAcceptDate"
                  placeholder={datebusinessestablishedplaceholder}
                  component={TextInputField}
                  widthFull
                  validate={(value) => (value ? undefined : businessdatevalidation)}
                  required
                /> */}
                <Field
                  type="number"
                  name="phone"
                  placeholder={businessphoneplaceholder}
                  component={TextInputField}
                  widthFull
                  validate={(value) =>
                    value && value.length == 10 ? undefined : businessphonevalidation
                  }
                  required
                />
                <Field
                  validateOnBlur
                  validate={(value) =>
                    !emailValidationPattern.test(value) && emailvalidation
                  }
                  required
                  type="email"
                  name="email"
                  placeholder={emailplaceholder}
                  component={TextInputField}
                  widthFull
                />
                {/* <div className="my-3">
                  <Field
                    type="check"
                    title={anotheremployeelabel}
                    name="terms"
                    component={ToggleField}
                    widthFull
                  />
                </div>
                <Field
                  type="text"
                  name="employeesnumber"
                  placeholder={employeesnumberplaceholder}
                  component={TextInputField}
                  widthFull
                  validate={(value) =>
                    value ? undefined : employeesnumbervalidation
                  }
                  required
                /> */}
              </div>
              <div>
                <SearchLocation
                  type="text"
                  label={businessaddresslabel}
                  name="address1"
                  placeholder={address1placeholder}
                  widthFull
                  error={error.address1}
                  setAddress={setAddressObject}
                  value={addressObject.address1}
                  setValue={(value) => {
                    setAddressObject({
                      ...addressObject,
                      address1: value,
                    })
                  }}
                />
                <TextInput
                  type="text"
                  name="address2"
                  placeholder={address2placeholder}
                  component={TextInputField}
                  widthFull
                />
                <TextInput
                  type="text"
                  name="city"
                  placeholder={cityplaceholder}
                  widthFull
                  error={error.city}
                  setAddress={setAddressObject}
                  value={addressObject.city}
                  onChange={(event) => {
                    setAddressObject({
                      ...addressObject,
                      city: event.target.value,
                    })
                    setError({
                      ...error,
                      city: "",
                    })
                  }}
                />
                <SelectMenu
                  type="select"
                  name="state"
                  options={stateOptions}
                  widthFull
                  error={error.state}
                  value={
                    addressObject.state &&
                    stateOptions.find(({ value }) => value === addressObject.state)
                  }
                  placeholder={stateplaceholder}
                  onChange={({ value }) => {
                    setAddressObject({
                      ...addressObject,
                      state: value,
                    })
                    setError({
                      ...error,
                      state: "",
                    })
                  }}
                />
                <TextInput
                  type="number"
                  name="zip"
                  placeholder={zipplaceholder}
                  widthFull
                  value={addressObject.zip}
                  onChange={(event) => {
                    setAddressObject({
                      ...addressObject,
                      zip: event.target.value,
                    })
                    setError({
                      ...error,
                      zip: "",
                    })
                  }}
                  error={error.zip}
                />
                <Field
                  type="select"
                  name="country"
                  options={countryOptions}
                  component={SelectInputField}
                  placeholder={countryplaceholder}
                  defaultValue={countrydefault}
                  widthFull
                  required
                />
                <TextInput
                  type="text"
                  name="website"
                  placeholder={websiteplaceholder}
                  widthFull
                  value={addressObject.website}
                  onChange={(event) => {
                    setAddressObject({
                      ...addressObject,
                      website: event.target.value,
                    })
                    setError({
                      ...error,
                      website: "",
                    })
                  }}
                  error={error.website}
                />
                {/* <Field
                  type="text"
                  name="website"
                  placeholder={websiteplaceholder}
                  component={TextInputField}
                  widthFull
                  // validate={(value) =>
                  //   !urlValidationPattern.test(value) && zipvalidation
                  // }
                  onChange={(event) => setWebsite(event.target.value)}
                  value={website}
                /> */}
              </div>
            </div>
            <div className="my-4 h-2 bg-gray-200 rounded-lg">
              <div className="my-4 h-2 w-1/3 bg-primary rounded-lg" />
            </div>
            <RoundedButton
              variant="primary"
              type="submit"
              title={actionbuttonlabel}
            />
          </form>
        )}
      />
    </div>
  )
}

AboutBusiness.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default AboutBusiness
