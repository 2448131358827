import {
  GET_JOB_OFFERS,
  ACCEPT_JOB_OFFER,
  DECLINE_JOB_OFFER,
  ASSIGN_JOB,
  GET_ALL_JOBS,
  FULL_DECLINE_JOB_OFFER,
  ACCEPT_JOB_AND_ASSIGN_MEMBER,
  GET_ALL_JOBS_TYPE,
  GET_MEMBERS_BY_JOB,
} from "../actions/types"

const initialState = {
  jobsByType: [],
  jobs: [],
  jobOffers: [],
  membersByJob: [],
}

function jobReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_JOB_OFFERS:
      return {
        ...state,
        jobOffers: payload,
      }
    case GET_ALL_JOBS:
      return {
        ...state,
        jobs: payload,
      }
    case GET_ALL_JOBS_TYPE:
      return {
        ...state,
        jobsByType: payload,
      }
    case ACCEPT_JOB_OFFER:
      return {
        ...state,
        jobOffers: state.jobOffers.map(({ id, ...rest }) =>
          id === payload
            ? { ...rest, id, status: { description: "ACCEPTED" } }
            : { ...rest, id }
        ),
      }
    case DECLINE_JOB_OFFER:
      return {
        ...state,
        jobOffers: state.jobOffers.map(({ id, ...rest }) =>
          id === payload
            ? { ...rest, id, status: { description: "REJECTED" } }
            : { ...rest, id }
        ),
      }
    case ASSIGN_JOB:
      return {
        ...state,
        jobOffers: state.jobOffers.map(({ id, ...rest }) =>
          id === payload
            ? { ...rest, id, status: { description: "ACCEPTED" } }
            : { ...rest, id }
        ),
      }
    case GET_MEMBERS_BY_JOB:
      return { ...state, membersByJob: payload }
    default:
      return state
  }
}

export default jobReducer
